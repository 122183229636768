import React from 'react';
import { Table } from 'react-bootstrap';

import "./CustomTable.css"

const CustomTable = (props) => (
  <div className="customtable-container">
    <Table className="customtable-table">
      {props.children}
    </Table>
  </div>
);


CustomTable.defaultProps = {};

export default CustomTable;
