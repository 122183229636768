import "./PricingB2CEditor.css";

import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTrash,
  faPlus,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import CustomTable from "../CustomTable/CustomTable";

import {
  loadB2CPrice,
  downloadB2CConfig,
  uploadB2CConfig,
  applyB2cConfig,
  getB2CPricingConfig,
  eraseB2CDraftConfig,
} from "../../utilities/prices";
import { GlobalStateContext } from "../../state";
import { toastOptions } from "../../state/constants";
import { ToastContainer, toast, Slide } from "react-toastify";
import ObjectsPicker from "../ObjectsPicker/ObjectsPicker";
import { useParams } from "react-router-dom";
const defaultPricingInfo = {
  total_distance: 0,
  price: {
    currency: "EUR",
    tax_percentage: 0,
    price_tax_included: 0,
    price_tax_excluded: 0,
    tax_amount: 0,
  },
  deliveries: [
    {
      pickup_pricing_criteria: {
        Ascenseur: "",
        "Cour / distance appartement rue": "",
        Étage: "",
      },
      pricing_criteria: {
        Ascenseur: "",
        "Cour / distance appartement rue": "",
        Étage: "",
      },
      objects: [],
    },
  ],
};

const PricingB2CEditor = () => {
  const { t } = useTranslation();

  const [, { dispatch }] = useContext(GlobalStateContext);

  const [pricingInfo, setPricingInfo] = useState(defaultPricingInfo);
  const [pricingConfig, setPricingConfig] = useState({
    Objects: [],
    PAConditions: [],
  });
  const [pricingAttributes, setPricingAttributes] = useState([]);

  const [loadingPrice, setLoadingPrice] = useState(false);
  const [priceError, setPriceError] = useState("");

  const [stage, setStage] = useState("");
  const [fetchedStage, setFetchedStage] = useState("");
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [uploadErrs, setUploadErrs] = useState([]);

  const [updatedConfig, setUpdatedConfig] = useState(false);
  const [totalDistance, setTotalDistance] = useState("");
  const history = useHistory();

  const reloadPrice = useRef(false);
  const attributesLoaded = useRef(false);
  const pricingConfigLoaded = useRef(false);
  const updatedPricingInfo = useRef(false);

  const { id } = useParams();

  const callingAPI = loadingPrice || loadingConfig;

  useEffect(() => {
    if (pricingConfig.PAConditions) {
      setPricingAttributes(
        pricingConfig.PAConditions.map((pac) => ({
          name: pac.Label,
          type: "select",
          options: pac.Values,
        }))
      );
      attributesLoaded.current = true;
    }
  }, [pricingConfig]);

  useEffect(() => {
    if (!pricingConfigLoaded.current) {
      setLoadingConfig(true);
      eraseB2CDraftConfig().catch((err) => new Error(err));
      getB2CPricingConfig(stage)
        .then((b2cConfig) => {
          setPricingConfig({
            Objects: b2cConfig.Objects,
            PAConditions: b2cConfig.PAConditions,
          });
          setFetchedStage(b2cConfig.Stage);
          if (pricingConfig.PAConditions) {
            setPricingAttributes(
              b2cConfig.PAConditions.map((pac) => ({
                name: pac.Label,
                type: "select",
                options: pac.Values,
              }))
            );
          }
          setLoadingConfig(false);
        })
        .catch((e) => {
          if (e.error) {
            if (e.error.code === 400) {
              toast.error(t(`${e.error.message}`), toastOptions);
            }
          } else {
            toast.error(t(`${e}`), toastOptions);
          }
          setLoadingConfig(true);
        });
      pricingConfigLoaded.current = true;
      attributesLoaded.current = true;
    }

    if (reloadPrice.current) {
      setLoadingPrice(true);

      loadB2CPrice(stage, pricingInfo)
        .then((response) => {
          const newPi = response;
          setPricingInfo((pricingInfo) => ({
            ...pricingInfo,
            price: newPi.price,
          }));

          setPriceError("");
        })
        .catch((e) => {
          setPricingInfo(defaultPricingInfo);

          if (e.error) {
            if (e.error.code === 400) {
              toast.error(t(`${e.error.message}`), toastOptions);
            }
          } else {
            toast.error(t(`${e}`), toastOptions);
          }
        })

        .finally(() => {
          reloadPrice.current = false;
          setLoadingPrice(false);
        });
    }
  }, [pricingInfo, stage, t, dispatch, pricingAttributes, pricingConfig]);

  useEffect(() => {
    onTotalDistanceChange(totalDistance);
  }, [totalDistance]);

  const onObjectChange = (objects, index) => {
    updatedPricingInfo.current = true;
    const newPricingInfo = { ...pricingInfo };
    newPricingInfo.deliveries[index].objects = objects || [];
    setPricingInfo({ ...newPricingInfo });
    reloadPrice.current = true;
    if (totalDistance) setUpdatedConfig(true);
  };

  const onTotalDistanceChange = (distance) => {
    updatedPricingInfo.current = true;
    const newPricingInfo = { ...pricingInfo };
    newPricingInfo.total_distance = parseFloat(distance) || 0;
    setPricingInfo({ ...newPricingInfo });
    reloadPrice.current = true;
    distance ? setUpdatedConfig(true) : setUpdatedConfig(false);
  };

  const onPrCriteriaChange = (key, value, index) => {
    updatedPricingInfo.current = true;
    const newPricingInfo = pricingInfo;
    newPricingInfo.deliveries[index].pricing_criteria[key] = value;

    setPricingInfo({ ...newPricingInfo });
    reloadPrice.current = true;
    if (totalDistance) setUpdatedConfig(true);
  };

  const onPUPrCriteriaChange = (key, value) => {
    updatedPricingInfo.current = true;
    const newPricingInfo = pricingInfo;

    for (const dv of newPricingInfo.deliveries) {
      dv.pickup_pricing_criteria[key] = value;
    }

    setPricingInfo({ ...newPricingInfo });
    reloadPrice.current = true;
    if (totalDistance) setUpdatedConfig(true);
  };

  const resetConfig = () => {
    window.location.reload();
  };

  const onStageChange = (stage) => {
    setStage(stage);
    setLoadingConfig(true);
    getB2CPricingConfig(stage)
      .then((b2cConfig) => {
        setPricingConfig({
          Objects: b2cConfig.Objects,
          PAConditions: b2cConfig.PAConditions,
        });
        setFetchedStage(b2cConfig.Stage);
        if (pricingConfig.PAConditions) {
          setPricingAttributes(
            b2cConfig.PAConditions.map((pac) => ({
              name: pac.Label,
              type: "select",
              options: pac.Values,
            }))
          );
        }
        setLoadingConfig(false);
      })
      .then(() => {
        loadB2CPrice(stage, pricingInfo)
          .then((response) => {
            const newPi = response;
            setPricingInfo({ ...pricingInfo, price: newPi.price });

            setPriceError("");
          })
          .catch((e) => {
            setPricingInfo(defaultPricingInfo);

            if (e.error) {
              if (e.error.code === 400) {
                toast.error(t(`${e.error.message}`), toastOptions);
              }
            } else {
              toast.error(t(`${e}`), toastOptions);
            }
          })
          .finally(() => {
            reloadPrice.current = false;
            setLoadingPrice(false);
          });
      })
      .catch((e) => {
        if (e.error) {
          if (e.error.code === 400) {
            toast.error(t(`${e.error.message}`), toastOptions);
          }
        } else {
          toast.error(t(`${e}`), toastOptions);
        }
        setLoadingConfig(false);
      });
  };
  const addDropOff = (e) => {
    e.preventDefault();
    const updatedPricingInfo = { ...pricingInfo };
    updatedPricingInfo.deliveries.push({
      pickup_pricing_criteria:
        pricingInfo.deliveries[0].pickup_pricing_criteria,
      pricing_criteria: {},
      objects: [],
    });
    setPricingInfo({ ...updatedPricingInfo });
  };

  const deleteDropOff = (dropOffIndex) => {
    const updatedPricingInfo = { ...pricingInfo };
    updatedPricingInfo.deliveries = updatedPricingInfo.deliveries.filter(
      (pi, index) => dropOffIndex !== index
    );

    setPricingInfo(updatedPricingInfo);
  };

  const isEditableField = (fieldName) => {
    if (
      [
        "package_description",
        "price_objects",
        "comment",
        "price_attributes",
        "price",
      ].includes(fieldName)
    ) {
      return canSimulate() && true;
    }
  };

  const downloadAndSave = () => {
    downloadB2CConfig("prod").catch((e) => {
      if (e.error) {
        if (e.error.code === 400) {
          toast.error(t(`${e.error.message}`), toastOptions);
        }
      } else {
        toast.error(t(`${e}`), toastOptions);
      }
    });
  };

  const uploadFile = (event) => {
    setUploadErrs([]);
    let file = event.target.files[0];
    if (file) {
      let data = new FormData();
      data.append("myFile", file);
      uploadB2CConfig("draft", data)
        .then((res) => {
          if (res.success) {
            toast.success(t(`file-upload-success`), toastOptions);
            setStage((stage) => (stage = "draft"));
          }
          setLoadingConfig(true);
          getB2CPricingConfig(stage)
            .then((b2cConfig) => {
              setPricingConfig({
                Objects: b2cConfig.Objects,
                PAConditions: b2cConfig.PAConditions,
              });
              setFetchedStage(b2cConfig.Stage);
              if (pricingConfig.PAConditions) {
                setPricingAttributes(
                  b2cConfig.PAConditions.map((pac) => ({
                    name: pac.Label,
                    type: "select",
                    options: pac.Values,
                  }))
                );
              }
              setLoadingConfig(false);
            })
            .then(() =>
              loadB2CPrice(stage, pricingInfo)
                .then((response) => {
                  const newPi = response;
                  setPricingInfo({ ...pricingInfo, price: newPi.price });
                  setPriceError("");
                })
                .catch((e) => {
                  setPricingInfo(defaultPricingInfo);

                  if (e.error) {
                    if (e.error.code === 400) {
                      toast.error(t(`${e.error.message}`), toastOptions);
                    }
                  } else {
                    toast.error(t(`${e}`), toastOptions);
                  }
                })

                .finally(() => {
                  reloadPrice.current = false;
                  setLoadingPrice(false);
                  setLoadingConfig(false);
                })
            )
            .catch((e) => {
              if (e.error) {
                if (e.error.code === 400) {
                  toast.error(t(`${e.error.message}`), toastOptions);
                }
              } else {
                toast.error(t(`${e}`), toastOptions);
              }
            });
        })
        .catch((e) => {
          if (e.error) {
            if (e.error.code === 400) {
              toast.error(t(`${e.error.message}`), toastOptions);
            }
          } else {
            if (e?.code === 422) {
              setUploadErrs(e.response);
              return;
            }
            toast.error(t(`${e}`), toastOptions);
          }
        });
    }
  };

  const applyConfig = () => {
    applyB2cConfig()
      .then((res) => {
        if (res.success) {
          updatedPricingInfo.current = false;
          toast.success(t(`config-applied-success`), toastOptions);
          setPricingInfo({
            ...defaultPricingInfo,
            deliveries: [
              {
                pickup_pricing_criteria: {
                  Ascenseur: "",
                  "Cour / distance appartement rue": "",
                  Étage: "",
                },
                pricing_criteria: {
                  Ascenseur: "",
                  "Cour / distance appartement rue": "",
                  Étage: "",
                },
                objects: [],
              },
            ],
          });
          setStage("");
          // setUpdatedConfig(false);
        }
      })
      .catch((e) => {
        if (e.error) {
          if (e.error.code === 400) {
            toast.error(t(`${e.error.message}`), toastOptions);
          }
        } else {
          toast.error(t(`${e}`), toastOptions);
        }
      });
  };

  const canSimulate = () => {
    return fetchedStage === stage || false;
  };

  return (
    <div className="config-body">
      <Col>
        <Row
          className="arrowLeft"
          onClick={() => history.push(`/clients/infos/${id}`)}
        >
          <img alt="arrow left" src="/left-arrow.svg" className="arrowLogo" />
          {t("returnToHomePage")}
        </Row>
        <Row className="configDetails">
          <Col className="configDetails-col">
            <Row>
              {!canSimulate() &&
                !callingAPI &&
                !!stage.length &&
                !uploadErrs.length && (
                  <Alert
                    key={"warning"}
                    variant={"warning"}
                    style={{ justifyItems: "right", width: "100%" }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                    {t("no-config-uploaded")}
                  </Alert>
                )}
            </Row>
            <Row className="stage-and-upload">
              {!uploadErrs.length && (
                <Form.Group controlId="stage">
                  <Form.Control
                    as="select"
                    value={stage}
                    className={"select-stage"}
                    required
                    // inValid={stage}
                    onChange={(e) => onStageChange(e.target.value)}
                  >
                    <option value="" disabled defaultValue={""}>
                      {t("selectYourOption")}
                    </option>
                    <option value="draft">{t("draftDemo")}</option>
                    <option value="prod">{t("deployedDemo")}</option>
                  </Form.Control>
                </Form.Group>
              )}
              <Col className="upload-csv">
                <Col xs="auto">
                  <label className="btn btn-info" htmlFor="upload-file">
                    {t("upload-file")}
                    <input
                      type="file"
                      className="d-none"
                      id="upload-file"
                      accept={".xlsx"}
                      name="myFile"
                      onChange={(e) => {
                        uploadFile(e);
                      }}
                      onClick={(e) => (e.target.value = "")}
                    />
                  </label>
                </Col>
                <Col xs="auto">
                  <Button
                    id="downloadRulesButton"
                    variant="info"
                    onClick={() => downloadAndSave()}
                  >
                    {t("download-b2cconfig")}
                  </Button>
                </Col>
              </Col>
            </Row>
            {!uploadErrs.length ? (
              <>
                <Row>
                  <h4>{t("configDetails")}</h4>
                </Row>

                <Row style={{ marginBottom: "15px", paddingRight: "10px" }}>
                  <strong> {t("total_distance")} </strong>
                  <Form.Control
                    onChange={(e) => setTotalDistance(e.target.value || "")}
                    type="number"
                    value={totalDistance}
                    placeholder={t("total_distance")}
                    disabled={!isEditableField("price_attributes")}
                  />
                </Row>
                <Row>
                  <h6> {t("pickUpInfo")} </h6>
                  {
                    <Fragment>
                      <Row>
                        {pricingAttributes.length > 0 && (
                          <Col xs={12} style={{ paddingRight: 0 }}>
                            <strong> {t("pricingCriteria")} </strong>
                          </Col>
                        )}
                        {pricingAttributes
                          .filter((attr) => attr.name !== "distance")
                          .map((pa, paIndex) => (
                            <Col key={paIndex} style={{ paddingRight: 0 }}>
                              <Form.Group controlId={pa.name}>
                                {pa.type === "select" && (
                                  <Form.Control
                                    as="select"
                                    value={
                                      pricingInfo?.deliveries[0]
                                        .pickup_pricing_criteria[pa.name]
                                    }
                                    onChange={(e) => {
                                      onPUPrCriteriaChange(
                                        pa.name,
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !isEditableField("price_attributes")
                                    }
                                  >
                                    <option value="" disabled hidden>
                                      {pa.name}
                                    </option>
                                    {pa.options?.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Control>
                                )}
                                {pa.type === "input" && (
                                  <Form.Control
                                    type="number"
                                    value={
                                      pricingInfo.deliveries[0]
                                        .pickup_pricing_criteria[pa.name]
                                    }
                                    placeholder={pa.name}
                                    onChange={(e) => {
                                      onPUPrCriteriaChange(
                                        pa.name,
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !isEditableField("price_attributes")
                                    }
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          ))}
                      </Row>
                    </Fragment>
                  }

                  {pricingInfo.deliveries.map((val, index) => (
                    <Fragment key={index}>
                      <Row>
                        <Col className="dropoff-title">
                          <h6>
                            {" "}
                            {t("dropOffInfo")}
                            {pricingInfo.deliveries.length > 1
                              ? `(${index + 1})`
                              : ""}
                          </h6>
                          <div className="d-flex flex-row">
                            {index !== 0 && (
                              <FontAwesomeIcon
                                className="delete-dropoff-btn"
                                icon={faTrash}
                                onClick={() => deleteDropOff(index)}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {pricingAttributes.length > 0 && (
                          <Col xs={12}>
                            <strong> {t("pricingCriteria")} </strong>
                          </Col>
                        )}
                        {pricingAttributes
                          .filter((attr) => attr.name !== "distance")
                          .map((pa, paIndex) => (
                            <Col key={paIndex}>
                              <Form.Group controlId={pa.name}>
                                {pa.type === "select" && (
                                  <Form.Control
                                    as="select"
                                    value={
                                      pricingInfo.deliveries[index]
                                        .pricing_criteria[pa.name]
                                    }
                                    onChange={(e) => {
                                      onPrCriteriaChange(
                                        pa.name,
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    disabled={
                                      !isEditableField("price_attributes")
                                    }
                                  >
                                    <option value="" disabled hidden>
                                      {pa.name}
                                    </option>
                                    {pa.options?.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Form.Control>
                                )}
                                {pa.type === "input" && (
                                  <Form.Control
                                    type="number"
                                    value={
                                      pricingInfo.deliveries[index]
                                        .pricing_criteria[pa.name]
                                    }
                                    placeholder={pa.name}
                                    onChange={(e) => {
                                      onPrCriteriaChange(
                                        pa.name,
                                        e.target.value,
                                        index
                                      );
                                    }}
                                    disabled={
                                      !isEditableField("price_attributes")
                                    }
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          ))}
                      </Row>
                      <Row className="objects-input">
                        <Col xs={12}>
                          <strong> {t("objects")} </strong>
                        </Col>
                        <Col>
                          <ObjectsPicker
                            key={index}
                            pricingConfig={pricingConfig}
                            disabled={!isEditableField("price_objects")}
                            objects={pricingInfo.deliveries[index].objects}
                            setObjects={(value) => onObjectChange(value, index)}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  ))}
                  {pricingInfo.deliveries.length < 3 && (
                    <div className="d-flex flex-row mb-4">
                      <a
                        href="/"
                        style={
                          !stage
                            ? {
                                cursor: "default",
                                pointerEvents: "none",
                                color: "#7097b6",
                              }
                            : { color: "#105285" }
                        }
                        onClick={(e) => addDropOff(e)}
                      >
                        <FontAwesomeIcon icon={faPlus} /> {t("addDropOff")}
                      </a>
                    </div>
                  )}
                  <Row className="pricingInfo-pricing">
                    <Col>
                      <h6>{t("requestPricing")}</h6>
                      <Form.Group controlId="requestPricing">
                        <Form.Label>{t("price")} €</Form.Label>
                        <Form.Control
                          type="number"
                          value={
                            !updatedConfig && !totalDistance
                              ? 0
                              : pricingInfo.price.price_tax_included
                          }
                          disabled
                        />
                        {priceError !== "" && (
                          <Form.Text className="address-invalidmsg">
                            {t(priceError)}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>

                <Row className="submit-block">
                  <Fragment>
                    <Button
                      variant="primary"
                      disabled={callingAPI || !canSimulate()}
                      onClick={applyConfig}
                    >
                      {!callingAPI && (
                        <span>{t("apply_b2c_draft_config")}</span>
                      )}
                      {callingAPI && (
                        <span>
                          <FontAwesomeIcon icon={faSpinner} pulse />
                        </span>
                      )}
                    </Button>
                    {!callingAPI && (
                      <Button variant="light" onClick={resetConfig}>
                        {" "}
                        {t("cancel")}{" "}
                      </Button>
                    )}
                  </Fragment>
                </Row>
              </>
            ) : (
              <Col xs={{ span: 11, offset: 0 }}>
                <Alert
                  key={"warning"}
                  variant={"warning"}
                  style={{ justifyItems: "center" }}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                  {t("warning-upload")}
                </Alert>
                <CustomTable>
                  <thead>
                    <tr className="codePromoItem">
                      <th>{t("sheetName")}</th>
                      <th>{t("errorLine")}</th>
                      <th>{t("columnName")}</th>
                      <th>{t("errorMessage")}</th>
                    </tr>
                  </thead>
                  {uploadErrs.length && (
                    <tbody>
                      {uploadErrs.map((error, index) => (
                        <tr key={index} className="codePromoItem">
                          <td>{error.SheetName}</td>
                          <td>{error.ErrorLine}</td>
                          <td>{error.ColName}</td>
                          <td>{t(`${error.Message}`)}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </CustomTable>
              </Col>
            )}
          </Col>
        </Row>
      </Col>
      <ToastContainer transition={Slide} />
    </div>
  );
};

PricingB2CEditor.defaultProps = {};

export default PricingB2CEditor;
