import React from 'react';
import { Spinner } from 'react-bootstrap';

import "./PageSpinner.css"

const PageSpinner = () => (
  <div className="pagespinner-container">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

PageSpinner.defaultProps = {};

export default PageSpinner;
