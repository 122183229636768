import {authorization} from "./auth.js";
import { fetchWrapper } from "./utils";

export const loadUsers = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users`, {
      headers: { authorization: authorization() }
    })
    if (!response.ok) {
      throw await response.json()
    }
    const { response: users } = await response.json();
    const result = users.filter(u => u.ClientAccountID === id);
    return result;
  } catch(e) {
    console.error(e)
  }
}

export const createUser = async (data) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users`, {
    headers: { authorization: authorization() },
    method: "POST",
    body: JSON.stringify(data)
  })
  if (!response.ok) { throw await response.json() }
  const { response: user } = await response.json();
  return user;
}

export const updateUser = async ({ID: id, ...data}) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users/${id}`, {
    headers: { authorization: authorization() },
    method: "PUT",
    body: JSON.stringify(data)
  })
  if (!response.ok) { throw await response.json() }
}

export const deleteUser = async (id) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users/${id}`, {
    headers: { authorization: authorization() },
    method: "DELETE",
  })
  if (!response.ok) { throw await response.json() }
}
