import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card, ListGroup, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {loadClients} from "../../utilities/clients.js";

import "./Clients.css"

const Clients = () => {

  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    document.title = `${t("clientsList")} | Stuart XL`;
  });

  useEffect(() => {
    setIsLoading(true);
    loadClients()
      .then(setClients)
      .catch(response => {
        localStorage.removeItem("token");
        window.location.reload();
      })
      .finally(() => setIsLoading(false));
  }, [])

  return (
    <Container>
      <br/>
      <Row>
        <Col>
          <Card>
            <Container>
              <br/>
              <Row>
                <Col><h3>
                  {t("clientsList")}
                  &nbsp;
                  {isLoading && <FontAwesomeIcon icon={faSpinner} pulse/>}
                </h3></Col>
                <Col xs="auto">
                  {!isLoading && <Button variant="info" as={Link} to="/clients/form">{t("createClient")}</Button> }
                </Col>
              </Row> 
              <br/>
              <Row>
                <Col>
                  <ListGroup>
                    {clients.map((client, index) =>(
                      <ListGroup.Item key={index} action href={`/clients/infos/${client.ID}`}>
                        {client.Name}
                      </ListGroup.Item>
                    ))} 
                  </ListGroup>
                </Col>
              </Row>
              <br/>
            </Container>
          </Card>
          <br/>
        </Col>
      </Row>
    </Container>
  )
};

Clients.defaultProps = {};

export default Clients;
