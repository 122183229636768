import React from 'react';
import { Col, Row } from 'react-bootstrap';

import "./PageSubtitle.css";

const PageSubtitle = (props) => (
  <Row className="pagesubtitle-container">
    <Col xs={{ span: 10, offset: 1 }}>
      {props.children}
    </Col>
  </Row>
);


PageSubtitle.defaultProps = {};

export default PageSubtitle;
