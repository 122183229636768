import React from "react";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faClock } from "@fortawesome/free-solid-svg-icons";

import "./TimeSlotPicker.css";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr);

const TimeSlotPicker = (props) => {
  const { t } = useTranslation();

  let maxDate = new Date();
  props.maxDays > 0
    ? maxDate.setDate(maxDate.getDate() + props.maxDays)
    : (maxDate = new Date(props.date));

  return (
    <Row>
      <Col>
        <DatePicker
          locale="fr"
          selected={!!props.date ? new Date(props.date) : new Date()}
          calendarStartDay={1}
          minDate={new Date()}
          maxDate={maxDate}
          disabled={props.disabled}
          onSelect={(date) => {
            props.getTimeSlots(dayjs(date?.toString()).format("YYYY-MM-DD"));
          }}
          customInput={
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend
                  className={`customInputGroup ${
                    props.disabled ? "disabledInputGroup" : ""
                  }`}
                >
                  <FontAwesomeIcon
                    className="prepend-svg"
                    icon={faCalendarDay}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  disabled={props.disabled}
                  readOnly
                  className="prepend-input-field"
                  value={
                    !!props.date ? dayjs(props.date).format("DD MMMM YYYY") : ""
                  }
                  placeholder={t("deliveryDate")}
                />
              </InputGroup>
              {props.errorSlots && (
                <Form.Text className="address-invalidmsg">
                  {props.errorSlots}
                </Form.Text>
              )}
            </Form.Group>
          }
          popperPlacement="bottom-start"
          withPortal
        />
      </Col>
      <Col>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend
              className={`customInputGroup ${
                props.disabled ? "disabledInputGroup" : ""
              }`}
            >
              <FontAwesomeIcon className="prepend-svg" icon={faClock} />
            </InputGroup.Prepend>
            {!props.disabled && (
              <Form.Control
                as="select"
                className="prepend-input-field"
                value={props.date}
                onChange={(e) => props.setDate(e.target.value)}
              >
                <option key={0} value="" hidden>
                  {t("deliveryTime")}
                </option>

                {props.timeSlots.map((element, index) => (
                  <option key={index + 1} value={element.start_time}>
                    {`${dayjs(element.start_time).format("HH:mm")} - ${dayjs(
                      element.end_time
                    ).format("HH:mm")}`}
                  </option>
                ))}
              </Form.Control>
            )}
            {props.disabled && (
              <Form.Control
                className="prepend-input-field"
                value={props.slotType === "Custom" ?
                  `${dayjs(props.deliverySlot.start_time).format("HH:mm")} - ${dayjs(
                  props.deliverySlot.end_time
                ).format("HH:mm")}`  :
                   dayjs(props.date).format("HH:mm")}
                disabled
              />
            )}
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default TimeSlotPicker;
