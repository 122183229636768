import React, { useCallback, useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import PageSpinner from "../PageSpinner/PageSpinner";
import CustomTable from "../CustomTable/CustomTable";
import PageSubtitle from "../PageSubtitle/PageSubtitle";
import {
  loadPromoList,
  getFormattedTime,
  getPromoCodeByCode,
  archivePromoCode,
} from "../../utilities/promocode";
import { GlobalStateContext } from "../../state";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import { toastOptions } from "../../state/constants";

import "./PromotionalCodeConfig.css";
import dayjs from "dayjs";

const PromotionalCodeConfig = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage] = useState(12);
  const [stagedFilterText, setStagedFilterText] = useState("");
  const [state, { dispatch }] = useContext(GlobalStateContext);

  useEffect(() => {
    document.title = `${t("requests")} | Stuart XL`;
  });

  const loadList = useCallback(
    async (p) => {
      const args = [p, perPage];
      await loadPromoList(...args, dispatch);
    },
    [perPage, dispatch]
  );

  useEffect(() => {
    loadList(page);
  }, [loadList, page]);

  const open = (id) => () => {
    history.push(`/promocode/form/${id}`);
  };
  const deletePromocode = async (id) => {
    Promise.all([archivePromoCode(id), loadList(page)]);
  };
  const submitFilter = async (e) => {
    e.preventDefault();
    try {
      await getPromoCodeByCode(stagedFilterText, dispatch);
      setPage(1);
    } catch (err) {
      if (err.error) {
        if (err?.error?.code === 400) {
          toast.error(t("code-not-found"), toastOptions);
        } else {
          toast.error(t(`${err}`), toastOptions);
        }
      } else {
        toast.error(t(`${err}`), toastOptions);
      }
    }
  };

  const clearFilter = (e) => {
    setStagedFilterText("");
    setPage(1);
    loadList(page);
  };

  const getPromoType = (promotype) => {
    switch (promotype) {
      case "amount":
        return "€";
      case "percentage":
        return "%";
      default:
        return "-";
    }
  };

  const getFormattedApplication = (app) => {
    if (!app.length) {
      return "-";
    }
    app = app.map((el) => {
      if (el === "b2c") {
        return "Particulier";
      } else if (el === "instore") {
        return "Magasin";
      }
      return "";
    });
    return app.join(" / ");
  };

  const getStatus = (promo) => {
    let date = new Date();
    date.setHours(1, 0, 0, 0);
    if (promo.UseNumber >= promo.MaxUseNum || new Date(promo.EndTime) < date) {
      return t("codeExipred");
    }

    if (!promo.Activated || new Date(promo.StartTime) > date) {
      return t("inactive");
    }
    return t("active");
  };
  return (
    <Container fluid>
      {state.promo.promoList.length > 0 && (
        <Row>
          <Col>
            <PageSubtitle>
              <h6>
                {state.promo.total}{" "}
                {t("promocode", { count: state.promo.total })}
              </h6>
            </PageSubtitle>{" "}
          </Col>
        </Row>
      )}
      <Row className="promotionalCodes-tab">
        <Col xs={{ offset: "1", span: "auto" }}>
          {
            <Button variant="info" as={Link} to="/promocode/form">
              {t("createPromoCode")}
            </Button>
          }
        </Col>
        <Col>
          <Form onSubmit={submitFilter}>
            <Row>
              <Col xs={{ offset: "1", span: "auto" }}>
                <InputGroup>
                  <Form.Control
                    id="promocode"
                    type="text"
                    placeholder={t("filterByStore")}
                    value={stagedFilterText}
                    onChange={(e) => {
                      setStagedFilterText(e.target.value);
                    }}
                  />
                  <InputGroup.Append className="Requests-clearicon">
                    <InputGroup.Text
                      onClick={clearFilter}
                      disabled={!stagedFilterText.length}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Button variant="info" onClick={submitFilter}>
                  {" "}
                  {t("filter")}{" "}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br />
      {!state.promo.promoList && <PageSpinner></PageSpinner>}
      {state.promo.promoList && !state.promo.promoList.length && (
        <div className="requests-empty">
          <img src="no-requests.svg" alt="no requests" />
          <h3>{t("noPromoCodeTitle")}</h3>
          <p>{t("noPromoCodeDescription")}</p>
        </div>
      )}
      {state.promo.promoList.length && (
        <div>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <CustomTable>
                <thead>
                  <tr className="codePromoItem">
                    <th>{t("code")}</th>
                    <th>{t("promo")}</th>
                    <th>{t("promoType")}( %/€)</th>
                    <th>{t("startTime")}</th>
                    <th>{t("endTime")}</th>
                    <th>{t("maxUseNumber")}</th>
                    <th>{t("application")}</th>
                    <th>{t("status")}</th>
                    <th></th>
                  </tr>
                </thead>
                {state.promo.promoList.length === 0 && (
                  <tbody>
                    <tr>
                      <td colSpan="9" className="emptyRow">
                        {t("noPromoCodeSearchResult")}
                      </td>
                    </tr>
                  </tbody>
                )}
                <tbody>
                  {state.promo.promoList.map((promo, index) => (
                    <tr key={index} className="codePromoItem">
                      <td>{promo.Code}</td>
                      <td>{promo.Promo}</td>
                      <td>{getPromoType(promo.PromoType)}</td>
                      <td>
                        {dayjs(getFormattedTime(promo.StartTime))
                          .format("DD-MM-YYYY")
                          .replaceAll("-", "/")}
                      </td>
                      <td>
                        {dayjs(getFormattedTime(promo.EndTime))
                          .format("DD-MM-YYYY")
                          .replaceAll("-", "/")}
                      </td>
                      <td>{promo.MaxUseNum}</td>
                      <td>{getFormattedApplication(promo.Application)}</td>
                      <td>{getStatus(promo)}</td>

                      <td>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="editIcon"
                          onClick={open(promo.ID)}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="deleteIcon"
                          onClick={() => deletePromocode(promo.ID)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustomTable>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={1}></Col>
            <Col xs="auto">
              <Pagination size="sm">
                {state.promo.pages.map((value, index) => {
                  return (
                    <Pagination.Item
                      active={page === value}
                      key={index}
                      onClick={(e) => {
                        setPage(parseInt(e.target.text));
                      }}
                    >
                      {value}{" "}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Col>
          </Row>
          <br />
        </div>
      )}
      <ToastContainer transition={Slide} />
    </Container>
  );
};

PromotionalCodeConfig.defaultProps = {};

export default PromotionalCodeConfig;
