import { authorization } from "./auth.js";
import { fetchWrapper } from "./utils";

export const loadClients = async () => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/clients`, {
    headers: { authorization: authorization() }
  })
  if (!response.ok) {
    throw response;
  }
  const { response: clients } = await response.json();
  return clients || [];
}

export const loadClient = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/clients/${id}`, {
      headers: { authorization: authorization() }
    })
    if (!response.ok) {
      throw await response.json()
    }
    const { response: client } = await response.json();
    return client || {};
  } catch(e) {
    return {};
  }
}

export const createClient = async (data) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/clients`, {
    headers: { authorization: authorization() },
    method: "POST",
    body: JSON.stringify(data)
  })
  if (!response.ok) {
    throw await response.json()
  }
  const { response: client } = await response.json();
  return client;
}

export const editClient = async (id, data) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/clients/${id}`, {
    headers: { authorization: authorization() },
    method: "PUT",
    body: JSON.stringify(data)
  })
  if (!response.ok) {
    throw await response.json()
  }
  const { response: client } = await response.json();
  return client;
}

export const refreshTasks = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/orders/refresh`;
  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  })
  const jsonResponse = await response.json();
  if (!response.ok) {
    throw jsonResponse;
  }
}

export const removeDuplicatedTasks = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/orders/remove_duplicates`;
  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  })
  const jsonResponse = await response.json();
  if (!response.ok) {
    throw jsonResponse;
  }
}


export const validateRequest = async (id, body) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/client/${id}/addresses/validate`, {
    headers: { authorization: authorization() },
    method: "POST",
    body: JSON.stringify(body)
  })
  if (!response.ok) {
    throw await response.json();
  }
  const { response: result } = await response.json();
  return result
}
