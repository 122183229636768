import { toast } from "react-toastify";

export const UPDATE_STATE = "UPDATE_STATE";
export const LOGOUT = "LOGOUT";

export const defaultPrice = {
  currency: "EUR",
  price_tax_excluded: "0",
  price_tax_included: "0",
  tax_amount: "0",
  tax_percentage: "0.2",
};

export const initialState = {
  user: {},
  price: defaultPrice,
  attributes: [],
  isLoadingPrice: false,
  isLoadingAttributes: false,
  draftPrice: defaultPrice,
  draftAttributes: [],
  isLoadingDraftPrice: false,
  isLoadingDraftAttributes: false,
  isSavingDraft: false,
  isSavingPricing: false,
  promo:{total:0,promoList:[],pages:[]}
};

export const toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  pauseOnHover: false,
  hideProgressBar: true,
};

export const openZones = [
  "aix_en_provence",
  "amiens",
  "angers",
  "angouleme",
  "annecy",
  "antibes",
  "aubagne",
  "avignon",
  "beauvais",
  "besancon",
  "beziers",
  "blois",
  "bordeaux",
  "boulogne_sur_mer",
  "brest",
  "brive",
  "caen",
  "calais",
  "cannes",
  "cazeres",
  "charleville_mezieres",
  "chembourg",
  "cholet",
  "clermont_ferrand",
  "colmar",
  "dijon",
  "dinard",
  "douai",
  "dunkerque",
  "evreux",
  "foix",
  "grenoble",
  "guerande",
  "la_rochelle",
  "le_havre",
  "le_mans",
  "lille",
  "limoges",
  "lyon",
  "marseille",
  "merignac",
  "merlimont",
  "metz",
  "montpellier",
  "mulhouse",
  "nancy",
  "nantes",
  "nice",
  "nimes",
  "niort",
  "orleans",
  "pamiers",
  "paris",
  "perigueux",
  "perpignan",
  "poitiers",
  "quimper",
  "reims",
  "rennes",
  "roubaix",
  "rouen",
  "saint_brieuc",
  "saint_etienne",
  "saint_nazaire",
  "saint_quentin",
  "strasbourg",
  "thionville",
  "toulon",
  "toulouse",
  "tours",
  "valence",
  "valenciennes",
  "vannes",
];

export const openZonesByCountry = {
  France: [
    "aix_en_provence",
    "amiens",
    "angers",
    "angouleme",
    "annecy",
    "antibes",
    "aubagne",
    "avignon",
    "beauvais",
    "besancon",
    "beziers",
    "blois",
    "bordeaux",
    "boulogne_sur_mer",
    "brest",
    "brive",
    "caen",
    "calais",
    "cannes",
    "cazeres",
    "charleville_mezieres",
    "chembourg",
    "cholet",
    "clermont_ferrand",
    "colmar",
    "dijon",
    "dinard",
    "douai",
    "dunkerque",
    "evreux",
    "foix",
    "grenoble",
    "guerande",
    "la_rochelle",
    "le_havre",
    "le_mans",
    "lille",
    "limoges",
    "lyon",
    "marseille",
    "merignac",
    "merlimont",
    "metz",
    "montpellier",
    "mulhouse",
    "nancy",
    "nantes",
    "nice",
    "nimes",
    "niort",
    "orleans",
    "pamiers",
    "paris",
    "perigueux",
    "perpignan",
    "poitiers",
    "quimper",
    "reims",
    "rennes",
    "roubaix",
    "rouen",
    "saint_brieuc",
    "saint_etienne",
    "saint_nazaire",
    "saint_quentin",
    "strasbourg",
    "thionville",
    "toulon",
    "toulouse",
    "tours",
    "valence",
    "valenciennes",
    "vannes",
  ],
  "United-Kingdom": ["basildon", "birmingham", "blackpool"],
  Spain: ["barcelona", "girona", "madrid", "valles"],
};

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const workTimes = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
