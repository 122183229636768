import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

import UserMenu from "../UserMenu";
import HelpLink from "../HelpLink";

import "./Header.css";

const Header = () => {
  const { t } = useTranslation();

  return (
    <Navbar className="header-navbar" bg="white" fixed="top">
      <Navbar.Brand as={NavLink} to="/">
        <img alt="Stuart Logo" src="/logo.svg" height="27" />
      </Navbar.Brand>
      <Navbar.Collapse>
        <Nav className="header-nav">
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact
            to="/"
            className="header-mainlink header-navlink"
          >
            {t("clientsList")}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact
            to="/requests"
            className="header-mainlink header-navlink"
          >
            {t("requests")}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact
            to="/workingHoursConfig"
            className="header-mainlink header-navlink"
          >
            {t("workingHoursConfig")}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact
            to="/promotionalCodes"
            className="header-mainlink header-navlink"
          >
            {t("promotionalCodes")}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <HelpLink></HelpLink>
      <UserMenu></UserMenu>
    </Navbar>
  );
};

export default Header;
