import React from "react";
import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header";
import PriceBuilder from "./components/PriceBuilder";
import ClientForm from "./components/ClientForm";
import PromotionalCodeForm from "./components/PromotionalCodeForm";
import ClientInfos from "./components/ClientInfos";
import ClientTasks from "./components/ClientTasks";
import Requests from "./components/Requests";
import WorkingHoursConfig from "./components/WorkingHoursConfig";
import PromotionalCodeConfig from './components/PromotionalCodeConfig/PromotionalCodeConfig'
import B2CWorkingHoursConfig from "./components/B2CWorkingHoursConfig"
import RequestPage from "./components/RequestPage";
import Login from "./components/Login";
import Clients from "./components/Clients";
import { GlobalStateProvider } from "./state";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PricingB2CEditor from "./components/PricingB2CEditor/PricingB2CEditor";


function App() {
  return (
    <Router>
      <GlobalStateProvider>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/requests/:id">
            <RequestPage />
          </Route>
          <Route>
            <Header />
            <div className="page-content">
              <Switch>
                <PrivateRoute>
                  <Route path="/" exact>
                    <Clients />
                  </Route>
                  <Route path="/requests" exact>
                    <Requests />
                  </Route>
                  <Route path="/workingHoursConfig" exact>
                    <WorkingHoursConfig />
                  </Route>
                  <Route path="/b2cWorkingHoursConfig/:id" exact>
                    <B2CWorkingHoursConfig />
                  </Route>
                  <Route path="/clients/form/:id?">
                    <ClientForm />
                  </Route>
                  <Route path="/clients/prices/:id">
                    <PriceBuilder />
                  </Route>
                  <Route path="/clients/infos/:id">
                    <ClientInfos />
                  </Route>
                  <Route path="/clients/tasks/:id">
                    <ClientTasks />
                  </Route>
                  <Route path="/promotionalCodes" exact>
                    <PromotionalCodeConfig />
                  </Route>
                  <Route path="/promocode/form/:id?">
                    <PromotionalCodeForm />
                  </Route>
                  <Route path="/clients/b2cprices/:id">
                    <PricingB2CEditor />
                  </Route>
                </PrivateRoute>
              </Switch>
            </div>
          </Route>
        </Switch>
      </GlobalStateProvider>
    </Router>
  );
}

export default App;
