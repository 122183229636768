import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ObjectInput from "../ObjectInput/ObjectInput";

import "./ObjectsPicker.css";

const ObjectsPicker = (props) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [predictions, setPredictions] = useState([]);

  const onFocus = () => {
    if (!!searchString) setShowPredictions(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      setShowPredictions(false);
    }, 200);
  };

  const handleChange = (value) => {
    setSearchString(value);
    if (!!value && props.pricingConfig.Objects) {
      const filteredObjects = props.pricingConfig.Objects.filter((object) =>
        t(object.Name).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      setPredictions(filteredObjects);
      setShowPredictions(true);
    } else {
      setPredictions([]);
      setShowPredictions(false);
    }
  };

  const addObject = (value) => {
    props.setObjects([
      ...props.objects,
      { name: value.Name, count: 1, mount: 0, unmount: 0 },
    ]);
    setSearchString("");
  };

  const setObject = (object, index) => {
    const updatedObjects = [...props.objects];
    updatedObjects[index] = object;
    props.setObjects(updatedObjects);
  };

  const removeObject = (objectIndex) => {
    const updatedObjects = [...props.objects].filter(
      (object, index) => objectIndex !== index
    );
    props.setObjects(updatedObjects);
  };

  return (
    <div className="objects-picker-container" data-testid="ObjectsPicker">
      <Form.Control
        required
        autoComplete="off"
        className={!!searchString ? "not-empty mb-3" : "empty mb-3"}
        value={searchString}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        disabled={props.disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={t("objectsFilter")}
      />
      {showPredictions && (
        <div className="objectinput-predictions">
          {predictions.map((prediction, index) => (
            <div
              key={index}
              className="objectlistitem-item"
              onClick={() => addObject(prediction)}
            >
              <div
                className="objectlistitem-itemname"
                data-content={prediction.Name}
              ></div>
            </div>
          ))}
        </div>
      )}
      <Row>
        {props.objects?.map((object, index) => (
          <Col key={index} xs={4}>
            <ObjectInput
              objectConfig={props.pricingConfig.Objects.find(
                (objectConfig) => objectConfig.Name === object.name
              )}
              key={index}
              object={object}
              setObject={(updatedObject) => setObject(updatedObject, index)}
              removeObject={() => removeObject(index)}
              disabled={props.disabled}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ObjectsPicker;
