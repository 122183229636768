import React from "react";
import "./EmailInput.css";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";

export const validateEmail = (email) => {
  const re = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
  return email.length === 0 || re.test(String(email).toLowerCase());
};

const EmailInput = (props) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="email">
      <InputGroup>
        <InputGroup.Prepend
          className={`customInputGroup ${
            props.disabled ? "disabledInputGroup" : ""
          }`}
        >
          <FontAwesomeIcon icon={faAt}> </FontAwesomeIcon>
        </InputGroup.Prepend>
        <Form.Control
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={t("email")}
          autoComplete="off"
          required
          disabled={props.disabled}
        />
      </InputGroup>
      {!validateEmail(props.value) && (
        <Form.Text className="address-invalidmsg">
          {t("invalidEmail")}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default EmailInput;
