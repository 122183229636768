import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import "./PhoneInput.css";

const phoneMask = "99 99 99 99 99";

export const validatePhone = (value) =>
  value.length === 0 || value.length === 10;

const beforeMaskedStateChange = ({ nextState }) => {
  let { value } = nextState;
  let selection = nextState.selection;

  if (value !== "" && value[0] !== "0") {
    value = "0" + value;
    selection = { start: value.length, end: value.length };
  }

  if (value.length > 14) {
    value = value.slice(0, 14);
  }

  return {
    value,
    selection,
  };
};

const PhoneInput = (props) => {
  const { t } = useTranslation();

  const updatePhone = (e) => {
    const phoneValue = e.target.value;
    const spaceless = phoneValue.replace(/[\s_]/g, "");
    props.onChange(spaceless);
  };

  return (
    <Form.Group controlId="phone">
      <InputGroup>
        <InputGroup.Prepend
          className={`customInputGroup ${
            props.disabled ? "disabledInputGroup" : ""
          }`}
        >
          <FontAwesomeIcon icon={faPhoneAlt}> </FontAwesomeIcon>
        </InputGroup.Prepend>
        <Form.Control
          as={InputMask}
          value={props.value}
          onChange={updatePhone}
          beforeMaskedStateChange={beforeMaskedStateChange}
          mask={phoneMask}
          placeholder={t("phone")}
          maskPlaceholder={null}
          required
          type="tel"
          autoComplete="off"
          disabled={props.disabled}
        />
      </InputGroup>
      {!validatePhone(props.value) && (
        <Form.Text className="address-invalidmsg">
          {t("missingPhoneLength", {
            number: 10 - props.value.length,
          })}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default PhoneInput;
