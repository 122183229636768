import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './Login.css';

import { login, isAuthenticated } from '../../utilities/auth';

const Login = () => {

  const { t } = useTranslation();

  const [creds, setCreds] = useState({});
  const [isLogging, setIsLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.title = 'Stuart XL';
  });

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsLogging(true)
      await login(creds);
    } catch ({ error = {} }) {
      const { message = "" } = error;
      setErrorMessage(message);
    } finally {
      setIsLogging(false)
    }
  }

  if (isAuthenticated()) return <Redirect to="/" />

  return (<Container fluid className="login-container">
    <Row className="login-main-row">
      <Col className="login-form-container">
        <img
          alt="Stuart XL Truck"
          src="/logo.svg"
          className="login-logo"
        />
        <Form onSubmit={handleSubmit}>
          <h2>{t("loginTitle")}</h2>
          <Form.Group controlId="formUsername">
            <Form.Label>{t("username")}</Form.Label>
            <Form.Control
              required
              size="lg"
              type="text"
              placeholder={t("username")}
              onChange={e => setCreds({ ...creds, username: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>{t("password")}</Form.Label>
            <Form.Control
              required
              size="lg"
              type="password"
              placeholder={t("password")}
              onChange={e => setCreds({ ...creds, password: e.target.value })}
            />
          </Form.Group>
          <Form.Row>
            <Col>
              <span className="login-errormessage">{errorMessage}</span>
            </Col>
          </Form.Row>
          <br />
          <Button variant="primary" type="submit" disabled={isLogging}>
            {isLogging && <FontAwesomeIcon icon={faSpinner} pulse />}
            {!isLogging && t("login")}
          </Button>
        </Form>
      </Col>
    </Row>
  </Container>)
};

Login.defaultProps = {};

export default Login;
