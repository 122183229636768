import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types';

import "./AddressListItem.css";

const typesIcons = {
  saved: { icon: faBookmark, color: "#1a5778" },
  prediction: { icon: faMapMarkerAlt, color: "#99a5b3" }
}

const AddressListItem = (props) => {

  const { t } = useTranslation();

  return (
    <div className="addresslistitem-item" onClick={props.onClick}>
      <div className="addresslistitem-itemicon">
        <FontAwesomeIcon
          icon={typesIcons[props.type].icon}
          size="lg"
          color={typesIcons[props.type].color}
        />
      </div>
      <div
        className="addresslistitem-itemname"
        data-alt={t("noNameAddress")}
        data-content={props.title}
      ></div>
      <div className="addresslistitem-itemaddress">{props.subtitle}</div>
    </div>
  )
};

AddressListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["saved", "prediction"]),
  onClick: PropTypes.func
};

AddressListItem.defaultProps = {};

export default AddressListItem;
