import React, { useState, useEffect } from "react";
import { Container, Col, ListGroup, Row, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { loadClient } from "../../utilities/clients.js";
import PageSpinner from "../PageSpinner";
import ClientUsers from "../ClientUsers";
import "./ClientInfos.css";

const ClientInfos = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [client, setClient] = useState({});

  useEffect(() => {
    loadClient(id).then(setClient);
  }, [id]);

  if (!client || !client.ID) return <PageSpinner></PageSpinner>;

  return (
    <Container>
      <br />
      <Card>
        <br />
        <Container>
          <Row>
            <Col>
              <h3> {client.Name} </h3>
            </Col>
            <Col xs="auto">
              <Button variant="light" as={Link} to={`/clients/form/${id}`}>
                {t("editClient")}
              </Button>
              &nbsp; &nbsp;
              {!client.Config.IsB2CClient && (
                <Button variant="info" as={Link} to={`/clients/prices/${id}`}>
                  {t("priceBuilder")}
                </Button>
              )}
              {client.Config.IsB2CClient && (
                <Button
                  variant="info"
                  as={Link}
                  to={`/clients/b2cprices/${id}`}
                >
                  {t("priceBuilder")}
                </Button>
              )}
              {client.Config.InStore.Enabled && (
                <>
                  &nbsp; &nbsp;{" "}
                  <Button
                    variant="info"
                    as={Link}
                    to={`/b2cWorkingHoursConfig/${id}`}
                  >
                    {t("workingHoursConfig")}
                  </Button>
                </>
              )}
            </Col>
          </Row>
          <br />
          {client.Config.InStore.Enabled && 
          <>
            <Row>
              <Col>
                <h4>{t("Client Details")}</h4>{" "}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="clientinfos-subsection">
                  {t("linkToStore")} : <a href={`${process.env.REACT_APP_API_URL}/short/${client.Config.InStore.URL.ShortUrl}`}>{process.env.REACT_APP_API_URL+"/short/"+client.Config.InStore.URL.ShortUrl}</a>
                </div>
              </Col>
            </Row>
          </>}
          <br/>
          <Row>
            <Col>
              {" "}
              <h4>{t("deliveriesConfig")}</h4>{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="clientinfos-subsection">
                <h6>
                  {t("clientType")}:
                  {client.Config.InStore.Enabled && " " + t("store")}
                  {client.Config.IsB2CClient && " " + t("b2c")}
                  {!(
                    client.Config.InStore.Enabled || client.Config.IsB2CClient
                  ) && " " + t("b2b")}
                </h6>
              </div>
              <br />
              <div className="clientinfos-subsection">
                <h6>
                  {t("showPricing")}:&nbsp;
                  {client.Config.ShowPricing && " " + t("yes")}
                  {!client.Config.ShowPricing && " " + t("no")}
                </h6>
              </div>
              <br />
              <div className="clientinfos-subsection">
                <h6>
                  {t("stackedDeliveriesEnabled")}:&thinsp;
                  {client.Config.EnableStackedDeliveries && " " + t("yes")}
                  {!client.Config.EnableStackedDeliveries && " " + t("no")}
                </h6>
              </div>
              <br />
              <div className="clientinfos-subsection">
                <h6>
                  {t("selectCustomSlotType")}:
                  {client.Config.SlotType === "Custom" && " " + t("yes")}
                  {client.Config.SlotType !== "Custom" && " " + t("no")}
                </h6>
              </div>
              <br />
              {client.Config.EnableVehicleTypeSelection.Enabled && (
                <div className="clientinfos-subsection">
                  <h6>{t("enabledVTList")}</h6>
                  <ListGroup>
                    {client.Config.EnableVehicleTypeSelection.EnabledTypes.map(
                      (vehicle) => (
                        <ListGroup.Item key={vehicle}>
                          <Container>
                            <Row>
                              <Col xs={2}>
                                <img
                                  src={`/icons/${vehicle}.svg`}
                                  alt={vehicle}
                                />
                              </Col>
                              <Col> {t(vehicle)} </Col>
                            </Row>
                          </Container>
                        </ListGroup.Item>
                      )
                    )}
                  </ListGroup>
                </div>
              )}
            </Col>
            <Col>
              <div className="clientinfos-subsection">
                <Row>
                  <Col>
                    <h6>{t("options")}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {!client.Config.OrderOptions && (
                      <span>{t("noOption")}</span>
                    )}
                    <ListGroup>
                      {client.Config.OrderOptions &&
                        client.Config.OrderOptions.map((option) => (
                          <ListGroup.Item key={option}>
                            {" "}
                            {option}{" "}
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              {" "}
              <h4>{t("APIConfiguration")}</h4>{" "}
            </Col>
          </Row>
          <div className="clientinfos-subsection">
            <br />
            <Row>
              <Col md={4}>{t("APIUsername")}</Col>
              <Col md={8}>{client.StuartAPICredentials.Username}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("APIClientID")}</Col>
              <Col md={8}>{client.StuartAPICredentials.APIClientID}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("APISecret")}</Col>
              <Col md={8}>{client.StuartAPICredentials.Secret}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("APIOAuthToken")}</Col>
              <Col md={8}>{client.StuartAPICredentials.OAuthToken}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("clientId")}</Col>
              <Col md={8}>{client.StuartAPICredentials.ClientID}</Col>
            </Row>
            <hr />
            <Row>
              <Col md={4}>{t("WebhookUrl")}</Col>
              <Col md={8}>{client.StuartAPICredentials.WebhookUrl}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("WebhookHeader")}</Col>
              <Col md={8}>{client.StuartAPICredentials.WebhookHeader}</Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>{t("WebhookKey")}</Col>
              <Col md={8}>{client.StuartAPICredentials.WebhookKey}</Col>
            </Row>
            <br />
          </div>
          <Row>
            {" "}
            <Col>
              {" "}
              <ClientUsers clientId={id} />{" "}
            </Col>{" "}
          </Row>
        </Container>
        <br />
      </Card>
      <br />
    </Container>
  );
};

ClientInfos.defaultProps = {};

export default ClientInfos;
