import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Container, Col, Row, Card, Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPen, faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Slideout from "../Slideout";
import PricePreview from "../PricePreview";
import PriceRule from "../PriceRule";
import PageSpinner from "../PageSpinner";
import Skeleton from 'react-loading-skeleton';
import CustomTable from "../CustomTable";
import { loadClient } from "../../utilities/clients.js";
import { loadPricing, savePricing, loadAttributes, saveDraftPricing, deleteDraft, loadDraftAttributes } from "../../utilities/prices.js";
import { ToastContainer, toast, Slide } from 'react-toastify';
import { GlobalStateContext } from '../../state';
import { toastOptions } from '../../state/constants';
import { useBeforeunload } from 'react-beforeunload';

import "./PriceBuilder.css"
import 'react-toastify/dist/ReactToastify.css';

const defaultNewAttribute = {
  name: "",
  type: "input",
  options: [],
  thresholds: [0],
}

const typesTexts = {
  input: {
    label: "createThreshold",
    attrName: "thresholds",
    inputType: "number",
    initialValue: (lastValue = -1) => parseInt(lastValue) + 1,
  },
  select: {
    label: "createOption",
    attrName: "options",
    inputType: "text",
    initialValue: () => "",
  },
}

let priceIdInc = 1;

const PriceBuilder = () => {

  const { t } = useTranslation();
  const { id } = useParams();

  const [ state, { dispatch }] = useContext(GlobalStateContext);
  const [client, setClient] = useState({});
  const [pricing, setPricing] = useState({prices: [], attributes: []});
  const [attrFormVisible, setAttrFormVisible] = useState(false);
  const [newAttribute, setNewAttribute] = useState(defaultNewAttribute);
  const [stagedPrices, setStagedPrices] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingAttrIndex, setDeletingAttrIndex] = useState(null);
  const [isEditingAttribute, setIsEditingAttribute] = useState(false);

  useBeforeunload((event) => {
    if(canSave) {
      event.preventDefault()
      return t("confirmLeave");
    }
  });

  const cleanPricing = value => ({
    ...value,
    prices: value.prices.map(p => {
      const {id, ...cleanPrice} = p;
      return cleanPrice;
    })
  });

  const setPricingFromBack = useCallback(async pricingFromBack => {
    const withIds = {...pricingFromBack, prices: pricingFromBack.prices.map(p => ({
      ...p,
      id: priceIdInc++,
    }))}
    setPricing(withIds)
    await loadDraftAttributes(dispatch, id)
  }, [])

  const initPricing = useCallback(() => {
    setIsLoading(true);
    loadPricing(id).then(setPricingFromBack).finally(() => {setIsLoading(false)});
  }, [id, setPricingFromBack])

  const setPricingAndDraft = async value => {
    try {
    const cleanValue = cleanPricing({...value, client_account_id: id});
    const response = await saveDraftPricing(cleanValue, dispatch)
    setPricingFromBack(response);
    await loadDraftAttributes(dispatch, id)
    } catch(r) {
      toast.error(r.error.message, toastOptions);
    }
  }

  useEffect(() => {
    deleteDraft(id);
    loadClient(id).then(setClient)
    initPricing();
  }, [id, initPricing])

  const typeTexts = typesTexts[newAttribute.type];

  const setNewAttributeProp = (field, value) => {
    setNewAttribute({...newAttribute, [field]: value})
  }

  const updateOpthold = (index, value) => {
    const { attrName } = typeTexts;
    const current = [...newAttribute[attrName]];
    current[index] = newAttribute.type === "input" ? parseFloat(value) : value;
    setNewAttributeProp(attrName, [...current])
  }

  const removeOpthold = (index) => e => {
    const { attrName } = typeTexts;
    const current = [...newAttribute[attrName]];
    current.splice(index, 1)
    setNewAttributeProp(attrName, [...current])
  }

  const addOpthold = () => {
    const { attrName, initialValue } = typeTexts;
    const current = newAttribute[attrName];
    setNewAttributeProp(attrName, [...current, initialValue(current.slice(-1)[0])])
  }

  const showAttrForm = (editMode) => {
    setAttrFormVisible(true)
    setIsEditingAttribute(editMode);
  }

  const cancelAttributeForm = () => {
    setNewAttribute(defaultNewAttribute)
    setAttrFormVisible(false);
  }

  const selectAttribute = (index) => e => {
    setNewAttribute({
      ...defaultNewAttribute,
      ...pricing.attributes[index],
      index
    })
    showAttrForm(true);
  }

  const editAttributeName = (prices, oldAttrName, newName) => {
    prices.forEach(p => {
      const r = p.rule
      if (r.hasOwnProperty(oldAttrName)){
        r[newName] = r[oldAttrName]
        delete r[oldAttrName]
      }
    })
  }

  const getChangedOptions = (newAttr, oldAttr) => {
    const newOptions = newAttr[typeTexts.attrName];
    const oldOptions = oldAttr[typeTexts.attrName];
    return oldOptions.map((oldOption, i) => {
      if(oldOption !== newOptions[i]) return { oldOption, newOption: newOptions[i] };
      else return undefined;
    }).filter(Boolean);
  }

  const pricingAfterCreateAttr = (newValue) => {
    const attributes = [
      ...pricing.attributes,
      newValue,
    ];
    const ruleFragment = { 
      type: newValue.type, 
      option: newValue.type === "select" ? newValue.options[0] : "", 
      threshold: newValue.type === "input" ? newValue.thresholds[0] : 0 
    }
    const prices = pricing.prices.map(price => ({ ...price, rule: { ...price.rule, [newValue.name]: ruleFragment } }))
    return { ...pricing, attributes, prices };
  }

  const pricingAfterEditAttr = (newValue, index) => {
    const attributes = pricing.attributes.map((attr, i) => i === index ? newValue : attr);
    const prices = [...pricing.prices]
    const oldValue = pricing.attributes[index]
    if (oldValue.name !== newValue.name) {
      editAttributeName(prices, oldValue.name, newValue.name)
      prices.forEach(price => {
        if(price.dynamic_attr === oldValue.name) price.dynamic_attr = newValue.name;
      })
    }
    const changedOptions = getChangedOptions(newValue, oldValue)
    const optionsField = newValue.type === "input" ? "threshold" : "option";
    changedOptions.forEach(({oldOption, newOption}) => {
      prices.forEach(price => {
        if(price.rule[newValue.name] && price.rule[newValue.name][optionsField] === oldOption) {
          price.rule[newValue.name][optionsField] = newOption;
        }
      })
    })
    return { prices, attributes } 
  }

  const submitAttribute = () => {
    if(newAttribute.options.some(el => el === "")) {
      toast.error(t("predefinedOptionEmpty"), toastOptions)
      return;
    }
    if(!newAttribute[typeTexts.attrName].length) {
      toast.error(t("optholdEmpty"), toastOptions)
      return;
    }
    const newValue = {
      name: newAttribute.name,
      type: newAttribute.type,
      [typeTexts.attrName]: newAttribute[typeTexts.attrName],
    };
    const { index } = newAttribute;
    const newPricing = index !== undefined ? pricingAfterEditAttr(newValue, index) : pricingAfterCreateAttr(newValue);
    setPricingAndDraft(newPricing);
    cancelAttributeForm()
    setCanSave(true);
    setIsEditingAttribute(false);
  }

  const confirmRemoveAttr = (index) => e => {
    setDeletingAttrIndex(index);
  }

  const hideConfirmAttrDelete = () => {
    setDeletingAttrIndex(null);
  }

  const removeAttribute =  e => {
    setPricingAndDraft({
      attributes: pricing.attributes.map((attr, index) => {
        if(index === deletingAttrIndex) return undefined;
        return attr;
      }).filter(Boolean),
      prices: pricing.prices.map(price => ({
        ...price,
        rule: Object.keys(price.rule || {}).reduce((obj, attrName) => ({
          ...obj,
          ...(attrName === pricing.attributes[deletingAttrIndex].name ? {} : {[attrName]: price.rule[attrName]})
        }), {})
      }))
    });
    setCanSave(true);
    hideConfirmAttrDelete()
  }

  const updatePrice = (value, id) => {
    if (isRuleAlreadyExisting(value.rule, id)) {
      toast.error(t("ruleAlreadyExist"), toastOptions);
      return;
    }
    setPricingAndDraft({
      ...pricing,
      prices: pricing.prices.map(p => {
        if(id !== p.id) return p;
        return {...p, ...value};
      })
    })
    setCanSave(true);
  }

  const defaultRule = () => {
    const reduceAttr = (obj, attr) => ({
      ...obj,
      [attr.name]: {
        ...(attr.thresholds ? {threshold: attr.thresholds[0]} : {}),
        ...(attr.options ? {option: attr.options[0]} : {}),
        type: attr.type
      }
    })
    return pricing.attributes.reduce(reduceAttr, {})
  }

  const defaultPrice = () => ({
    id: priceIdInc++,
    staged: true,
    price: 0,
    type: "static",
    rule: defaultRule()
  })

  const addStagedPrice = () => {
    setStagedPrices([ ...stagedPrices, defaultPrice() ])
  }

  const cancelStagedPrice = id => {
    setStagedPrices(stagedPrices.map(p => id === p.id ? null : p).filter(Boolean));
  }

  const isRuleAlreadyExisting = (newRule, newRuleId) => {
    return pricing.prices.some(
      (p) =>
        p.id !== newRuleId &&
        !Object.keys(p.rule).some((attr) =>
          p.rule[attr].type === "input"
            ? p.rule[attr].threshold !== newRule[attr].threshold
            : newRule[attr] && p.rule[attr].option !== newRule[attr].option
        )
    );
  };

  const addPrice = (price, id) => {
    if (isRuleAlreadyExisting(price.rule, id)) {
      toast.error(t("ruleAlreadyExist"), toastOptions);
      return;
    }
    const {staged, ...cleanPrice} = {...price, id};
    setPricingAndDraft({
     ...pricing,
     prices: [ ...pricing.prices, cleanPrice]
   })
    cancelStagedPrice(id);
    setCanSave(true);
  }

  const removePrice = id => {
    setPricingAndDraft({
      ...pricing,
      prices: pricing.prices.map(price => {
        if(id === price.id) return undefined;
        return price;
      }).filter(Boolean)
    });
    setCanSave(true);
  }

  const submitPrices = async () => {
    try {
      setIsSaving(true);
      await savePricing(cleanPricing({...pricing, client_account_id: id}), dispatch);
      loadAttributes(dispatch, id);
      const toastMsg = t("priceSaved");
      const options = { ...toastOptions, autoClose: 2000 }
      toast.success(toastMsg, options);
    } catch (response) {
      toast.error(response.error.message, toastOptions);
    } finally {
      setCanSave(false);
      setIsSaving(false);
    }
  }

  const discardChanges = async () => {
    try {
      await deleteDraft(id);
      setStagedPrices([]);
      initPricing();
      setCanSave(false);
    } catch(response) {
      toast.error(response.error.message, toastOptions);
    }
  }

  const setUploadedPricing = (e) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setPricingAndDraft(JSON.parse(e.target.result));
      setCanSave(true);
    };
    reader.readAsText(e.target.files[0]);
  };

  useEffect(() => {
    document.title = `${t("priceBuilder")} | Stuart XL`;
  });

  const saveBtnVariant = canSave ? "success" : "light";
  const saveBtnDisabled = !canSave || isSaving;

  if(!client || !client.ID) return <PageSpinner></PageSpinner>

  return (
    <Container>
      <br/>
      <Row>
        <Col>
          <Card>
            <Container>
              <br/>
              <Row>
                <Col>
                  <Link to={`/clients/infos/${id}`} className="pricebuilder-backlink">
                    <FontAwesomeIcon icon={faArrowLeft} size="lg"/>
                    &nbsp;
                    { t("backToClientPage") }
                  </Link>
                </Col>
                <Col xs="auto">
                  <Button
                    id="downloadRulesButton"
                    variant="info"
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      JSON.stringify({attributes: pricing.attributes, prices: pricing.prices})
                    )}`}
                    download={`Règles de ${client.Name}.json`}
                  >
                    {t("downloadRules")}
                  </Button>
                </Col>
                <Col xs="auto">
                  <label className="btn btn-info" htmlFor="rulesUploadInput">
                    {t("uploadRules")}
                    <input
                      type="file"
                      className="d-none"
                      id="rulesUploadInput"
                      onChange={(e) => {
                        setUploadedPricing(e);
                      }}
                    />
                  </label>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col><h3>{t("priceBuilder")} {t("of")} {client.Name}</h3></Col>
              </Row>
              <br/>
              <Row>
                <Col><h4>{t("attributes")}</h4></Col>
              </Row>
              <Row>
                <Col>
                  <CustomTable>
                    <thead>
                      <tr>
                        <th>{t("attributeName")}</th>
                        <th>{t("attributeType")}</th>
                        <th>{t("thresholds")}/{t("options")}</th>
                        <th>{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {pricing.attributes.map((attribute, index) =>(
                      <tr key={index}>
                        <td>{attribute.name}</td>
                        <td>{t(attribute.type)}</td>
                        <td>{(attribute.thresholds || attribute.options).join(", ")}</td>
                        <td>
                          <FontAwesomeIcon icon={faTimes} className="pricebuilder-actionicon" onClick={confirmRemoveAttr(index)}/>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <FontAwesomeIcon icon={faPen} className="pricebuilder-actionicon" onClick={selectAttribute(index)}/>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </CustomTable>
                </Col>
              </Row>
              <br/>
              { !attrFormVisible && <Row>
                <Col xs={3}>
                  <Button id="createAttributeButton" variant="info" onClick={() => showAttrForm(false)}>
                    { t("createAttribute") }
                  </Button>
                </Col>
              </Row> }
              {attrFormVisible && <Form>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="attrName">
                      <Form.Label>{t("attributeName")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("attributeNamePlaceholder")}
                        value={newAttribute.name}
                        onChange={e => setNewAttributeProp("name", e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                  {!isEditingAttribute && <Form.Group controlId="attrType">
                      <Form.Label>{t("attributeType")}</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder={t("attributePlaceholder")}
                        value={newAttribute.type}
                        onChange={e => setNewAttributeProp("type", e.target.value)}
                      >
                        <option value="input">{t("input")}</option>
                        <option value="select">{t("select")}</option>
                      </Form.Control>
                    </Form.Group>}
                  </Col>
                </Form.Row>
                <Form.Row>
                  <h5>{t(typeTexts.attrName)}</h5>
                </Form.Row>
                {newAttribute[typeTexts.attrName].map((opthold, index) => (
                  <Form.Row key={index}>
                    <Col>
                      <Form.Group controlId={`opthold${index}`}>
                        <Form.Label data-suffix={t(typeTexts.attrName + "_prefix")}>
                          {t(typeTexts.attrName + "_label")}
                        </Form.Label>
                        <Form.Control
                          type={typeTexts.inputType}
                          placeholder={`${t(typeTexts.attrName + "_placeholder")}`}
                          value={opthold}
                          onChange={e => updateOpthold(index, e.target.value)}
                        >
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon className="pricebuilder-actionicon" icon={faTimes} onClick={removeOpthold(index)}/>
                    </Col>
                  </Form.Row>
                ))}
                <Form.Row>
                  <Col><Button id="addAttributeOptionButton" variant="info" onClick={addOpthold}>{t(typeTexts.label)}</Button></Col>
                </Form.Row>
                <Form.Row>
                  <Col></Col>
                  <Col xs={3}>
                    <Button variant="light" onClick={cancelAttributeForm}>{t("cancel")}</Button>
                    &nbsp;
                    <Button variant="success" onClick={submitAttribute}>{t("confirm")}</Button>
                  </Col>
                </Form.Row>
              </Form> }
              <br/>
              <Row>
                <Col><h4>{t("rules")}</h4></Col>
              </Row>
              {isLoading && <Row>
                <Col>
                  <h5><Skeleton /></h5>
                  <Skeleton count={3}/>
                  <br/>
                  <h5><Skeleton /></h5>
                  <Skeleton count={3}/>
                </Col>
              </Row>}
              {!isLoading && <div>
                {pricing.prices.map(price => (
                  <PriceRule
                    key={price.id}
                    rule={price}
                    attributes={pricing.attributes}
                    onRemove={removePrice}
                    onUpdate={updatePrice}
                  />
                ))}
                {stagedPrices.map(price => (
                  <PriceRule
                    key={price.id}
                    rule={price}
                    attributes={pricing.attributes}
                    onRemove={cancelStagedPrice}
                    onUpdate={addPrice}
                  />
                ))}
              </div>}
              <br/>
              <Row>
                <Col><Button variant="info" onClick={() => addStagedPrice()}>{t("addRule")}</Button></Col>
              </Row>
              <Row>
                <Col></Col>
                <Col xs="auto">
                  { canSave &&
                  <Button variant="outline-danger" onClick={discardChanges} >
                      {isSaving && <FontAwesomeIcon icon={faSpinner} pulse/>}
                      {!isSaving && t("discardChanges") }
                  </Button>
                  }
                  &nbsp;&nbsp;
                  <Button
                    variant={saveBtnVariant}
                    onClick={submitPrices}
                    disabled={saveBtnDisabled}
                    >
                      {isSaving && <FontAwesomeIcon icon={faSpinner} pulse/>}
                      {!isSaving && canSave && t("submitPrices") }
                      {!isSaving && !canSave && t("waitingChanges") }
                  </Button>
                </Col>
              </Row>
              <br/>
            </Container>
          </Card>
        </Col>
      </Row>
      <ToastContainer transition={Slide} />
      <Slideout>
        <Container fluid>
          <PricePreview
            title={t("deployedDemo")}
            type="deployed"
            clientId={id}
          />
          <hr/>
          <PricePreview
            title={t("draftDemo")}
            type="draft"
            clientId={id}
            second
          />
        </Container>
      </Slideout>
      <Modal show={deletingAttrIndex !== null} onHide={hideConfirmAttrDelete}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <p>{t("confirmRemoveAttr", {name: (pricing.attributes[deletingAttrIndex] || {}).name || ""})}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={hideConfirmAttrDelete}>{t("no")}</Button>
          <Button variant="info" onClick={removeAttribute}>{t("yes")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={state.isSavingDraft || state.isSavingPricing} >
        <Modal.Body>
          {t("savingWait")}
          &nbsp;
          <FontAwesomeIcon icon={faSpinner} pulse/>
        </Modal.Body>
      </Modal>
    </Container>
  )
};

PriceBuilder.defaultProps = {};

export default PriceBuilder;
