import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import AddressInput from "../AddressInput/AddressInput";
import { useTranslation } from "react-i18next";

import "./RequestAddressInfo.css";
import PhoneInput from "../PhoneInput/PhoneInput";

export const RequestAddressInfo = (props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <Row>
          {!props.hiddenInputs.includes("lastName") && (
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend
                    className={`customInputGroup ${
                      props.disabled ? "disabledInputGroup" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faUser}> </FontAwesomeIcon>
                  </InputGroup.Prepend>
                  <Form.Control
                    onChange={(e) => props.onLastNameChange(e.target.value)}
                    type="text"
                    value={props.addressinfo.contact.lastname}
                    placeholder={t("lastName")}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          )}
          {!props.hiddenInputs.includes("firstname") && (
            <Col>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend
                    className={`customInputGroup ${
                      props.disabled ? "disabledInputGroup" : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faUser}> </FontAwesomeIcon>
                  </InputGroup.Prepend>
                  <Form.Control
                    onChange={(e) => props.onFirstNameChange(e.target.value)}
                    type="text"
                    value={props.addressinfo.contact.firstname}
                    placeholder={t("firstName")}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row>
          {!props.hiddenInputs.includes("address") && (
            <Col>
              <Form.Group controlId="deliveryAddress">
                <AddressInput
                  prependIcon={true}
                  onChange={props.onAddressChange}
                  hideSaved={true}
                  value={props.addressinfo}
                  disabled={!props.isDeliveryEditableField("address")}
                  error={props.validAddress?.error || ""}
                />
              </Form.Group>
            </Col>
          )}

          {!props.hiddenInputs.includes("phone") && (
            <Col>
              <PhoneInput
                value={props.addressinfo.contact.phone}
                disabled={!props.isDeliveryEditableField("phone")}
                onChange={props.onPhoneChange}
              />
            </Col>
          )}
        </Row>
        {!props.hiddenInputs.includes("comment") && (
          <Form.Group controlId="comment">
            <Form.Control
              type="text"
              value={props.addressinfo.comment}
              onChange={props.onCommentChange}
              autoComplete="off"
              disabled={!props.isDeliveryEditableField("comment")}
              placeholder={t("comment")}
            />
          </Form.Group>
        )}

        {!props.hiddenInputs.includes("package_description") && (
          <Form.Group controlId="objects">
            <Form.Control
              autoComplete="off"
              type="text"
              value={props.addressinfo.package_description}
              onChange={props.onPackageDescriptionChange}
              disabled={!props.isDeliveryEditableField("package_description")}
              placeholder={t("objects")}
            />
          </Form.Group>
        )}
      </Col>
    </Row>
  );
};

RequestAddressInfo.defaultProps = {
  type: "picking",
  hiddenInputs: [],
};

export default RequestAddressInfo;
