import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { isAuthenticated } from '../../utilities/auth';

const PrivateRoute = (props) => (
  <Fragment>
    {isAuthenticated() ? props.children : <Redirect to="/login" />}
  </Fragment>
)


PrivateRoute.defaultProps = {};

export default PrivateRoute;
