import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Col,
  Form,
  ListGroup,
  Row,
  Card,
  Button,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  createClient,
  loadClient,
  editClient,
} from "../../utilities/clients.js";
import { useHistory } from "react-router-dom";
import PageSpinner from "../PageSpinner";
import { ToastContainer, toast, Slide } from "react-toastify";
import { toastOptions } from "../../state/constants";
import "./ClientForm.css";
import { Fragment } from "react";
import Address from "../Address";

const vehicles = [
  "walk",
  "bike",
  "car",
  "cargobike",
  "motorbike",
  "van",
  "truck",
];

const defaultClient = {
  orderOptions: [],
};

const emptyContact = { firstname: "", lastname: "", phone: "", email: "" };

const emptyAddress = { address: "", contact: emptyContact, comment: "" };

const ClientForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [client, setClient] = useState(defaultClient);
  const [enabledVT, setEnabledVT] = useState(
    vehicles.reduce((obj, v) => ({ ...obj, [v]: false }), {})
  );
  const [newOption, setNewOption] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stagedStoreAddress, setStagedStoreAddress] = useState(emptyAddress);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setStagedStoreAddress(client.inStoreAddress);
  };

  const getClientType = () => {
    if (client.inStore) return "Store";
    if (client.isB2cClient) return "B2C";

    return "B2B";
  };

  useEffect(() => {
    if (!show) {
      if (client.inStore && !client.inStoreAddress?.address) {
        setClient({ ...client, inStore: false, inStoreAddress: null });
      }
    }
  }, [show, client]);

  const updateEnabledVT = useCallback(
    (vehicle, checked) => {
      setEnabledVT({ ...enabledVT, [vehicle]: checked });
    },
    [enabledVT]
  );

  const mapClientFromAPI = useCallback((client) => {
    setClient({
      id: client.ID,
      name: client.Name,
      APIUsername: client.StuartAPICredentials.Username,
      APIClientId: client.StuartAPICredentials.APIClientID,
      APISecret: client.StuartAPICredentials.Secret,
      APIOAuthToken: client.StuartAPICredentials.OAuthToken,
      clientId: client.StuartAPICredentials.ClientID,
      WebhookUrl: client.StuartAPICredentials.WebhookUrl,
      WebhookHeader: client.StuartAPICredentials.WebhookHeader,
      WebhookKey: client.StuartAPICredentials.WebhookKey,
      enableVT: client.Config.EnableVehicleTypeSelection?.Enabled,
      enableStackedDeliveries: client.Config.EnableStackedDeliveries,
      showPricing: client.Config.ShowPricing,
      orderOptions: client.Config.OrderOptions || [],
      inStore: client.Config.InStore.Enabled,
      inStoreAddress: client.Config.InStore.PickupAddress,
      isB2cClient: client.Config.IsB2CClient,
      slotType: client.Config.SlotType,
      URL: client.Config.InStore.URL,
    });

    const clientVT =
      (client.Config.EnableVehicleTypeSelection || {}).EnabledTypes || [];
    setEnabledVT(
      vehicles.reduce((o, v) => ({ ...o, [v]: clientVT.includes(v) }), {})
    );
  }, []);

  useEffect(() => {
    if (id) loadClient(id).then(mapClientFromAPI);
  }, [id, mapClientFromAPI]);

  const updateClient = (field, value) => {
    setClient({ ...client, [field]: value });
  };

  const updateClientType = (value) => {
    if (value === "Store") {
      setClient({
        ...client,
        inStore: true,
        inStoreAddress: emptyAddress,
        enableVT: false,
        showPricing: true,
        enableStackedDeliveries: false,
        isB2cClient: false,
      });
      setShow(true);
      setStagedStoreAddress(client.inStoreAddress);
      return;
    }

    if (value === "B2C") {
      setClient({
        ...client,
        inStore: false,
        inStoreAddress: null,
        enableVT: false,
        showPricing: true,
        enableStackedDeliveries: true,
        isB2cClient: true,
      });
      return;
    }

    setClient({
      ...client,
      inStore: false,
      isB2cClient: false,
      inStoreAddress: null,
    });
  };

  const updateStagedStoreAddress = (value) => {
    setStagedStoreAddress(value);
  };

  const submitAddress = () => {
    setClient({ ...client, inStoreAddress: stagedStoreAddress });
    setShow(false);
  };

  const submitNewOption = () => {
    if (!newOption.length) return;
    if (client.orderOptions.includes(newOption)) return;
    setClient({ ...client, orderOptions: [...client.orderOptions, newOption] });
    setNewOption("");
  };

  const deleteOption = (index) => {
    setClient({
      ...client,
      orderOptions: client.orderOptions
        .map((o, i) => (i === index ? null : o))
        .filter(Boolean),
    });
  };

  const submit = async () => {
    try {
      setIsSubmitting(true);
      const submittedClient = {
        Name: client.name,
        StuartAPICredentials: {
          Username: client.APIUsername,
          APIClientID: client.APIClientId,
          Secret: client.APISecret,
          ClientID: client.clientId,
          OAuthToken: client.APIOAuthToken,
          WebhookUrl: client.WebhookUrl,
          WebhookHeader: client.WebhookHeader,
          WebhookKey: client.WebhookKey,
        },
        Config: {
          EnableVehicleTypeSelection: {
            Enabled: client.enableVT,
            EnabledTypes: Object.keys(enabledVT).filter((v) => enabledVT[v]),
          },
          EnableStackedDeliveries: client.enableStackedDeliveries,
          ShowPricing: client.showPricing,
          OrderOptions: client.orderOptions,
          InStore: {
            Enabled: client.inStore,
            PickupAddress: client.inStoreAddress,
            URL: client.URL || {ShortUrl: "", LongUrl: ""},
          },
          SlotType: client.slotType || "Standard",
          IsB2CClient: client.isB2cClient,
        },
      };
      if (client.id) {
        await editClient(client.id, submittedClient);
        history.push(`/clients/infos/${id}`);
      } else {
        const createdClient = await createClient(submittedClient);
        history.push(`/clients/infos/${createdClient.ID}`);
      }
    } catch (r) {
      toast.error(r.error.message, toastOptions);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitText = id ? "edit" : "create";

  if (id && !client.id) return <PageSpinner></PageSpinner>;

  return (
    <Fragment>
      <Container>
        <br />
        <Row>
          <Col>
            <Card>
              <Container>
                <br />
                <Row>
                  <Col>
                    <h3> {t("createClient")} </h3>
                  </Col>
                </Row>
                <br />
                <div className="clientform-subsection">
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                          placeholder={t("name")}
                          value={client.name}
                          onChange={(e) => {
                            updateClient("name", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                </div>
                <Row>
                  <Col>
                    <h4>{t("deliveriesConfig")}</h4>
                  </Col>
                </Row>
                <br />
                <div className="clientform-subsection">
                  <Row>
                    <Col md={6}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="6">
                          {t("clientType")}
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="select"
                            value={getClientType()}
                            onChange={(e) => {
                              updateClientType(e.target.value);
                            }}
                          >
                            <option value="B2B">{t("b2b")}</option>
                            <option value="Store">{t("store")}</option>
                            <option value="B2C">{t("b2c")}</option>
                          </Form.Control>
                        </Col>
                        <Col></Col>
                      </Form.Group>
                      {client.inStore && (
                        <Row>
                          <Form.Label column sm="4">
                            {t("storeAddress")}
                          </Form.Label>
                          <Col className="storeAddress" sm="6">
                            {client.inStoreAddress?.address}
                          </Col>
                          <Col className="storeAddress" sm="2">
                            <FontAwesomeIcon
                              className="clientform-actionicon"
                              icon={faPen}
                              onClick={() => {
                                setShow(true);
                                setStagedStoreAddress(client.inStoreAddress);
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <br />
                      <Form.Check
                        type="checkbox"
                        id="inStore"
                        label={t("showPricing")}
                        checked={client.showPricing}
                        onChange={(e) => {
                          updateClient("showPricing", e.target.checked);
                        }}
                        disabled={client.inStore || client.isB2cClient}
                      />
                      <br />
                      <Form.Check
                        type="checkbox"
                        id="enableStackedDeliveries"
                        label={t("enableStackedDeliveries")}
                        checked={client.enableStackedDeliveries}
                        onChange={(e) => {
                          updateClient(
                            "enableStackedDeliveries",
                            e.target.checked
                          );
                        }}
                        disabled={client.isB2cClient}
                      />
                      <br />
                      <Form.Check
                        type="checkbox"
                        id="enableVT"
                        label={t("enableVT")}
                        checked={client.enableVT}
                        onChange={(e) => {
                          updateClient("enableVT", e.target.checked);
                        }}
                        disabled={client.inStore || client.isB2cClient}
                      />
                      <br />
                      {client.enableVT && (
                        <div className="clientform-subsection">
                          <Form.Group>
                            <Form.Label>{t("enabledVTList")}</Form.Label>
                            {vehicles.map((vehicle) => (
                              <div key={vehicle}>
                                <Row as="label" className="clientform-vehicle">
                                  <Col xs="auto">
                                    <Form.Check
                                      type="checkbox"
                                      id={`vehicle${vehicle}`}
                                      checked={enabledVT[vehicle]}
                                      onChange={(e) => {
                                        updateEnabledVT(
                                          vehicle,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <img
                                      src={`/icons/${vehicle}.svg`}
                                      alt={vehicle}
                                    />
                                  </Col>
                                  <Col> {t(vehicle)} </Col>
                                </Row>
                                <br />
                              </div>
                            ))}
                          </Form.Group>
                          <br />
                        </div>
                      )}
                      {client.inStore && (
                        <Form.Check
                          type="checkbox"
                          id="selectCustomSlotType"
                          label={t("selectCustomSlotType")}
                          checked={client.slotType === "Custom" ? true : false}
                          onChange={(e) => {
                            updateClient(
                              "slotType",
                              e.target.checked ? "Custom" : "Standard"
                            );
                          }}
                          disabled={client.isB2cClient}
                        />
                      )}
                      <br />
                    </Col>
                    <Col>
                      <h5>{t("deliveriesOptions")}</h5>
                      <div className="clientform-subsection">
                        {client.orderOptions.length === 0 && (
                          <div>
                            <Row>
                              <Col className="clientform-hint">
                                {t("noOption")}
                              </Col>
                            </Row>
                            <br />
                          </div>
                        )}
                        <Row>
                          <Col md={6}>
                            <ListGroup variant="flush">
                              {client.orderOptions.map((option, index) => (
                                <ListGroup.Item key={option}>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => deleteOption(index)}
                                    className="clientform-actionicon"
                                  />
                                  &nbsp;
                                  {option}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="auto">
                            <Form.Group controlId="newOption">
                              <Form.Control
                                placeholder={t("newOption")}
                                value={newOption}
                                onChange={(e) => {
                                  setNewOption(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Button
                              variant="info"
                              onClick={() => {
                                submitNewOption();
                              }}
                            >
                              {t("add")}
                            </Button>
                          </Col>
                        </Row>
                        <br />
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <h4>{t("APIConfiguration")}</h4>
                  </Col>
                </Row>
                <br />
                <div className="clientform-subsection">
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="APIUsername">
                        <Form.Label>{t("APIUsername")}</Form.Label>
                        <Form.Control
                          placeholder={t("APIUsername")}
                          value={client.APIUsername}
                          onChange={(e) => {
                            updateClient("APIUsername", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="APIClientId">
                        <Form.Label>{t("APIClientId")}</Form.Label>
                        <Form.Control
                          placeholder={t("APIClientId")}
                          value={client.APIClientId}
                          onChange={(e) => {
                            updateClient("APIClientId", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="APISecret">
                        <Form.Label>{t("APISecret")}</Form.Label>
                        <Form.Control
                          placeholder={t("APISecret")}
                          value={client.APISecret}
                          onChange={(e) => {
                            updateClient("APISecret", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="APIOAuthToken">
                        <Form.Label>{t("APIOAuthToken")}</Form.Label>
                        <Form.Control
                          placeholder={t("APIOAuthToken")}
                          value={client.APIOAuthToken}
                          onChange={(e) => {
                            updateClient("APIOAuthToken", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="clientId">
                        <Form.Label>{t("clientId")}</Form.Label>
                        <Form.Control
                          placeholder={t("clientId")}
                          value={client.clientId}
                          onChange={(e) => {
                            updateClient("clientId", e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  {id && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="WebhookUrl">
                            <Form.Label>{t("WebhookUrl")}</Form.Label>
                            <Form.Control
                              placeholder={t("WebhookUrl")}
                              value={client.WebhookUrl}
                              onChange={(e) => {
                                updateClient("WebhookUrl", e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="WebhookHeader">
                            <Form.Label>{t("WebhookHeader")}</Form.Label>
                            <Form.Control
                              placeholder={t("WebhookHeader")}
                              value={client.WebhookHeader}
                              onChange={(e) => {
                                updateClient("WebhookHeader", e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col md={6}>
                          <Form.Group controlId="WebhookKey">
                            <Form.Label>{t("WebhookKey")}</Form.Label>
                            <Form.Control
                              placeholder={t("WebhookKey")}
                              value={client.WebhookKey}
                              onChange={(e) => {
                                updateClient("WebhookKey", e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                    </div>
                  )}
                </div>
                <Row>
                  <Col></Col>
                  <Col xs="auto">
                    <Button
                      variant="success"
                      onClick={submit}
                      disabled={isSubmitting}
                    >
                      {!isSubmitting && t(submitText)}
                      {isSubmitting && (
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      )}
                    </Button>
                  </Col>
                </Row>
                <br />
              </Container>
            </Card>
            <br />
          </Col>
        </Row>
        <ToastContainer transition={Slide} />
      </Container>

      <Modal animation={false} show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("addressDetails")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Address
            id="pickup-component"
            query={stagedStoreAddress}
            clientId={client.id || "admin"}
            setter={updateStagedStoreAddress}
            address={t("pickup")}
            illustration="/pickup-illustration.svg"
            hideSaveOption={true}
            hideSavedAddresses={true}
            noTitle
          />
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={{ span: 4, offset: 8 }}>
                <Button variant="outline-secondary" onClick={handleClose}>
                  {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                  {!isSubmitting && t("cancel")}
                </Button>
                &nbsp;
                <Button
                  variant="info"
                  onClick={() => {
                    submitAddress();
                  }}
                  disabled={!stagedStoreAddress?.isValid}
                >
                  {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                  {!isSubmitting && t("submit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

ClientForm.propTypes = {};

ClientForm.defaultProps = {};

export default ClientForm;
