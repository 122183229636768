import debouncePromise from "debounce-promise";
import { authorization } from "./auth.js";
import { UPDATE_STATE } from "../state/constants";
import { fetchWrapper } from "./utils";
import { defaultPrice } from "../state/constants";
import { saveAs } from "file-saver";

let loadPriceController;
let loadDraftPriceController;

export const loadPricing = async (clientId) => {
  const url = `${process.env.REACT_APP_API_URL}/client/${clientId}/pricing/prod`;
  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  });
  if (!response.ok) {
    throw await response.json();
  }
  const {
    response: { id, _id, ...pricing },
  } = await response.json();
  return { ...pricing, attributes: pricing.attributes || [] };
};

export const savePricing = async (data, dispatch) => {
  const { client_account_id, ...body } = data;
  dispatch({ type: UPDATE_STATE, payload: { isSavingPricing: true } });
  const url = `${process.env.REACT_APP_API_URL}/client/${client_account_id}/pricing/prod`;
  const response = await fetchWrapper(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { authorization: authorization() },
  });
  dispatch({ type: UPDATE_STATE, payload: { isSavingPricing: false } });
  if (!response.ok) {
    throw await response.json();
  }
  const { success } = await response.json();
  return success;
};

export const loadPrice = debouncePromise(async (dispatch, id, data) => {
  if (loadPriceController) loadPriceController.abort();
  loadPriceController = new AbortController();
  dispatch({ type: UPDATE_STATE, payload: { isLoadingPrice: true } });
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/pricing/prod/price`;
  convertDistanceToString(data);
  const response = await fetchWrapper(url, {
    method: "POST",
    headers: { authorization: authorization() },
    body: JSON.stringify(data),
    signal: loadPriceController.signal,
  });
  dispatch({ type: UPDATE_STATE, payload: { isLoadingPrice: false } });

  let price = defaultPrice;
  if (!response.ok) {
    price.error = "errorLoadingPrice";
  } else {
    let rsp = await response.json();
    if (rsp.success) {
      price = rsp.response;
    } else {
      price.error = rsp.error.message;
    }
  }

  dispatch({ type: UPDATE_STATE, payload: { price } });

  return price;
}, 1000);

export const loadRequestPrice = async (id, orderId, data) => {
  if (loadPriceController) loadPriceController.abort();
  loadPriceController = new AbortController();
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/price/${orderId}`;
  const response = await fetchWrapper(url, {
    method: "POST",
    headers: { authorization: authorization() },
    body: JSON.stringify(data),
    signal: loadPriceController.signal,
  });

  if (!response) {
    throw new Error("errorLoadingPrice");
  }

  if (!response.ok) {
    throw await response.json();
  }

  const {
    response: { price, total_distance, deliveries, promocode },
  } = await response.json();

  return { price, total_distance, deliveries, promocode };
};

export const loadAttributes = async (dispatch, id) => {
  dispatch({ type: UPDATE_STATE, payload: { isLoadingAttributes: true } });
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/pricing/prod/attributes`;
  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  });
  dispatch({ type: UPDATE_STATE, payload: { isLoadingAttributes: false } });

  if (!response.ok) {
    throw await response.json();
  }
  const { response: attributes } = await response.json();
  dispatch({ type: UPDATE_STATE, payload: { attributes: attributes || [] } });

  return attributes || [];
};

export const saveDraftPricing = async (data, dispatch) => {
  dispatch({ type: UPDATE_STATE, payload: { isSavingDraft: true } });
  const { client_account_id, ...body } = data;
  const url = `${process.env.REACT_APP_API_URL}/client/${client_account_id}/pricing/draft`;
  const response = await fetchWrapper(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { authorization: authorization() },
  });
  dispatch({ type: UPDATE_STATE, payload: { isSavingDraft: false } });
  if (!response.ok) {
    throw await response.json();
  }
  const { response: pricing } = await response.json();
  return pricing;
};

export const deleteDraft = (id) => {
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/pricing/draft`;
  fetchWrapper(url, {
    method: "DELETE",
    headers: { authorization: authorization() },
  });
};

export const loadDraftPrice = debouncePromise(async (dispatch, id, data) => {
  if (loadDraftPriceController) loadDraftPriceController.abort();
  loadDraftPriceController = new AbortController();
  dispatch({ type: UPDATE_STATE, payload: { isLoadingDraftPrice: true } });
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/pricing/draft/price`;
  convertDistanceToString(data);
  const response = await fetchWrapper(url, {
    method: "POST",
    headers: { authorization: authorization() },
    body: JSON.stringify(data),
    signal: loadDraftPriceController.signal,
  });
  dispatch({ type: UPDATE_STATE, payload: { isLoadingDraftPrice: false } });

  let draftPrice = defaultPrice;
  if (!response.ok) {
    draftPrice.error = "errorLoadingPrice";
  } else {
    let rsp = await response.json();
    if (rsp.success) {
      draftPrice = rsp.response;
    } else {
      draftPrice.error = rsp.error.message;
    }
  }

  dispatch({ type: UPDATE_STATE, payload: { draftPrice } });
}, 1000);

export const loadDraftAttributes = async (dispatch, id) => {
  dispatch({ type: UPDATE_STATE, payload: { isLoadingDraftAttributes: true } });
  const url = `${process.env.REACT_APP_API_URL}/client/${id}/pricing/draft/attributes`;
  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  });
  dispatch({
    type: UPDATE_STATE,
    payload: { isLoadingDraftAttributes: false },
  });
  if (!response.ok) {
    throw await response.json();
  }
  const { response: attr } = await response.json();
  const draftAttributes = attr || [];
  dispatch({ type: UPDATE_STATE, payload: { draftAttributes } });
};

function convertDistanceToString(data) {
  if (data.hasOwnProperty("distance") && typeof data.distance === "number") {
    data.distance = `${data.distance}`;
  }
}

export const getB2CPricingConfig = async (stage) => {
  stage = stage !== "" ? stage : "draft";
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/config/${stage}`;

  const response = await fetchWrapper(url, {
    headers: { authorization: authorization() },
  });

  if (!response) {
    throw new Error("Error getting b2c pricing config");
  }

  if (!response.ok) {
    throw await response.json();
  }

  const {
    response: {Config:{ Objects, PAConditions },Stage},
  } = await response.json();

  return { Objects, PAConditions,Stage };
};

export const loadB2CPrice = async (stage, data) => {
  stage = stage !== "" ? stage : "draft";
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/price/${stage}`;
  const response = await fetchWrapper(url, {
    method: "POST",
    headers: { authorization: authorization() },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw await response.json();
  }

  const {
    response: { price, total_distance, deliveries },
  } = await response.json();

  return { price, total_distance, deliveries };
};

export const downloadB2CConfig = async (stage) => {
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/config/${stage}/download`;
  await fetchWrapper(url, {
    method: "GET",
    headers: { authorization: authorization() },
    responseType: "blob",
  })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, `b2c_pricing_config_${Date.now()}.xlsx`))
    .catch((e) => {
      throw e;
    });
  return;
};

export const uploadB2CConfig = async (stage, data) => {
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/config/${stage}`;
  const response = await fetchWrapper(url, {
    method: "POST",
    headers: { authorization: authorization() },
    body: data,
  });

  if (!response.ok) {
    throw await response.json();
  }

  let jsonResp = await response.json()

  if(!jsonResp.success){
     const error = ({code:422,response:jsonResp.response})
     throw error
  }

  return await jsonResp
};

export const applyB2cConfig = async () => {
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/config`;
  const response = await fetchWrapper(url, {
    method: "PATCH",
    headers: { authorization: authorization() },
  });

  
  if (!response.ok) {
    throw await response.json();
  }

  return await response.json();
};
export const eraseB2CDraftConfig = async () => {
  const url = `${process.env.REACT_APP_API_URL}/b2cpricing/config/draft`;
  const response = await fetchWrapper(url, {
    method: "DELETE",
    headers: { authorization: authorization() },
  });

  
  if (!response.ok) {
    throw await response.json();
  }

  return await response.json();
};
