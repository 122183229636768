import React, {useRef, useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton';
import { loadPrice, loadAttributes, loadDraftPrice, loadDraftAttributes } from "../../utilities/prices.js";
import { GlobalStateContext } from '../../state';

import './PricePreview.css';

const typeFunctions = {
  deployed: {priceLoader: loadPrice, attributesLoader: loadAttributes},
  draft: {priceLoader: loadDraftPrice, attributesLoader: loadDraftAttributes},
}

const typeProps = {
  deployed: {
    price: "price",
    attributes: "attributes",
    loadingPrice: "isLoadingPrice",
    loadingAttributes: "isLoadingAttributes",
  },
  draft: {
    price: "draftPrice",
    attributes: "draftAttributes",
    loadingPrice: "isLoadingDraftPrice",
    loadingAttributes: "isLoadingDraftAttributes",
  }
};

const formatPrice = price => `€${parseFloat(price.price_tax_excluded).toFixed(2)} HT (€${parseFloat(price.price_tax_included).toFixed(2)} TTC)`;

const PricePreview = (props) =>{

  const { t } = useTranslation();

  const [state, { dispatch }] = useContext(GlobalStateContext);
  const [pricingData, setPricingData] = useState({});
  const type = useRef(props.type);
  const priceProp = useRef(typeProps[type.current].price);
  const attributesProp = useRef(typeProps[type.current].attributes);
  const loadingPriceProp = useRef(typeProps[type.current].loadingPrice);
  const loadingAttributesProp = useRef(typeProps[type.current].loadingAttributes);
  const price = state[priceProp.current];
  const priceText = price.error ? t(price.error) : formatPrice(price);
  const attributes = state[attributesProp.current];
  const isLoadingPrice = state[loadingPriceProp.current];
  const isLoadingAttributes = state[loadingAttributesProp.current];
  
  const updatePricingData = field => e => {
    setPricingData({...pricingData, [field]: e.target.value});
  }

  const attributeField = {
    input: a => <Form.Control value={pricingData[a.name] || 0} onChange={updatePricingData(a.name)}/>,
    select: a => <Form.Control as="select" value={pricingData[a.name]} onChange={updatePricingData(a.name)}>
      {a.options.map(option => <option key={option}>{option}</option>)}
    </Form.Control>
  }

  useEffect(() => {
    const loader = typeFunctions[type.current].attributesLoader;
    loader(dispatch, props.clientId)
  }, [dispatch, props.clientId, type])

  const submittedData = useCallback(() => {
    const reduceAttrs = (obj, attr) => ({
      ...obj,
      [attr.name]: pricingData[attr.name] || (attr.options ? attr.options[0] : "0")
    })
    return attributes.reduce(reduceAttrs, {});
  }, [attributes, pricingData])

  useEffect(() => {
    const loader = typeFunctions[type.current].priceLoader;
    loader(dispatch, props.clientId, submittedData())
  }, [dispatch, props.clientId, submittedData, type])

  return(
    <div>
      <Row>
        <h4>{t(props.title)}</h4>
      </Row>
      {isLoadingAttributes && <Row>
        <Col><Skeleton count={2}/></Col>
      </Row>}
      {!isLoadingAttributes && attributes.map(attribute => (
        <div key={attribute.name}>
          <Row className="pricepreview-attributerow">
            <Col xs={4}>{attribute.name}:</Col>
            <Col>{attributeField[attribute.type](attribute)} </Col>
          </Row>
        </div>
      ))}
      <br/>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <h5>
          {isLoadingPrice && <FontAwesomeIcon icon={faSpinner} pulse/>}
          {!isLoadingPrice && <span className="pricepreview-price"> {priceText} </span> }
          </h5>
        </Col>
      </Row>
    </div>
  )
};

PricePreview.propTypes = {
  title: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default PricePreview;
