import { fetchWrapper } from "./utils";
import { authorization } from "./auth.js";
import { UPDATE_STATE } from "../state/constants";

export const loadPromoList = async (page, per_page, dispatch) => {
  try {
    const params = new URLSearchParams({
      page,
      per_page,
    });
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/promocode?${params.toString()}`,
      {
        headers: { authorization: authorization() },
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
    const { response: promoList } = jsonResponse;
    const total = response.headers.get("X-Total");
    dispatch({
      type: UPDATE_STATE,
      payload: {
        promo: {
          total: isNaN(total) ? promoList.length : total,
          promoList: promoList,
          pages: await range(1, Math.ceil(total / per_page)),
        },
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_STATE,
      payload: { promo: { total: 0, promoList: [], pages: [] } },
    });
  }
};

function range(start, end) {
  if (!end) return [1];
  return Array.apply(0, Array(end)).map((element, index) => index + start);
}

export const getPromoById = (promoList, id) => {
  let promo = promoList.find((el, index) => {
    if (el.ID === id) {
      return el;
    }
    return null;
  });
  return promo;
};

export const removeAddApplication = (app, key, checked) => {
  let application = app ?? [];
  if (checked) {
    if (!application.includes(key)) {
      application.push(key);
    }
  } else {
    if (application.includes(key)) {
      application = application.filter((el) => el !== key);
    }
  }
  return application;
};

export const editPromoCode = async (id, data, dispatch) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/promocode/${id.toString()}`,
      {
        headers: { authorization: authorization() },
        method: "PUT",
        body: JSON.stringify(data),
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
  } catch (e) {
    throw e;
  }
};

export const createPromoCode = async (data, dispatch) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/promocode`,
      {
        headers: { authorization: authorization() },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
  } catch (e) {
    throw e;
  }
};

export const getFormattedTime = (time) => {
  if (time === "0001-01-01T00:00:00Z") {
    return "";
  }
  const dateTimeInParts = new Date(time).toISOString().split("T");
  return dateTimeInParts[0]
};
export const getPromoCodeByCode = async (promocode, dispatch) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/promocode/code/${promocode.toString()}`,
      {
        headers: { authorization: authorization() },
        method: "GET",
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
    const { response: promodoc } = jsonResponse;
    dispatch({
      type: UPDATE_STATE,
      payload: { promo: { total: 1, promoList: [promodoc], pages: [1] } },
    });
  } catch (e) {
    throw e;
  }
};

export const archivePromoCode = async (id, dispatch) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/promocode/${id.toString()}/delete`,
      {
        headers: { authorization: authorization() },
        method: "PATCH",
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
  } catch (e) {
    throw e;
  }
};
