import React, {useState, useEffect} from 'react';
import { Container, Col, Row, Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomTable from "../CustomTable";
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { loadUsers, createUser, updateUser, deleteUser } from "../../utilities/users.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast, Slide } from 'react-toastify';
import './ClientUsers.css';
import { toastOptions } from '../../state/constants';

const ClientUsers = (props) => {

  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({});
  const [deleting, setDeleting] = useState({});
  const [updating, setUpdating] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadUsers(props.clientId).then(setUsers)
    .finally(() => {setIsLoading(false)});
  }, [props.clientId])

  const confirmRemove = user => {
    setDeleting(user)
  }

  const cancelRemove = () => {
    setDeleting({})
  }

  const removeUser = async () => {
    try {
      setIsDeleting(true);
      await deleteUser(deleting.ID)
      setUsers(users.filter(u => u.ID !== deleting.ID))
      setDeleting({});
    } catch(e) {
      toast.error(e.error.message, toastOptions);
    } finally {
      setIsDeleting(false);
    }
  }

  const startUpdate = (user) => {
    setUpdating({name: user.Name, ID: user.ID});
  }

  const cancelUpdate = () => {
    setUpdating({});
  }

  const updateUpdating = (field, value) => {
    setUpdating({...updating, [field]: value})
  }

  const updateForm = (field, value) => {
    setForm({...form, [field]: value})
  }

  const submitUpdate = async () => {
    try {
      setIsUpdating(true);
      await updateUser(updating)
      setUsers(users.map(u => u.ID === updating.ID ? {...u, Name: updating.name} : u))
      cancelUpdate();
    } catch(e) {
      toast.error(e.error.message, toastOptions);
    } finally {
      setIsUpdating(false);
    }
  }

  const cancelForm = () => {
    setForm({});
    setShowForm(false);
  }

  const submitForm = async () => {
    try {
      setIsSubmitting(true);
      const createdUser = await createUser({...form, clientaccountid: props.clientId})
      setUsers([...users, createdUser]);
      cancelForm();
    } catch(e) {
      toast.error(e.error.message, toastOptions);
    } finally {
      setIsSubmitting(false);
    }
  }
  
  if(isLoading) return <Skeleton count={6}/>

  return (
    <Container>
      <br/>
      <Row><Col><h4>{t("users")}</h4></Col></Row>
      <Row>
        <Col>
          <CustomTable>
            <thead>
              <tr>
                <th>{t("name")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
            {users.length === 0 && <tr><td colSpan="2">{t("noUser")}</td></tr>}
            {users.length > 0 && users.map(user =>(
              <tr key={user.ID}>
                <td>{user.Name}</td>
                <td>
                  <FontAwesomeIcon icon={faTimes} className="clientusers-actionicon" onClick={() => {confirmRemove(user)}}/>
                  &nbsp;
                  &nbsp;
                  <FontAwesomeIcon icon={faPen} className="clientusers-actionicon" onClick={() => {startUpdate(user)}}/>
                </td>
              </tr>
            ))} 
            </tbody>
          </CustomTable>
        </Col>
      </Row>
      <br/>
      { !showForm && <Row>
        <Col xs={3}>
          <Button variant="info" onClick={() => {setShowForm(true)}}>
            { t("createUser") }
          </Button>
        </Col>
      </Row> }
      {showForm && <Form>
        <Form.Row>
          <Col>
            <Form.Group controlId="formName">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("name")}
                value={form.name}
                onChange={e => updateForm("name", e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPassword">
              <Form.Label>{t("password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("password")}
                value={form.password}
                onChange={e => updateForm("password", e.target.value)}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col></Col>
          <Col xs="auto">
            <Button variant="light" onClick={cancelForm}>
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} />}
              {!isSubmitting && t("cancel")}
            </Button>
            &nbsp;
            <Button variant="success" onClick={submitForm}>
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} />}
              {!isSubmitting && t("confirm")}
            </Button>
          </Col>
        </Form.Row>
      </Form> }
      <ToastContainer transition={Slide} />
      <Modal show={deleting.ID !== undefined} onHide={cancelRemove}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <p>{t("confirmRemoveUser", deleting)}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancelRemove}>
            {isDeleting && <FontAwesomeIcon icon={faSpinner} />}
            {!isDeleting && t("no")}
          </Button>
          <Button variant="info" onClick={removeUser}>
            {isDeleting && <FontAwesomeIcon icon={faSpinner} />}
            {!isDeleting && t("yes")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={updating.ID !== undefined} onHide={cancelUpdate}>
        <Modal.Header closeButton>
          {t("updatingUser")}
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Form.Row>
              <Col>
                <Form.Group controlId="updateName">
                  <Form.Label>{t("name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("name")}
                    value={updating.name}
                    onChange={e => updateUpdating("name", e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group controlId="updatePassword">
                  <Form.Label>{t("password")}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t("passwordUpdatePlaceholder")}
                    onChange={e => updateUpdating("password", e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancelUpdate}>
            {isUpdating && <FontAwesomeIcon icon={faSpinner} />}
            {!isUpdating && t("no")}
          </Button>
          <Button variant="info" onClick={submitUpdate}>
            {isUpdating && <FontAwesomeIcon icon={faSpinner} />}
            {!isUpdating && t("yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
ClientUsers.propTypes = {
  clientId: PropTypes.string.isRequired
};

ClientUsers.defaultProps = {};

export default ClientUsers;
