import React, { useRef, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import AddressListItem from "../AddressListItem";
import { GlobalStateContext } from "../../state";

import "./AddressInput.css";

const AddressInput = (props) => {
  const { t } = useTranslation();
  const [, { googleLoaded }] = useContext(GlobalStateContext);
  const [autocomplete, setAutocomplete] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);
  const [stagedValue, setStagedValue] = useState({ ...props.value });
  const [selectedValue, setSelectedValue] = useState({ ...props.value });
  const blurFromSelect = useRef(false);
  const service = useRef(null);
  const sessionToken = useRef(null);
  const mounted = useRef(true);

  const onFocus = () => {
    setShowPredictions(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      if (mounted.current) {
        setShowPredictions(false);
        if (!blurFromSelect.current) {
          if (stagedValue.address === "") {
            setSelectedValue(stagedValue);
            props.onChange(stagedValue);
          } else {
            setStagedValue(selectedValue);
          }
        }
        blurFromSelect.current = false;
      }
    }, 200);
  };

  const onSelect = (value) => {
    blurFromSelect.current = true;

    setStagedValue(value);
    setSelectedValue(value);
    setShowPredictions(false);
    props.onChange(value);
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
  };

  const displaySuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK)
      return setAutocomplete([]);
    const formattedPreds = predictions.map((p) => ({
      type: "prediction",
      title: p.structured_formatting.main_text,
      subtitle: p.structured_formatting.secondary_text,
      value: { address: p.description, contact: {}, placeId: p.place_id },
    }));
    setAutocomplete(formattedPreds);
  };

  useEffect(() => {}, [stagedValue]);

  const displayed = autocomplete;

  const handleChange = (input) => {
    const { placeId, ...pureStagedValue } = stagedValue;

    setStagedValue({ ...pureStagedValue, address: input });
    if (!input.length) {
      setAutocomplete([]);
      return;
    }
    const options = {
      input,
      sessionToken: sessionToken.current,
      componentRestrictions: { country: "fr" },
    };
    service.current.getPlacePredictions(options, displaySuggestions);
  };

  useEffect(() => {
    if (!window.google) return;
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
    service.current = new window.google.maps.places.AutocompleteService();
  }, [googleLoaded]);

  useEffect(() => {
    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setStagedValue(props.value);
    setSelectedValue(props.value);
  }, [props.value]);

  return (
    <div>
      <Form.Group className="addressinput-group">
        <InputGroup>
          {props.prependIcon && (
            <InputGroup.Prepend
              className={`customInputGroup ${
                props.disabled ? "disabledInputGroup" : ""
              }`}
            >
              <FontAwesomeIcon icon={faPaperPlane}> </FontAwesomeIcon>
            </InputGroup.Prepend>
          )}
          <Form.Control
            id={`${props.id}-input`}
            required
            autoComplete="off"
            className="addressinput-field"
            value={stagedValue.address}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={props.disabled}
            placeholder={t("searchAddress")}
          />
        </InputGroup>
        {showPredictions && (
          <div className="addressinput-predictions">
            {displayed.map((address, index) => (
              <AddressListItem
                key={index}
                title={address.title}
                subtitle={address.subtitle}
                type={address.type}
                onClick={() => {
                  onSelect(address.value);
                }}
              ></AddressListItem>
            ))}
          </div>
        )}
        {props.error && props.error !== "" && (
          <Form.Text className="address-invalidmsg">{t(props.error)}</Form.Text>
        )}
      </Form.Group>
    </div>
  );
};

AddressInput.defaultProps = {};

export default AddressInput;
