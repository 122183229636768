import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  Form,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  openZonesByCountry,
  workTimes,
  daysOfWeek,
  toastOptions
} from "../../state/constants";

import {
  getSlotsConfigs,
  getSlotsConfig,
  createSlotsConfig,
  deleteSlotsConfig,
} from "../../utilities/requests";

import { ToastContainer, toast, Slide } from 'react-toastify';

import "./WorkingHoursConfig.css";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const ClientType = {
  b2c: "Particulier",
  instore: "Magasin",
  b2b: "B2B",
};
const SlotType = {
  Standard: "Standard",
  Custom: "Custom",
};
const defaultZoneConfig = {
  Day: "Monday",
  StartTime: "00:00",
  EndTime: "00:00",
  Zone: undefined,
  CuttOff: "00:00",
  ServiceTime: "02:00",
  Activated: false,
  timeZone: timezone,
  Slots: [{ StartTime: "00:00", EndTime: "00:00" }],
  SlotType: SlotType.Standard,
  Channel: "b2c",
};

const WorkingHoursConfig = () => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState("none");
  const [selectedZone, setSelectedZone] = useState("none");
  const [zonesWithConfig, setZonesWithConfig] = useState([]);
  const [zoneConfig, setZoneConfig] = useState(defaultZoneConfig);
  const [stagedZoneConfig, setStagedZoneConfig] = useState(defaultZoneConfig);
  const [isCustomTimeChanged, setIsCustomTimeChanged] = useState(false);
  useEffect(() => {
    selectDefaultConfig();
    getSlotsConfigs().then((res) => {
      const foundZones = [];
      res
        .filter((el) => !!el.Zone)
        .forEach((config) => {
          if (!foundZones.includes(config.Zone)) foundZones.push(config.Zone);
        });
      setZonesWithConfig(foundZones);
    });
  }, []);
  const addZone = (e) => {
    setSelectedCountry("none");
    setSelectedZone("none");
    setIsCustomTimeChanged(false);

    if (!zonesWithConfig.includes(e.target.value)) {
      setZonesWithConfig([...zonesWithConfig, e.target.value]);
      setZoneConfig({ ...defaultZoneConfig, Zone: e.target.value });
      setStagedZoneConfig({ ...defaultZoneConfig, Zone: e.target.value });
      createSlotsConfig({ ...defaultZoneConfig, Zone: e.target.value });
    } else {
      getSlotsConfig({ Day: "Monday", Zone: e.target.value }).then((res) => {
        setZoneConfig(res);
        setStagedZoneConfig(res);
      });
    }
  };

  const selectDefaultConfig = () => {
    setIsCustomTimeChanged(false);

    getSlotsConfig({
      Day: stagedZoneConfig.Day,
      Channel: stagedZoneConfig.Channel,
      SlotType: stagedZoneConfig.SlotType,
    })
      .then((res) => {
        setZoneConfig(res);
        setStagedZoneConfig(res);
      })
      .catch((e) => {
        setZoneConfig({
          ...defaultZoneConfig,
          Zone: undefined,
          Day: stagedZoneConfig.Day,
        });
        setStagedZoneConfig({
          ...defaultZoneConfig,
          Zone: undefined,
          Day: stagedZoneConfig.Day,
        });
      });
  };

  const selectConfigByZone = (Zone) => {
    setIsCustomTimeChanged(false);

    getSlotsConfig({
      Day: stagedZoneConfig.Day,
      Zone,
      Channel: stagedZoneConfig.Channel,
      SlotType: stagedZoneConfig.SlotType,
    })
      .then((res) => {
        setZoneConfig(res);
        setStagedZoneConfig(res);
      })
      .catch((e) => {
        setZoneConfig({
          ...defaultZoneConfig,
          Zone,
          Day: stagedZoneConfig.Day,
        });
        setStagedZoneConfig({
          ...defaultZoneConfig,
          Zone,
          Day: stagedZoneConfig.Day,
        });
      });
  };

  const selectDay = (Day) => {
    setIsCustomTimeChanged(false);

    getSlotsConfig({
      Day,
      Zone: stagedZoneConfig.Zone,
      Channel: stagedZoneConfig.Channel,
      SlotType: stagedZoneConfig.SlotType,
    })
      .then((res) => {
        setZoneConfig(res);
        setStagedZoneConfig(res);
      })
      .catch((e) => {
        setZoneConfig({
          ...defaultZoneConfig,
          Day,
          Zone: stagedZoneConfig.Zone,
        });
        setStagedZoneConfig({
          ...defaultZoneConfig,
          Day,
          Zone: stagedZoneConfig.Zone,
        });
      });
  };

  const saveConfig = () => {
    let configToSave = stagedZoneConfig;
    createSlotsConfig(configToSave).then((res) => {
      toast.success("Configuration enregistée",toastOptions)
      setIsCustomTimeChanged(false);
      setZoneConfig(configToSave);
    }).catch(err=>{
      if (err?.error?.code === 400)
      toast.error(err.error.message,toastOptions );
    });
  };

  const deleteConfig = (zone) => {
    deleteSlotsConfig(zone).then((config) => {
      setZonesWithConfig(zonesWithConfig.filter((el) => el !== zone));
      if (zone === zoneConfig.Zone) selectConfigByZone(undefined);
    });
  };

  const resetZoneConfig = () => {
    selectConfigByZone(stagedZoneConfig.Zone);
    setStagedZoneConfig(zoneConfig);
  };

  const onCustomSlotsChange = (key, value, index) => {
    setIsCustomTimeChanged(true);
    let newArr = stagedZoneConfig.Slots
      ? [...stagedZoneConfig.Slots]
      : [...defaultZoneConfig.Slots];
    newArr[index][key] = value;
    setStagedZoneConfig({ ...stagedZoneConfig, Slots: newArr });
  };

  const handleVisibilityOfCuttOffChange = (event) => {
    const inputTime = event.target.value;
    setStagedZoneConfig({
      ...stagedZoneConfig,
      CuttOff: inputTime,
    });
  };
  const handleVisibilityOfCuttOffBlur = () => {
    // Check the input format after the user has finished typing
    const regex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

    if (!regex.test(stagedZoneConfig.CuttOff)) {
      // Reset the input if it doesn't match the '00:00' format
      setStagedZoneConfig({
        ...stagedZoneConfig,
        CuttOff: "00:00",
      });
    }
  };

  useEffect(() => {
    if (stagedZoneConfig.Channel !== "instore") {
      setStagedZoneConfig({
        ...stagedZoneConfig,
        SlotType: SlotType.Standard,
        Slots: null,
      });
    }
  }, [stagedZoneConfig.Channel]);
  const handleCustomSlotAdd = () => {
    setStagedZoneConfig({
      ...stagedZoneConfig,
      Slots: [
        ...(stagedZoneConfig?.Slots ?? defaultZoneConfig.Slots),
        { StartTime: "00:00", EndTime: "00:00" },
      ],
    });
  };
  const handleCustomSlotRemove = (index) => {
    const list = [...stagedZoneConfig.Slots];
    list.splice(index, 1);
    setStagedZoneConfig({
      ...stagedZoneConfig,
      Slots: list,
    });
  };

  const onSlotTypeChange = (e) => {
    let selectedSlotType = e.target.value;
    let ch = "b2c";
    setIsCustomTimeChanged(false);
    if (selectedSlotType === SlotType.Custom) {
      ch = "instore";
      setStagedZoneConfig({
        ...stagedZoneConfig,
        Channel: "instore",
        SlotType: SlotType[selectedSlotType],
        Slots: null,
      });
    } else {
      setStagedZoneConfig({
        ...stagedZoneConfig,
        Channel: "b2c",
        SlotType: SlotType[selectedSlotType],
        Slots: null,
      });
    }

    getSlotsConfig({
      Day: stagedZoneConfig.Day,
      Zone: stagedZoneConfig.Zone,
      Channel: ch,
      SlotType: ch === "instore" ? selectedSlotType : SlotType.Standard,
    })
      .then((res) => {
        res.Channel = ch;
        res.SlotType = selectedSlotType;

        setZoneConfig(res);
        setStagedZoneConfig(res);
      })
      .catch((e) => {
        setZoneConfig({
          ...defaultZoneConfig,
          Day: stagedZoneConfig.Day,
          Zone: stagedZoneConfig.Zone,
          Channel: ch,
          SlotType: selectedSlotType,
          Slots: null,
        });
        setStagedZoneConfig({
          ...defaultZoneConfig,
          Day: stagedZoneConfig.Day,
          Zone: stagedZoneConfig.Zone,
          Channel: ch,
          SlotType: selectedSlotType,
          Slots: null,
        });
      });
  };
  return (
    <Container>
      <br />
      <Row>
        <Col>
          <Card>
            <Container>
              <br />
              <Row className="mb-3">
                <Col xs={4}>
                  <ListGroup
                    activeKey={`#${
                      stagedZoneConfig.Zone ? stagedZoneConfig.Zone : "none"
                    }`}
                  >
                    <ListGroup.Item
                      action
                      href="#none"
                      onClick={selectDefaultConfig}
                    >
                      {t("defaultWorkingHours")}
                    </ListGroup.Item>
                    <br />
                    <span>{t("addSpecificHoursPerZone")}</span>
                    <div className="d-flex flex-wrap justify-content-between">
                      <Form.Group>
                        <Form.Label>{t("country")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedCountry}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                        >
                          <option key={0} value="none" disabled>
                            Choisissez un pays
                          </option>
                          {Object.keys(openZonesByCountry)?.map(
                            (country, index) => (
                              <option key={index + 1} value={country}>
                                {country}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t("zone")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedZone}
                          onChange={addZone}
                        >
                          <option key={0} value="none" disabled>
                            Choisissez une zone
                          </option>
                          {openZonesByCountry[selectedCountry]?.map(
                            (zone, index) => (
                              <option key={index} value={zone}>
                                {zone}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <br />
                    {Object.keys(openZonesByCountry)?.map((country, index) => (
                      <>
                        <span>{country}</span>
                        {openZonesByCountry[country]?.map(
                          (zone, index) =>
                            zonesWithConfig?.includes(zone) && (
                              <ListGroup.Item
                                action
                                key={index}
                                href={`#${zone}`}
                                onClick={() => selectConfigByZone(zone)}
                              >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                  <span>{zone}</span>
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteConfig(zone);
                                    }}
                                  />
                                </div>
                              </ListGroup.Item>
                            )
                        )}
                      </>
                    ))}
                  </ListGroup>
                </Col>
                <Col xs="8">
                  <h3>{t("workingDaysSelection")}</h3>
                  <br />
                  <ListGroup
                    horizontal
                    className="flex-wrap"
                    activeKey={`#${stagedZoneConfig.Day}`}
                  >
                    {daysOfWeek.map((Day) => (
                      <ListGroup.Item
                        className="dayItem"
                        href={`#${Day}`}
                        onClick={() => selectDay(Day)}
                      >
                        {t(Day)}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <br />
                  <Form.Switch
                    id="active-switch"
                    label={
                      stagedZoneConfig.Activated
                        ? "Jour ouvré"
                        : "Jour non ouvré"
                    }
                    checked={stagedZoneConfig.Activated}
                    onChange={() =>
                      setStagedZoneConfig({
                        ...stagedZoneConfig,
                        Activated: !stagedZoneConfig.Activated,
                      })
                    }
                  />
                  <br />
                  <h3>{t("workingHoursConfig")}</h3>
                  <br />

                  <Row>
                    {
                      <Col xs={6} md={3} className="mb-2">
                        <Form.Control
                          as="select"
                          value={stagedZoneConfig.SlotType ?? SlotType.Standard}
                          onChange={onSlotTypeChange}
                        >
                          {Object.entries(SlotType).map(
                            ([key, value], index) => (
                              <option key={index} value={key}>
                                {value}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    }
                    {stagedZoneConfig.SlotType === SlotType.Custom && (
                      <Col xs={6} md={3} className="mb-2">
                        <Form.Control
                          disabled
                          as="select"
                          value={stagedZoneConfig.Channel ?? ClientType.b2c}
                          // onChange={onChannelChange}
                        >
                          {Object.entries(ClientType)?.map(
                            ([key, value], index) => (
                              <option key={index} value={key}>
                                {value}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    )}
                  </Row>
                  {stagedZoneConfig.SlotType === SlotType.Custom ? (
                    <Row className="align-items-center">
                      <Row style={{ marginLeft: "3%" }}>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Cut-off de visibilité:&nbsp;</span>
                          <Form.Control
                            type="text"
                            disabled={!stagedZoneConfig.Activated}
                            value={stagedZoneConfig.CuttOff}
                            onChange={(e) => handleVisibilityOfCuttOffChange(e)}
                            onBlur={(e) => handleVisibilityOfCuttOffBlur(e)}
                            pattern="[0-9]{2}:[0-9]{2}"
                            placeholder="00:00"
                          />
                        </Col>
                      </Row>
                      <Row>
                      <br/>
                      <Row style={{marginLeft:"5%"}}>
                        <Col className="mb-2">
                        <h6>{t("workingSlotsConfig")} : </h6>
                        </Col>
                    
                      </Row>
                      
                        <br />
                        <Row>
                          <Col>
                            {" "}
                            {(
                              stagedZoneConfig.Slots ?? defaultZoneConfig.Slots
                            ).map((slot, index) => {
                              return (
                                <Row
                                  className="align-items-center"
                                  style={{ marginLeft: "5%" }}
                                >
                                  <Col xs={9}>
                                    <Row>
                                      <Col xs={6} md={3} className="mb-2">
                                        <span>A partir de :&nbsp;</span>
                                        <Form.Control
                                          as="select"
                                          disabled={!stagedZoneConfig.Activated}
                                          value={slot.StartTime}
                                          onChange={(e) =>
                                            onCustomSlotsChange(
                                              "StartTime",
                                              e.target.value,
                                              index
                                            )
                                          }
                                        >
                                          {workTimes.map((time, index) => (
                                            <option key={index} value={time}>
                                              {time}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </Col>
                                      <Col xs={6} md={3} className="mb-2">
                                        <span>Jusqu'à :&nbsp;</span>
                                        <Form.Control
                                          as="select"
                                          disabled={!stagedZoneConfig.Activated}
                                          value={slot.EndTime}
                                          onChange={(e) =>
                                            onCustomSlotsChange(
                                              "EndTime",
                                              e.target.value,
                                              index
                                            )
                                          }
                                        >
                                          {workTimes.map((time, index) => (
                                            <option key={index} value={time}>
                                              {time}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </Col>
                                      <Col>
                                        <Row style={{ paddingTop: "15%" }}>
                                          {stagedZoneConfig.Slots &&
                                            stagedZoneConfig.Slots?.length !==
                                              1 && (
                                              <span
                                                variant="primary"
                                                onClick={() =>
                                                  handleCustomSlotRemove(index)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  style={{ color: "red" }}
                                                />
                                              </span>
                                            )}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ marginLeft: "6%" }}>
                            <Button
                              variant="outlined-white"
                              color="dashed"
                              onClick={handleCustomSlotAdd}
                              disabled={!stagedZoneConfig.Activated}
                            >
                              <FontAwesomeIcon
                                className="prepend-svg object-controls-plus"
                                icon={faPlus}
                              />
                              <span>Ajouter un nouveau créneau</span>
                            </Button>
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  ) : (
                    <>
                      <Row className="align-items-center">
                        <Col xs={6} md={3} className="mb-2">
                          {" "}
                          <span>A partir de :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedZoneConfig.Activated}
                            value={stagedZoneConfig.StartTime}
                            onChange={(e) =>
                              setStagedZoneConfig({
                                ...stagedZoneConfig,
                                StartTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Jusqu'à :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedZoneConfig.Activated}
                            value={stagedZoneConfig.EndTime}
                            onChange={(e) =>
                              setStagedZoneConfig({
                                ...stagedZoneConfig,
                                EndTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Cut-off :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedZoneConfig.Activated}
                            value={stagedZoneConfig.CuttOff}
                            onChange={(e) =>
                              setStagedZoneConfig({
                                ...stagedZoneConfig,
                                CuttOff: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Taille de créneau :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedZoneConfig.Activated}
                            value={stagedZoneConfig.ServiceTime}
                            onChange={(e) =>
                              setStagedZoneConfig({
                                ...stagedZoneConfig,
                                ServiceTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </>
                  )}
                  <br />
                  <br />
                  <div className="d-flex flex-wrap justify-content-end">
                    <Button
                      variant="outline-secondary"
                      disabled={
                        JSON.stringify(stagedZoneConfig) ===
                          JSON.stringify(zoneConfig) && !isCustomTimeChanged
                      }
                      onClick={resetZoneConfig}
                    >
                      Annuler
                    </Button>
                    <Button
                      className="ml-3"
                      variant="info"
                      onClick={saveConfig}
                      disabled={
                        JSON.stringify(stagedZoneConfig) ===
                          JSON.stringify(zoneConfig) && !isCustomTimeChanged
                      }
                    >
                      Enregistrer
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card>
          <br />
        </Col>
      </Row>
      <ToastContainer transition={Slide} />
    </Container>
  );
};

export default WorkingHoursConfig;
