import { authorization, logout } from './auth';

export const fetchWrapper = async (resource, init) =>  {
	let response = await fetch(resource, init);
    if (!response.ok && response.status === 401) {
	    response = await refreshtoken();
	    if (response.ok) {
	    	const { response: user } = await response.json();
			localStorage.setItem("token", user.jwt);
			init.headers.authorization = authorization();
	     	response = await fetch(resource, init);
	    } else if(!response.ok && response.status === 401) {
			logout();
		}
    }
    return response;
  }

export const refreshtoken = async () => {
	let response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
		method: "POST",
		credentials: "include",
		body: JSON.stringify({}),
		headers: { authorization: authorization() },
	});

	return response;
}
