import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Col, Form, Row, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import PageSpinner from "../PageSpinner/PageSpinner";
import { ToastContainer, toast, Slide } from "react-toastify";
import { toastOptions } from "../../state/constants";
import "./PromotionalCodeForm.css";
import { Fragment } from "react";
import { GlobalStateContext } from "../../state";
import {
  getPromoById,
  removeAddApplication,
  editPromoCode,
  createPromoCode,
  getFormattedTime,
} from "../../utilities/promocode";

const PromotionalCodeForm = () => {
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const [state, { dispatch }] = useContext(GlobalStateContext);

  const [promoState, setPromo] = useState({ Application: [] });
  const [editPromo, setEditPromo] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const getPromo = () => {
    if (id) {
      if (!state.promo.promoList.length) {
        history.push(`/promotionalCodes`);
      }
      let pr = getPromoById(state.promo.promoList, id);
      setPromo(pr);
    }
  };

  useEffect(() => getPromo());

  useEffect(() => () => (isMounted.current = false), []);

  const updatePromo = (update) => {
    setEditPromo({ ...Object.assign(editPromo, update) });
    setPromo({ ...Object.assign(promoState, update) });
  };

  const editPromoC = async () => {
    await editPromoCode(id, editPromo, dispatch)
      .then(() => history.push(`/promotionalCodes`))
      .catch((e) => {
        if (e.error) {
          if (e.error.code === 400) {
            toast.error(t(`${e.error.message}`), toastOptions);
          }
        } else {
          toast.error(t(`${e}`), toastOptions);
        }
      });
  };
  const createPromoC = async () => {
    await createPromoCode(promoState, dispatch)
      .then(() => history.push(`/promotionalCodes`))
      .catch((e) => {
        if (e.error) {
          if (e.error.code === 400) {
            toast.error(t(`${e.error.message}`), toastOptions);
          }
        } else {
          toast.error(t(`${e}`), toastOptions);
        }
      });
  };
  const submit = () => {
    try {
      setIsSubmitting(true);
      if (id) {
        editPromoC();
      } else {
        createPromoC();
      }
    } catch (e) {
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitText = id ? "edit" : "create";
  if (id && !promoState?.ID) return <PageSpinner></PageSpinner>;

  return (
    <Fragment>
      <Container>
        <br />
        <Row>
          <Col>
            <Card>
              <Container>
                <br />
                <Row>
                  <Col>
                    <h3> {id ? t("editPromoCode") : t("createPromoCode")} </h3>
                  </Col>
                </Row>
                <br />
                <div className="promo-subsection">
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="code">
                        <Form.Label>{t("code")}</Form.Label>
                        <Form.Control
                          required
                          placeholder={t("code")}
                          value={promoState?.Code ?? ""}
                          isValid={promoState?.Code}
                          onChange={(e) =>
                            updatePromo({ Code: e.target.value })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="promo">
                        <Form.Label>{t("promo")}</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step="0.1"
                          placeholder={t("promo")}
                          value={promoState?.Promo ?? ""}
                          onChange={(e) =>
                            updatePromo({ Promo: parseFloat(e.target.value) })
                          }
                          isValid={promoState?.Promo}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="promo-type">
                        <Form.Label>{t("promoType")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={promoState?.PromoType ?? ""}
                          required
                          isValid={promoState?.PromoType}
                          onChange={(e) =>
                            updatePromo({ PromoType: e.target.value })
                          }
                        >
                          <option value="" disabled defaultValue={""}>
                            {t("selectYourOption")}
                          </option>
                          <option value="amount">{t("amount")} (€)</option>
                          <option value="percentage">
                            {t("percentage")} (%)
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label sm="6">{t("Application")}</Form.Label>
                          <div key={`inline-checkbox`} className="mb-3">
                            <Form.Check
                              label="B2C Particulier"
                              name="application"
                              type={"checkbox"}
                              id={`b2c`}
                              checked={
                                promoState?.Application.includes("b2c") ?? false
                              }
                              onChange={(e) => {
                                updatePromo({
                                  Application: removeAddApplication(
                                    promoState?.Application,
                                    "b2c",
                                    e.target.checked
                                  ),
                                });
                              }}
                            />
                            <Form.Check
                              label="Magasin"
                              name="application"
                              type={"checkbox"}
                              checked={
                                promoState?.Application.includes("instore") ??
                                false
                              }
                              id={`instore`}
                              onChange={(e) => {
                                updatePromo({
                                  Application: removeAddApplication(
                                    promoState?.Application,
                                    "instore",
                                    e.target.checked
                                  ),
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Form.Group>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="date">
                      <Form.Group controlId="promo-startTime">
                        <Form.Label>{t("startTime")}</Form.Label>
                        <Form.Control
                          required
                          placeholder={t("startTime")}
                          value={
                            promoState?.StartTime
                              ? getFormattedTime(promoState.StartTime)
                              : ""
                          }
                          type="date"
                          style={{ width: "50%" }}
                          onChange={(e) => {
                            updatePromo({
                              StartTime: new Date(e.target.value),
                            });
                          }}
                          isValid={promoState?.StartTime}
                        />
                      </Form.Group>
                      <Form.Group controlId="promo-endTime">
                        <Form.Label>{t("endTime")}</Form.Label>
                        <Form.Control
                          required
                          placeholder={t("endTime")}
                          value={
                            promoState?.EndTime
                              ? getFormattedTime(promoState.EndTime)
                              : ""
                          }
                          type="date"
                          style={{ width: "50%" }}
                          onChange={(e) =>
                            updatePromo({ EndTime: new Date(e.target.value) })
                          }
                          isValid={promoState?.EndTime}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="promo-maxUseNumber">
                        <Form.Label>{t("maxUseNumber")}</Form.Label>
                        <Form.Control
                          required
                          placeholder={t("maxUseNumber")}
                          type={"number"}
                          value={promoState?.MaxUseNum ?? ""}
                          style={{ width: "50%" }}
                          onChange={(e) => {
                            updatePromo({
                              MaxUseNum: parseInt(e.target.value),
                            });
                          }}
                          isValid={promoState?.MaxUseNum}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <br />
                <Row>
                  <Col>
                    <Form.Check
                      type={"checkbox"}
                      id={"activatePromoCode"}
                      label={t("activatePromoCode")}
                      checked={promoState?.Activated ?? false}
                      onChange={(e) => {
                        updatePromo({ Activated: e.target.checked });
                      }}
                    />
                  </Col>
                  <br />
                </Row>

                <Row>
                  <Col></Col>
                  <Col xs="auto">
                    <Button
                      variant="success"
                      onClick={submit}
                      disabled={isSubmitting}
                    >
                      {!isSubmitting && t(submitText)}
                      {isSubmitting && (
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      )}
                    </Button>
                  </Col>
                </Row>
                <br />
              </Container>
            </Card>
            <br />
          </Col>
        </Row>
        <ToastContainer transition={Slide} />
      </Container>
    </Fragment>
  );
};

PromotionalCodeForm.propTypes = {};

PromotionalCodeForm.defaultProps = {};

export default PromotionalCodeForm;
