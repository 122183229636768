import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import UserMenuItem from '../UserMenuItem';
import { logout, } from '../../utilities/auth';
import { GlobalStateContext } from '../../state';

import './UserMenu.css';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="usermenu-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const UserMenu = () => {

  const { t } = useTranslation();

  const [state, { dispatch }] = useContext(GlobalStateContext);
  const history = useHistory();

  const triggerLogout = (e) => {
    logout(dispatch);
    history.push("/login");
  };

  return (<Dropdown>
    <Dropdown.Toggle as={CustomToggle}>
      <div className="usermenu-avatar">
        <FontAwesomeIcon className="usermenu-icon" icon={faUser} size="lg" />
      </div>
      <FontAwesomeIcon icon={faChevronDown} size="xs" />
    </Dropdown.Toggle>
    <Dropdown.Menu alignRight>
      <Dropdown.Header>{state.user.Name}</Dropdown.Header>
      <Dropdown.Divider />
      <UserMenuItem icon={faSignOutAlt} onClick={triggerLogout}>{t("logout")}</UserMenuItem>
    </Dropdown.Menu>
  </Dropdown>)
};

export default UserMenu;
