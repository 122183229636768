import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import './HelpLink.css';

const HelpLink = () => (
  <a className="helplink-a" href="https://help-client.stuart.com/en/" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="helplink-icon" icon={faComment} flip="horizontal" size="lg" />
    <span className="helplink-label">Help Center</span>
  </a>
);

HelpLink.propTypes = {};

HelpLink.defaultProps = {};

export default HelpLink;
