export default {
  translation: {
    clientsList: "Liste des clients",
    createClient: "Créer un nouveau client",
    edit: "Modifier",
    editClient: "Modifier client",
    priceBuilder: "Gestion des prix",
    backToClientPage: "Retourner à la page du client",
    attributes: "Attributs",
    createAttribute: "Créer un nouvel attribut",
    attributeName: "Nom de l'attribut",
    attributeNamePlaceholder: "Ex: poids",
    attributeType: "Type de l'attribut",
    input: "Nombre",
    select: "Options prédéfinies",
    thresholds: "Paliers",
    options: "Options",
    thresholds_suffix: "Jusqu'à la valeur suivante",
    thresholds_label: "À partir de",
    options_label: "Valeur de l'option",
    thresholds_placeholder: "Entrez ici la valeur du palier",
    options_placeholder: "Entrez ici la valeur de l'option",
    createThreshold: "Ajouter un palier",
    createOption: "Ajouter une option",
    confirm: "Confirmer",
    create: "Créer",
    of: "de",
    yes: "oui",
    no: "non",
    cancel: "Annuler",
    rules: "Règles",
    price: "Prix",
    type: "type",
    static: "fixe",
    dynamic: "indexé",
    addRule: "Ajouter une règle de prix",
    conditions: "Conditions d'application",
    settings: "Règlages",
    credit: "Crédit",
    dynamicPropLabel: "indexé à",
    anyValue: "Toutes Valeurs",
    submitPrices: "Déployer les changements",
    priceSaved: "Données enregistrées",
    waitingChanges: "En attente de changements",
    discardChanges: "Annuler les changements",
    actions: "actions",
    logout: "Se déconnecter",
    login: "Connexion",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    loginTitle: "Connectez-vous à Stuart XL",
    confirmRemovePrice: "Etes-vous sûr de vouloir supprimer ce prix ?",
    deployedDemo: "En Production",
    draftDemo: "Preview",
    confirmLeave:
      "Etes-vous sûr de vouloir quitter la page ? votre travail sera supprimé.",
    confirmRemoveAttr:
      "Etes-vous sûr de vouloir supprimer l'attribut {{name}} ?",
    savingWait: "Veuillez patienter pendant la sauvegarde des données",
    name: "Nom",
    deliveriesConfig: "Configuration des livraisons",
    enableVT: "Activer la sélection du véhicule",
    enabledVTList: "Véhicules disponibles",
    selectCustomSlotType: "Paramétrage des horaires ouvrés personalisé",
    walk: "Piéton",
    bike: "Bicyclette",
    motorbike: "Scooter",
    car: "Voiture",
    cargobike: "Cargo Bike",
    van: "Van",
    truck: "Camion",
    deliveriesOptions: "Options des livraisons",
    noOption: "Aucune option de livraison",
    newOption: "Nouvelle Option",
    add: "Ajouter",
    APIConfiguration: "Configuration API",
    APIUsername: "Nom d'utilisateur API",
    APIClientId: "ID Client API",
    APISecret: "Clé secrète API",
    APIOAuthToken: "Token OAuth API",
    WebhookUrl: "Webhook V2 URL",
    WebhookKey: "Webhook authentication key",
    WebhookHeader: "Webhook authentication header",
    clientId: "ID Client",
    createUser: "Créer un nouvel utilisateur",
    users: "Utilisateurs",
    noUser: "Il n'y a aucun utilisateur pour ce compte client",
    confirmRemoveUser:
      "Etes-vous sûr de vouloir supprimer l'utilisateur {{Name}} ?",
    updatingUser: "Modifier utilisateur",
    passwordUpdatePlaceholder: "Laissez vide, si vous ne voulez pas le changer",
    optholdEmpty: "Doit avoir au moins 1 palier/option",
    enableStackedDeliveries: "Activer les livraisons multiples",
    stackedDeliveriesEnabled: "Livraisons mutliples activées",
    showPricing: "Activer l'affichage du prix",
    ruleAlreadyExist: "Règle déjà définie",
    predefinedOptionEmpty: "Les options prédéfinies ne doivent pas être vide",
    priceNotAvailable:
      "Prix non défini pour cette combinaison de valeurs d'attributs",
    clientType: "Type de compte client",
    storeAddress: "Adresse du Store",
    firstName: "Prénom",
    lastName: "Nom",
    searchAddress: "Rechercher une adresse",
    phone: "Téléphone",
    invalidPhone: "Veuillez entrer un numéro valid",
    invalidEmail: "Veuillez saisir un email valide",
    missingPhoneLength: "Il manque {{number}} chiffres à ce numéro",
    email: "Email",
    courierDetails: "Informations pour le livreur",
    addressDetails: "Détails de l'adresse",
    detailsPlaceholder: "étage ou autres infos additionnelles",
    validated: "VALIDÉE",
    waiting_for_validation: "NON VALIDÉE",
    waiting_payment: "Attente de paiement",
    waiting_for_validation_no_payment: "NON VALIDÉE (Attente de paiement)",
    cancelled: "ANNULÉE",
    requestDetails: "Details de la livraison",
    deliveryInfo: "Informations de livraison",
    objects: "Objet à transporter",
    pricingCriteria: "Éléments de tarification",
    requestPricing: "Prix de la livraison",
    saveDeliveryInformation: "Enregistrer les informations de livraison",
    store: "Magasin",
    deliveryAddress: "Adresse de livraison",
    pickupAddress: "Adresse de retrait",
    comment: "Commentaire",
    saveDelivery: "Créer la livraison",
    cancelDelivery: "Annuler la livraison",
    requestStatus: "Statut de la livraison",
    deliveryValidated: "LIVRAISON VALIDÉE",
    deliveryCancelled: "LIVRAISON ANNULÉE",
    requests: "Liste Livraisons",
    noRequestsSearchResult: "Aucune livraison",
    filterByStore: "Chercher dans le tableau",
    deliveryDate: "Date de livraison",
    deliveryTime: "Créneau horaire",
    returnToHomePage: "Retourner vers la page principale",
    status: "Statut",
    delivery: "{{number}} Livraisons",
    noRequestsTitle: "Aucune Livraison",
    noRequestsDescription: "Vous n'avez aucune livraison",
    errorToPushOrder: "Erreur d'enregistrement",
    errorToCancelOrder: "Erreur d'annulation",
    errorToUpdateOrder: "Erreur d'enregistrement",
    errorToloadOrder: "Erreur de chargement",
    cancelConfirm: "Etes-vous sûr de vouloir annuler la commande ?",
    cancelHelp:
      "Aidez-nous à améliorer les services de StuartXL et dites-nous la raison de l'annulation",
    cantCancel: "Impossible d'annuler cette livraison",
    cancelAlert: "Vous êtes sur le point d'annuler une livraison vers",
    cancelComment: "Commentez votre expérience",
    yesCancel: "Oui annuler",
    back: "Retour",
    errorValidatingAddress: "Erreur de validation d'addresse",
    "Address error": "Erreur d'adresse",
    "Courier Issue": "Refus de la livraison par le livreur",
    "Customer cancellation / Requested": "Annulation par le client",
    "Duplicate job": "Demande dupliquée",
    "Incorrect package": "Paquet incorrect",
    "No supply": "Pas de livreur disponible",
    Other: "Autre",
    "Package damaged": "Paquet endommagé",
    "Package not ready": "Paquet non prêt",
    "PU closed": "Retrait clos",
    "Technical issue": "Problème technique",
    "Wrong Transport Type": "Demande invalide",
    "The address can't be geocoded": "Cette adresse n'est pas assez précise",
    "This location is out of range":
      "Désolé, Stuart ne peut pas livrer à cette adresse",
    "Contact phone is required":
      "Cette addresse n'est pas assez précise. Veuillez entrer un numéro de téléphone",
    downloadRules: "Télécharger les règles",
    uploadRules: "Charger des règles",
    "This combination of attributes values is not supported":
      "Prix non défini pour cette combinaison de valeurs d'attributs",
    errorLoadingPrice:
      "Prix non défini pour cette combinaison de valeurs d'attributs",
    expired: "Expirée",
    scheduled: "Programmée",
    in_progress: "En cours",
    finished: "Terminée",
    searching: "Recherche un livreur",
    new: "Nouvelle",
    deliveryLink: "Afficher la livraison",
    objectsFilter: "Table, chaise, armoire à deux portes...",
    pickUpInfo: "Point de retrait",
    dropOffInfo: "Point de livraison",
    channel: "Type de livraison",
    instore: "B2C magasin",
    b2c: "B2C particulier",
    ErrorGettingTimeSlots:
      "Il n'y a pas des créneaux disponibles pour cette date",
    ImpossibleCreationOfDelivery: "Il est impossible de créer la livraison, car l'heure actuelle est passée à l'heure de début de la livraison.",
    defaultWorkingHours: "Horaires ouvrés par défaut",
    CuffOffDurationGreaterThanOneHour: "Cut-off doit être valid et au moins égale à une heure.",
    addSpecificHoursPerZone: "Ajouter un horaire spècifique par zone",
    workingDaysSelection: "Sélection des jours ouvrés",
    workingHoursConfig: "Paramétrage des horaires ouvrés",
    workingSlotsConfig: "Paramétrage des crénaux",
    country: "Pays",
    zone: "Zone",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
    promocode: "Code Promo(s)",
    createPromoCode: "Créer un nouveau code promo",
    promoType: "Type de promotion",
    activatePromoCode: "Activer le code promo",
    maxUseNumber: "Nombre d'utilisation Maximal",
    startTime: "Début",
    endTime: "Fin",
    editPromoCode: "Modifier le Code Promo",
    selectYourOption: "Veillez choisissez votre option",
    noPromoCodeSearchResult: "Aucun Code Promo",
    "code-already-exists": "Code déjà utilisé",
    "code-not-found": "Code non existant",
    noPromoCodeTitle: "Aucun Code Promo",
    noPromoCodeDescription: "Vous n'avez aucun code promo",
    promotionalCodes: "Codes promotionnels",
    promoCode: "Code promotionnel",
    "code-required": "Le code est obligatoire",
    "promo-required": "La valeur du promo est obligatoire",
    "promotype-required": "Le type du promo est obligatoire",
    "application-required": "L'application est obligatoire",
    "starttime-required": "Le date de début est obligatoire",
    "endtime-required": "Le date de fin est obligatoire",
    "maxusenum-required": "Le nombre maximal d'utilisation est obligatoire",
    inactive: "Inactif",
    active: "Actif",
    application: "Application",
    codeExipred: "Expiré",
    code: "Code",
    promo: "Promotion",
    total_distance: "Distance totale",
    addDropOff: "Ajouter une adresse de livraison supplémentaire",
    apply_b2c_draft_config: "Appliquer la configuration",
    configDetails: "La configuration",
    "no-b2cconfig-to-delete": "Ajoutez une configuration",
    "download-b2cconfig": "Téléchargement",
    "upload-file": "Charger la configuration",
    "warning-upload": "Veillez corriger les champs erronés",
    sheetName: "Nom de la feuille",
    errorLine: "La ligne d'erreur",
    columnName: "Le nom de la colonne",
    "check-line-number": "Veillez vérifier le nombre ou l'ordre des lignes",
    errorMessage: "Message d'erreur",
    "config-applied-success": "La configuration est appliquée avec succès",
    "file-upload-success": "La configuration est ajoutée avec succès",
    "no-config-uploaded": "Veillez ajouter une configuration",
    "download-invoice": "Télécharger la facture",
    applyCoupon: "Appliquer",
    invalidPromoCode:
      "Code promo invalide , le prix affiché est celui par défaut",
    errorToValidatePromoCode: "Erreur de validation du code promotionnel",
    "Missing Total Distance": "Ajoutez une distance",
    "nonexistent-category": "La catégorie est inexistante",
    "category-should-be-integer": "La catégorie doit être un entier",
    "check-label": "Vérifier l'étiquette",
    "check-value": "Vérifier la valeur",
    "check-coef": "Vérifier le coefficient",
    address_error: "Erreur d'adresse",
    courier_issue: "Refus de la livraison par le livreur",
    customer_cancellation_requested: "Annulation par le client",
    duplicate_job: "Demande dupliquée",
    incorrect_package: "Paquet incorrect",
    no_supply: "Pas de livreur disponible",
    other: "Autre",
    package_damaged: "Paquet endommagé",
    package_not_ready: "Paquet non prêt",
    pu_closed: "Retrait clos",
    technical_issue: "Problème technique",
    wrong_transport_type: "Demande invalide",
    "check-coef": "Vérifier le coefficient",
    "linkToStore":"Lien du magasin"
  },
};
