import { UPDATE_STATE, LOGOUT } from '../state/constants';
import { fetchWrapper } from "./utils";

export const isAuthenticated = () => !!localStorage.getItem("token");

export const authorization = () => `Bearer ${localStorage.getItem("token")}`;

export const login = async (creds) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
    body: JSON.stringify(creds),
    method: "POST",
    credentials: "include",
  })
  if (!response.ok) {
    throw await response.json();
  }
  const { response: user } = await response.json();
  localStorage.setItem("token", user.jwt);
}

export const logout = (dispatch = () => {}) => {
  localStorage.removeItem("token");
  dispatch({
    type: LOGOUT
  })
  window.location = "/login";
}

export const loadUserData = async (dispatch) => {
  const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/profile`, {
    headers: { authorization: authorization() }
  })
  if (!response.ok) return;
  const { response: user } = await response.json();
  dispatch({ type: UPDATE_STATE, payload: { user } })
}
