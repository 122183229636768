import React from "react";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import "./ObjectInput.css";

const ObjectInput = (props) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    let count = +value;
    const updatedObject = {
      ...props.object,
      count,
      mount: props.object.mount ? count : 0,
      unmount: props.object.unmount ? count : 0,
    };
    props.setObject(updatedObject);
  }

  const handleMouseOut = () => {
    if (!props.object.count || props.object.count <= 0) {
      const updatedObject = {
        ...props.object,
        count: 1,
        mount: props.object.mount ? 1 : 0,
        unmount: props.object.unmount ? 1 : 0,
      };
      props.setObject(updatedObject);
    }
  }

  const incrementObjectCount = () => {
    if (props.object.count < 99 || !props.object.count) {
      let count = !props.object.count ? 1 : ++props.object.count;
      console.log({ count })
      const updatedObject = {
        ...props.object,
        count,
        mount: props.object.mount ? count : 0,
        unmount: props.object.unmount ? count : 0,
      };
      props.setObject(updatedObject);
    }
  };

  const decrementObjectCount = () => {
    const count = !props.object.count || props.object.count <= 2 ? 1 : props.object.count - 1;
    const updatedObject = {
      ...props.object,
      count,
      mount: props.object.mount ? count : 0,
      unmount: props.object.unmount ? count : 0,
    };
    props.setObject(updatedObject);
  };

  const deleteObjectCount = () => {
    props.removeObject();
  }

  const toggleObjectMounting = () => {
    if (props.disabled) return;

    const updatedObject = {
      ...props.object,
      mount: props.object.mount ? 0 : props.object.count,
    };
    props.setObject(updatedObject);
  };

  const toggleObjectUnmounting = () => {
    if (props.disabled) return;

    const updatedObject = {
      ...props.object,
      unmount: props.object.unmount ? 0 : props.object.count,
    };
    props.setObject(updatedObject);
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <div className="objectInputContainer">
      <div
        data-testid="ObjectInput"
        className="d-flex justify-content-between mb-1"
      >
        <span className="object-title mr-2">{t(props.object.name)}</span>
        <div className="object-controls">
          {props.object.count !== 0 &&
            (props.objectConfig?.Mountable ||
              props.objectConfig?.Unmountable) && (
              <div className="d-flex">
                {props.objectConfig?.Unmountable && (
                  <span
                    className={`mr-1 ${props.object.unmount
                      ? "mountingContainer-selected"
                      : "mountingContainer"
                      }`}
                    onClick={toggleObjectUnmounting}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                    &nbsp; <span>Démontage</span>
                  </span>
                )}
                {props.objectConfig?.Mountable && (
                  <span
                    className={`mr-2 ${props.object.mount
                      ? "mountingContainer-selected"
                      : "mountingContainer"
                      }`}
                    onClick={toggleObjectMounting}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlusSquare} />
                    &nbsp; <span>Montage</span>
                  </span>
                )}
              </div>
            )}
          <FontAwesomeIcon
            className="prepend-svg object-controls-minus mr-2"
            icon={faMinus}
            onClick={decrementObjectCount}
          />
          <input
            required
            autoComplete="off"
            type="number"
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            className="inputArrowHide input-number"
            value={props.object.count === 0 ? "" : props.object.count}
            onMouseOut={(e) => {
              handleMouseOut();
            }}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          <FontAwesomeIcon
            className="prepend-svg object-controls-plus ml-1"
            icon={faPlus}
            onClick={incrementObjectCount}
          />
          <FontAwesomeIcon
            className="prepend-svg delete-dropoff-btn ml-2"
            icon={faTrash}
            onClick={deleteObjectCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectInput;
