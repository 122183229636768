import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useTranslation } from "react-i18next";
import RequestAddressInfo from "../RequestAddressInfo/RequestAddressInfo";
import InputGroup from "react-bootstrap/InputGroup";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faSpinner,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import {
  loadRequest,
  updateRequest,
  pushRequest,
  validateRequestAddress,
  getTimeSlots,
  validatePromoCode,
  getClientSlotType,
} from "../../utilities/requests";
import { loadRequestPrice, loadAttributes } from "../../utilities/prices";
import { getDistance } from "../../utilities/maps";
import { GlobalStateContext } from "../../state";
import { defaultPrice, toastOptions } from "../../state/constants";
import { getB2CPricingConfig } from "../../utilities/prices";
import { ToastContainer, toast, Slide } from "react-toastify";
import RequestCanceller from "../RequestCanceller/RequestCanceller";
import ObjectsPicker from "../ObjectsPicker/ObjectsPicker";
import TimeSlotPicker from "../TimeSlotPicker/TimeSlotPicker";
import PhoneInput, { validatePhone } from "../PhoneInput/PhoneInput";
import EmailInput, { validateEmail } from "../EmailInput/EmailInput";
import { openZones } from "../../state/constants";

import "./RequestPage.css";

// TODO add unit tests and refactoring (split in components and move RequestPage to a page folder)

const b2cChannel = "b2c";
const instoreChannel = "instore";

const puPointType = "picking";
const doPointType = "delivering";

const defaultRequest = {
  ID: "",
  Store: "",
  Status: "validation",
  Channel: instoreChannel,
  ClientAccountID: "",
  Job: null,
  invoice_link: "",
  JobRequest: {
    pickups: [
      {
        address: "",
        comment: "",
        contact: { firstname: "", lastname: "", phone: "", email: "" },
      },
    ],
    dropoffs: [
      {
        package_type: "xxlarge",
        address: "",
        comment: "",
        contact: { firstname: "", lastname: "", phone: "", email: "" },
        package_description: "",
      },
    ],
    dropoff_at: "",
    pickup_at: "",
  },
  PricingInfoRequest: {
    price: {
      currency: "EUR",
      tax_percentage: "0.2",
      price_tax_included: "0",
      price_tax_excluded: "0",
      tax_amount: "0",
    },
    deliveries: [{ price: "0", pricing_criteria: {}, objects: [] }],
    promocode: "",
  },
};


const validPc = (rq, pa) => {
  for (const dpi of rq.PricingInfoRequest.deliveries) {
    for (const val of pa) {
      if (dpi.pricing_criteria[val.name] === "") return false;
    }

    if (rq.Channel === b2cChannel) {
      for (const val of pa) {
        if (dpi.pickup_pricing_criteria[val.name] === "") return false;
      }

      if (!dpi.objects || !dpi.objects.length) return false;
    }
  }
  return true;
};

const getDateKey = (channel, dropoffs) =>
  channel === instoreChannel
    ? dropoffs.length > 1
      ? "pickup_at"
      : "dropoff_at"
    : "pickup_at";

const getAddressZone = (newreq) => {
  try {
    const addressSubstrings =
      newreq.Channel === instoreChannel
        ? newreq.JobRequest.dropoffs[0].address.split(", ")
        : newreq.JobRequest.pickups[0].address.split(", ");

    const dropOffZone = addressSubstrings[addressSubstrings.length - 2]
      .replace(/\s/g, "_")
      .toLowerCase();

    return openZones.includes(dropOffZone) ? dropOffZone : "paris";
  } catch (err) {
    return "paris";
  }
};

const getSlots = async (newReq, date) => {
  const type = newReq.Channel === instoreChannel ? "dropoff" : "pickup";
  const zone = getAddressZone(newReq);

  const today = dayjs(date)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  const pickedDate = dayjs(date)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  if (pickedDate < today) {
    return [];
  }

  const nextDate = dayjs(date).add(1, "day");
  let pickedDateRes = [];
  let nextDateRes = [];

  try {
    pickedDateRes = await getTimeSlots(newReq.ClientAccountID, {
      date,
      type,
      zone,
    });
  } catch (error) {
    pickedDateRes = [];
  }

  try {
    nextDateRes = await getTimeSlots(newReq.ClientAccountID, {
      date: dayjs(date).add(1, "day").format("YYYY-MM-DD"),
      type,
      zone,
    });
  } catch (error) {
    nextDateRes = [];
  }

  const pickedDateTimeSlots = [
    ...pickedDateRes
      .filter((date) => pickedDate <= dayjs(date.start_time))
      .map((date) => ({
        start_time: dayjs(date.start_time).format(),
        end_time: dayjs(date.end_time).format(),
      })),
    ...nextDateRes
      .filter((date) => nextDate > dayjs(date.start_time))
      .map((date) => ({
        start_time: dayjs(date.start_time).format(),
        end_time: dayjs(date.end_time).format(),
      })),
  ];

  return pickedDateTimeSlots;
};

const RequestPage = () => {
  const { t } = useTranslation();

  const [, { dispatch }] = useContext(GlobalStateContext);

  const [request, setRequest] = useState(defaultRequest);
  const [slotType, setSlotType] = useState("");
  const [pricingConfig, setPricingConfig] = useState({ Objects: [] });
  const [pricingAttributes, setPricingAttributes] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const [validatingAddress, setValidatingAddress] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [pushingAddress, setPushingAddress] = useState(false);
  const [updatingRequest, setUpdatingRequest] = useState(false);
  const [errorSlots, setErrorSlots] = useState("");
  const [priceError, setPriceError] = useState("");
  const [promocode, setPromoCode] = useState(
    request.PricingInfoRequest.promocode
  );
  const [promoCodeError, setPromoCodeError] = useState("");
  const [validAddress, setValidAddress] = useState({
    picking: { 0: { valid: true } },
    delivering: { 0: { valid: true }, 1: { valid: true }, 2: { valid: true } },
  });
  const [loadingRequest, setLoadingRequest] = useState(false);
  const history = useHistory();
  const reloadPrice = useRef(false);
  const attributesLoaded = useRef(false);
  const pricingConfigLoaded = useRef(false);
  const updatedRequest = useRef(false);

  const callingAPI =
    updatingRequest ||
    validatingAddress ||
    loadingPrice ||
    pushingAddress ||
    loadingRequest;

  const { id } = useParams();

  const onDatePickerChange = async (date) => {
    updatedRequest.current = true;
    const newTimeSlots = await getSlots(request, date);
    setTimeSlots(newTimeSlots);
    let errSlots = "";
    let newDeliveryTime = date;

    if (newTimeSlots.length > 0) {
      newDeliveryTime = newTimeSlots[0].start_time;
    } else {
      errSlots = t("ErrorGettingTimeSlots");
    }

    setErrorSlots(errSlots);
    setRequest({
      ...request,
      JobRequest: {
        ...request.JobRequest,
        [getDateKey(request.Channel, request.JobRequest.dropoffs)]:
          newDeliveryTime,
      },
    });
  };

  useEffect(() => {
    setLoadingRequest(true);
    loadRequest(id)
      .then(async (requestRsp) => {
        setPromoCode(requestRsp.PricingInfoRequest.promocode);
        const deliverytime = dayjs(
          requestRsp.JobRequest[
          getDateKey(requestRsp.Channel, requestRsp.JobRequest.dropoffs)
          ]
        );

        if (deliverytime < dayjs() && requestRsp.Status === "validation") {
          requestRsp.JobRequest[
            getDateKey(requestRsp.Channel, requestRsp.JobRequest.dropoffs)
          ] = deliverytime.format();
          setRequest({ ...requestRsp });
        } else {
          requestRsp.JobRequest[
            getDateKey(requestRsp.Channel, requestRsp.JobRequest.dropoffs)
          ] = deliverytime.format();
          setRequest({ ...requestRsp });

          const newTimeSlots = await getSlots(
            requestRsp,
            deliverytime.format("YYYY-MM-DD")
          );
          setTimeSlots(newTimeSlots);
          if (newTimeSlots.length === 0 && requestRsp.Status === "validation")
            setErrorSlots(t("ErrorGettingTimeSlots"));
        }
        setLoadingRequest(false);
      })
      .catch((r) => {
        setLoadingRequest(false);
        toast.error(t("errorToloadOrder"), toastOptions);
      })
      .finally(() => {
        setLoadingRequest(false);
      });
  }, [id, t]);

  useEffect(() => {
    if (pricingConfig.PAConditions) {
      setPricingAttributes(
        pricingConfig.PAConditions.map((pac) => ({
          name: pac.Label,
          type: "select",
          options: pac.Values,
        }))
      );
      attributesLoaded.current = true;
    }
  }, [pricingConfig]);

  useEffect(() => {
    const clientID = request.ClientAccountID;
    if (
      request.Channel === b2cChannel &&
      clientID &&
      !pricingConfigLoaded.current
    ) {
      getB2CPricingConfig().then((b2cConfig) => {
        setPricingConfig(b2cConfig);
        if (pricingConfig.PAConditions) {
          setPricingAttributes(
            b2cConfig.PAConditions.map((pac) => ({
              name: pac.Label,
              type: "select",
              options: pac.Values,
            }))
          );
        }
      });
      pricingConfigLoaded.current = true;
      attributesLoaded.current = true;
    }

    if (
      request.Channel === instoreChannel &&
      clientID &&
      !attributesLoaded.current
    ) {
      loadAttributes(dispatch, clientID).then(setPricingAttributes);
      attributesLoaded.current = true;
    }

    if (reloadPrice.current && validPc(request, pricingAttributes)) {
      setLoadingPrice(true);

      loadRequestPrice(
        request.ClientAccountID,
        request.ID,
        request.PricingInfoRequest
      )
        .then((response) => {
          const newPi = response;
          setRequest({ ...request, PricingInfoRequest: newPi });
          setPriceError("");
        })
        .catch(() => {
          setRequest({
            ...request,
            PricingInfoRequest: {
              ...request.PricingInfoRequest,
              price: defaultPrice,
            },
          });

          setPriceError("errorLoadingPrice");
        })
        .finally(() => {
          reloadPrice.current = false;
          setLoadingPrice(false);
        });
    }
  }, [request, dispatch, pricingAttributes, pricingConfig]);

  const getHiddenFileds = (addressType) => {
    if (addressType === puPointType && request.Channel === b2cChannel) {
      return ["package_description"];
    }

    if (addressType === doPointType && request.Channel === b2cChannel) {
      return ["package_description"];
    }
  };

  const getUserContact = () => {
    return {
      email: request.UserContact?.email || "",
      phone: request.UserContact?.phone || "",
    };
  };

  const checkAddress = (address, phone, index, type) => {
    if (!address.length) {
      setValidAddress({
        ...validAddress,
        [type]: { ...validAddress[type], [index]: { valid: false, error: "" } },
      });
    } else {
      setValidatingAddress(true);
      validateRequestAddress(request.ClientAccountID, {
        address,
        phone,
        type: puPointType,
      })
        .then(({ success, error }) => {
          if (success)
            setValidAddress({
              ...validAddress,
              [type]: {
                ...validAddress[type],
                [index]: { valid: true, error: "" },
              },
            });
          else
            setValidAddress({
              ...validAddress,
              [type]: {
                ...validAddress[type],
                [index]: { valid: false, error: error },
              },
            });
        })
        .catch(() =>
          setValidAddress({
            ...validAddress,
            [type]: {
              ...validAddress[type],
              [index]: { valid: false, error: "errorValidatingAddress" },
            },
          })
        )
        .finally(() => {
          setValidatingAddress(false);
        });
    }
  };

  const onAddressChange = (index, type) => async (adressInfo) => {
    updatedRequest.current = true;
    reloadPrice.current = true;
    const newRequest = { ...request };
    let phone = "";
    if (type === puPointType) {
      newRequest.JobRequest.pickups[index].address = adressInfo.address;
      phone = newRequest.JobRequest.pickups[index].contact?.phone;
    }

    if (type === doPointType) {
      newRequest.JobRequest.dropoffs[index].address = adressInfo.address;
      phone = newRequest.JobRequest.dropoffs[index].contact?.phone;
    }

    if (
      request.Channel === instoreChannel &&
      pricingAttributes.find((value) => value.name === "distance")
    ) {
      const newDistance = await getDistance(
        newRequest.JobRequest.pickups[0].address,
        [adressInfo.address]
      );
      newRequest.PricingInfoRequest.deliveries[0].pricing_criteria["distance"] =
        newDistance.distance;
    } else if (request.Channel === b2cChannel) {
      const destinations = newRequest.JobRequest.dropoffs.map((x) => x.address);
      const newDistance = await getDistance(
        newRequest.JobRequest.pickups[0].address,
        destinations
      );
      newRequest.PricingInfoRequest.total_distance = parseFloat(
        newDistance.distance
      );
    }

    setRequest({ ...newRequest });
    checkAddress(adressInfo.address, phone, index, type);
  };

  const onPhoneChange = (index, type) => (phoneValue) => {
    updatedRequest.current = true;
    const newRequest = request;
    if (type === puPointType) {
      newRequest.JobRequest.pickups[index].contact.phone = phoneValue;
      setRequest({ ...newRequest });

      if (validatePhone(phoneValue)) {
        checkAddress(
          newRequest.JobRequest.pickups[index].address,
          phoneValue,
          index,
          type
        );
      }
    }

    if (type === doPointType) {
      newRequest.JobRequest.dropoffs[index].contact.phone = phoneValue;
      setRequest({ ...newRequest });

      if (validatePhone(phoneValue)) {
        checkAddress(
          newRequest.JobRequest.dropoffs[index].address,
          phoneValue,
          index,
          type
        );
      }
    }
  };

  const onFirstNameChange = (index, type) => (firstnameValue) => {
    updatedRequest.current = true;
    const newRequest = request;
    if (type === puPointType)
      newRequest.JobRequest.pickups[index].contact.firstname = firstnameValue;

    if (type === doPointType)
      newRequest.JobRequest.dropoffs[index].contact.firstname = firstnameValue;

    setRequest({ ...newRequest });
  };

  const onLastNameChange = (index, type) => (lastnameValue) => {
    updatedRequest.current = true;
    const newRequest = request;
    if (type === puPointType)
      newRequest.JobRequest.pickups[index].contact.lastname = lastnameValue;

    if (type === doPointType)
      newRequest.JobRequest.dropoffs[index].contact.lastname = lastnameValue;

    setRequest({ ...newRequest });
  };

  const onObjectChange = (objects, index) => {
    updatedRequest.current = true;
    const newRequest = { ...request };
    newRequest.PricingInfoRequest.deliveries[index].objects = objects || [];

    setRequest({ ...newRequest });
    reloadPrice.current = true;
  };

  const onEmailUserInfoChange = (newEmail) => {
    updatedRequest.current = true;
    const newRequest = request;

    newRequest.UserContact.email = newEmail;

    setRequest({ ...newRequest });
  };

  const onPhoneUserInfoChange = (phoneValue) => {
    updatedRequest.current = true;
    const newRequest = request;

    newRequest.UserContact.phone = phoneValue;

    setRequest({ ...newRequest });
  };

  const onCommentChange = (index, type) => (e) => {
    updatedRequest.current = true;
    const newRequest = request;

    if (type === puPointType) {
      newRequest.JobRequest.pickups[index].comment = e.target.value;
    }
    if (type === doPointType) {
      newRequest.JobRequest.dropoffs[index].comment = e.target.value;
    }
    setRequest({ ...newRequest });
  };

  const onPackageDescriptionChange = (index, type) => (e) => {
    updatedRequest.current = true;
    const newRequest = request;
    if (type === puPointType) {
      newRequest.JobRequest.pickups[index].package_description = e.target.value;
    }
    if (type === doPointType) {
      newRequest.JobRequest.dropoffs[index].package_description =
        e.target.value;
    }
    setRequest({ ...newRequest });
  };

  const onDeliveryDateChange = (date) => {
    updatedRequest.current = true;
    const newRequest = request;

    setRequest({
      ...newRequest,
      JobRequest: {
        ...newRequest.JobRequest,
        [getDateKey(newRequest.Channel, newRequest.JobRequest.dropoffs)]: date,
      },
    });
  };

  const onPrCriteriaChange = (key, value, index) => {
    updatedRequest.current = true;
    const newRequest = request;
    newRequest.PricingInfoRequest.deliveries[index].pricing_criteria[key] =
      value;
    reloadPrice.current = true;
    setRequest({ ...newRequest });
  };

  const onPUPrCriteriaChange = (key, value) => {
    updatedRequest.current = true;
    const newRequest = request;

    for (const dv of newRequest.PricingInfoRequest.deliveries) {
      dv.pickup_pricing_criteria[key] = value;
    }

    reloadPrice.current = true;
    setRequest({ ...newRequest });
  };

  const validUserContact = () => {
    const userInfo = getUserContact();
    if (
      userInfo.email === "" ||
      userInfo.phone === "" ||
      !validateEmail(userInfo.email) ||
      !validatePhone(userInfo.phone)
    ) {
      return false;
    }

    return true;
  };

  const validPi = () => {
    if (
      !request.PricingInfoRequest.price ||
      request.PricingInfoRequest.price.price_tax_included === "0" ||
      request.PricingInfoRequest.price.price_tax_included === ""
    ) {
      return false;
    }

    for (const dpi of request.PricingInfoRequest.deliveries) {
      if (
        request.Channel === instoreChannel &&
        (dpi.price === "" || dpi.price === "0")
      ) {
        return false;
      }

      if (
        request.Channel === b2cChannel &&
        (!dpi.objects || dpi.objects.length === 0)
      ) {
        return false;
      }

      for (const val of pricingAttributes) {
        if (
          dpi.pricing_criteria[val.name] === "" ||
          !dpi.pricing_criteria[val.name]
        ) {
          return false;
        }

        if (
          request.Channel === b2cChannel &&
          (dpi.pickup_pricing_criteria[val.name] === "" ||
            !dpi.pickup_pricing_criteria[val.name])
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const getRequestDate = () => {
    return request.JobRequest[
      getDateKey(request.Channel, request.JobRequest.dropoffs)
    ];
    // return "2022-07-08T02:00:00+02:00"
  };

console.log({ pricingAttributes })

  const validPU = () =>
    request.JobRequest.pickups[0].address &&
    validAddress[puPointType][0].valid &&
    (request.Channel === instoreChannel ||
      validatePhone(request.JobRequest.pickups[0].contact.phone));

  const validDO = () => {
    const lenDO = request.JobRequest.dropoffs.length;
    for (let index = 0; index < lenDO; index++) {
      const vdo =
        request.JobRequest.dropoffs[0].address &&
        validAddress[doPointType][index].valid &&
        validatePhone(request.JobRequest.dropoffs[index].contact.phone);
      if (!vdo) return false;
    }

    return true;
  };

  const validPromoCode = () => {
    return request.PricingInfoRequest.promocode !== "";
  };
  const validPricingInfo = useMemo(() => request.PricingInfoRequest.deliveries.every((ob) =>
    ob.objects.every(a => a.count && a.count > 0)
  ), [request.PricingInfoRequest.deliveries])
  const validRequest = () => {
    return (
      !errorSlots &&
      getRequestDate() !== "" &&
      validDO() &&
      validPU() &&
      validPi() &&
      validUserContact()
    );
  };

  const updateRequestInfo = () => {
    setUpdatingRequest(true);
    updateRequest(request.ClientAccountID, request.ID, {
      UserContact: getUserContact(),
      JobRequest: request.JobRequest,
      PricingInfoRequest: request.PricingInfoRequest,
    })
      .then((success) => {
        if (success) window.location.reload();
      })
      .catch((r) => {
        if (r.error && r.error.message) {
          toast.error(t(r.error.message), toastOptions);
        } else {
          toast.error(t("errorToUpdateOrder"), toastOptions);
        }
      })
      .finally(() => {
        setUpdatingRequest(false);
      });
  };

  const resetRequest = () => {
    window.location.reload();
  };

  const handlePushEvent = () => {
    setPushingAddress(true);
    pushRequest(request.ID, request.ClientAccountID)
      .then(() => {
        setLoadingRequest(true);
        window.location.reload();
      })
      .catch((r) => {
        toast.error(t("errorToPushOrder"), toastOptions);
      })
      .finally(() => {
        setPushingAddress(false);
      });
  };

  const postCancel = (withError) => {
    if (withError) toast.error(t("errorToCancelOrder"), toastOptions);
    else window.location.reload();
  };

  const isDeliveryEditable = ["validation", "pushed"].includes(request.Status);

  const isDeliveryEditableField = (fieldName) => {
    let jobstatus = "";
    if (request.Job) {
      jobstatus = request.Job.status;
    }

    if (
      [
        "package_description",
        "price_objects",
        "comment",
        "price_attributes",
        "price",
      ].includes(fieldName)
    ) {
      return (
        ["validation", "pushed"].includes(request.Status) &&
        jobstatus !== "finished"
      );
    }

    return request.Status === "validation";
  };

  const notAllowedStatus = () => {
    return ["finished", "pushed"].includes(request.Status);
  };

  const applyCoupon = () => {
    validatePromoCode(request.ID, promocode)
      .then((res) => {
        if (res) {
          setPromoCodeError("");
          setRequest((request) => {
            request.PricingInfoRequest.promocode = promocode;
            return request;
          });
          reloadPrice.current = true;
          updatedRequest.current = true;
          if (reloadPrice.current && validPc(request, pricingAttributes)) {
            setLoadingPrice(true);
            loadRequestPrice(
              request.ClientAccountID,
              request.ID,
              request.PricingInfoRequest
            )
              .then((response) => {
                const newPi = response;
                setRequest({ ...request, PricingInfoRequest: newPi });
                setPriceError("");
              })
              .catch(() => {
                setRequest({
                  ...request,
                  PricingInfoRequest: {
                    ...request.PricingInfoRequest,
                    price: defaultPrice,
                  },
                });
                setPriceError("errorLoadingPrice");
              })
              .finally(() => {
                reloadPrice.current = false;
                setLoadingPrice(false);
              });
          }
        } else {
          updatedRequest.current = false;
          setPromoCodeError("invalidPromoCode");
          setRequest((request) => {
            request.PricingInfoRequest.promocode = "";
            return request;
          });
          reloadPrice.current = true;
          if (reloadPrice.current && validPc(request, pricingAttributes)) {
            setLoadingPrice(true);
            loadRequestPrice(
              request.ClientAccountID,
              request.ID,
              request.PricingInfoRequest
            )
              .then((response) => {
                const newPi = response;
                setRequest({ ...request, PricingInfoRequest: newPi });

                setPriceError("");
              })
              .catch(() => {
                setRequest({
                  ...request,
                  PricingInfoRequest: {
                    ...request.PricingInfoRequest,
                    price: defaultPrice,
                  },
                });
                setPriceError("errorLoadingPrice");
              })
              .finally(() => {
                reloadPrice.current = false;
                setLoadingPrice(false);
              });
          }
        }
      })
      .catch((r) => {
        if (r.error && r.error.message) {
          toast.error(t(r.error.message), toastOptions);
        } else {
          toast.error(t("errorToValidatePromoCode"), toastOptions);
        }
      });
  };

  const onPromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  const getMaxDays = () => {
    const payIntentCreatedAt = new Date(request.PaymentIntent?.created * 1000);
    const diffDays =
      parseInt(process.env.REACT_APP_MAX_DAYS ?? "6") -
      dateDiffInDays(payIntentCreatedAt, new Date()) - 1;
    return request?.PaymentIntent ? (diffDays > 0 ? diffDays : 0) : 30;
  };

  useEffect(() => {
    if (request.ClientAccountID === "") return;
    (async () => {
      const slotType = await getClientSlotType(request.ClientAccountID);
      setSlotType(slotType);
    })()
  }, [request]);

  useEffect(() => {
    if (
      slotType === "Custom" &&
      request.JobRequest.delivery_slot &&
      dayjs(new Date()).isAfter(dayjs(request.JobRequest.delivery_slot.start_time))
    ) {
      setErrorSlots(t("ImpossibleCreationOfDelivery"))
    }
  }, [slotType, request, timeSlots, t]);

  return (
    <Row>
      <Col>
        <Row className="arrowLeft" onClick={() => history.push("/requests")}>
          <img alt="arrow left" src="/left-arrow.svg" className="arrowLogo" />
          {t("returnToHomePage")}
        </Row>
        <Row className="requestDetails">
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4> {t("requestDetails")} </h4>
              {request.invoice_link && (
                <Button
                  variant="primary"
                  onClick={() => {
                    window.location.href = request?.invoice_link;
                  }}
                  style={{
                    borderColor: "#007bff",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    height: "50px",
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> {t("download-invoice")}
                </Button>
              )}
            </div>

            <Row>
              <Col>
                <Row>
                  <Col>
                    <h6> {t("deliveryInfo")} </h6>
                    <Row>
                      <Col>
                        <PhoneInput
                          value={getUserContact().phone}
                          disabled={!isDeliveryEditableField("phone")}
                          onChange={onPhoneUserInfoChange}
                        />
                      </Col>
                      <Col>
                        <EmailInput
                          value={getUserContact().email}
                          disabled={!isDeliveryEditableField("email")}
                          onChange={onEmailUserInfoChange}
                        />
                      </Col>
                    </Row>
                    <TimeSlotPicker
                      date={getRequestDate()}
                      setDate={onDeliveryDateChange}
                      getTimeSlots={onDatePickerChange}
                      timeSlots={timeSlots}
                      disabled={
                        (!isDeliveryEditableField("deliveryTime") &&
                          request?.Job?.status !== "scheduled") ||
                        slotType === "Custom"
                      }
                      errorSlots={errorSlots}
                      maxDays={getMaxDays()}
                      slotType={slotType}
                      deliverySlot={request.JobRequest.delivery_slot}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6> {t("pickUpInfo")} </h6>
                    {request.Channel === instoreChannel && (
                      <Row>
                        {" "}
                        <Col>
                          {" "}
                          <Form.Group controlId="storeName">
                            <Form.Label>{t("store")}</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend className="customInputGroup disabledInputGroup">
                                <FontAwesomeIcon icon={faMapMarkerAlt}>
                                  {" "}
                                </FontAwesomeIcon>
                              </InputGroup.Prepend>
                              <Form.Control
                                type="text"
                                value={request.Store}
                                disabled
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>{" "}
                      </Row>
                    )}
                    {request.Channel === b2cChannel && (
                      <Fragment>
                        <RequestAddressInfo
                          addressinfo={request.JobRequest.pickups[0]}
                          onAddressChange={onAddressChange(0, puPointType)}
                          onCommentChange={onCommentChange(0, puPointType)}
                          onPhoneChange={onPhoneChange(0, puPointType)}
                          onFirstNameChange={onFirstNameChange(0, puPointType)}
                          onLastNameChange={onLastNameChange(0, puPointType)}
                          onPackageDescriptionChange={onPackageDescriptionChange(
                            0,
                            puPointType
                          )}
                          isDeliveryEditableField={isDeliveryEditableField}
                          validAddress={validAddress[puPointType][0]}
                          hiddenInputs={getHiddenFileds(puPointType)}
                        />
                        <Row>
                          {pricingAttributes.length > 0 && (
                            <Col xs={12}>
                              <strong> {t("pricingCriteria")} </strong>
                            </Col>
                          )}

                          {pricingAttributes
                            .filter((attr) => attr.name !== "distance")
                            .map((pa, paIndex) => (
                              <Col key={paIndex}>
                                <Form.Group controlId={pa.name}>
                                  {pa.type === "select" && (
                                    <Form.Control
                                      as="select"
                                      value={
                                        request.PricingInfoRequest.deliveries[0]
                                          .pickup_pricing_criteria[pa.name]
                                      }
                                      onChange={(e) => {
                                        onPUPrCriteriaChange(
                                          pa.name,
                                          e.target.value
                                        );
                                      }}
                                      disabled={
                                        !isDeliveryEditableField(
                                          "price_attributes"
                                        )
                                      }
                                    >
                                      <option value="">{pa.name}</option>
                                      {pa.options?.map((option) => (
                                        <option key={option.Value} value={option.Value}>
                                          {option.Value}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  )}
                                  {pa.type === "input" && (
                                    <Form.Control
                                      type="number"
                                      value={
                                        request.PricingInfoRequest.deliveries[0]
                                          .pickup_pricing_criteria[pa.name]
                                      }
                                      placeholder={pa.name}
                                      onChange={(e) => {
                                        onPUPrCriteriaChange(
                                          pa.name,
                                          e.target.value
                                        );
                                      }}
                                      disabled={
                                        !isDeliveryEditableField(
                                          "price_attributes"
                                        )
                                      }
                                    />
                                  )}
                                </Form.Group>
                              </Col>
                            ))}
                        </Row>
                      </Fragment>
                    )}
                  </Col>
                </Row>
                {request.JobRequest.dropoffs.map((val, index) => (
                  <Fragment key={index}>
                    <Row>
                      <Col>
                        <h6>
                          {" "}
                          {t("dropOffInfo")}
                          {request.JobRequest.dropoffs.length > 1
                            ? `(${index + 1})`
                            : ""}
                        </h6>
                        <RequestAddressInfo
                          addressinfo={val}
                          onAddressChange={onAddressChange(index, doPointType)}
                          onCommentChange={onCommentChange(index, doPointType)}
                          onPhoneChange={onPhoneChange(index, doPointType)}
                          onFirstNameChange={onFirstNameChange(
                            index,
                            doPointType
                          )}
                          onLastNameChange={onLastNameChange(
                            index,
                            doPointType
                          )}
                          onPackageDescriptionChange={onPackageDescriptionChange(
                            index,
                            doPointType
                          )}
                          isDeliveryEditableField={isDeliveryEditableField}
                          validAddress={validAddress[doPointType][index]}
                          hiddenInputs={getHiddenFileds(doPointType)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {pricingAttributes.length > 0 && (
                        <Col xs={12}>
                          <strong> {t("pricingCriteria")} </strong>
                        </Col>
                      )}

                      {pricingAttributes
                        .filter((attr) => attr.name !== "distance")
                        .map((pa, paIndex) => (
                          <Col key={paIndex}>
                            <Form.Group controlId={pa.name}>
                              {pa.type === "select" && (
                                <Form.Control
                                  as="select"
                                  value={
                                    request.PricingInfoRequest.deliveries[index]
                                      .pricing_criteria[pa.name]
                                  }
                                  onChange={(e) => {
                                    onPrCriteriaChange(
                                      pa.name,
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  disabled={
                                    !isDeliveryEditableField("price_attributes")
                                  }
                                >
                                  <option value="">{pa.name}</option>
                                  {pa.options?.map((option) => (
                                    <option key={option.Value} value={option.Value}>
                                      {option.Value}
                                    </option>
                                  ))}
                                </Form.Control>
                              )}
                              {pa.type === "input" && (
                                <Form.Control
                                  type="number"
                                  value={
                                    request.PricingInfoRequest.deliveries[index]
                                      .pricing_criteria[pa.name]
                                  }
                                  placeholder={pa.name}
                                  onChange={(e) => {
                                    onPrCriteriaChange(
                                      pa.name,
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  disabled={
                                    !isDeliveryEditableField("price_attributes")
                                  }
                                />
                              )}
                            </Form.Group>
                          </Col>
                        ))}
                    </Row>
                    {request.Channel === b2cChannel && (
                      <Row className="Objects-input">
                        <Col xs={12}>
                          <strong> {t("objects")} </strong>
                        </Col>
                        <Col>
                          <ObjectsPicker
                            key={index}
                            pricingConfig={pricingConfig}
                            disabled={!isDeliveryEditableField("price_objects")}
                            objects={
                              request.PricingInfoRequest.deliveries[index]
                                .objects
                            }
                            setObjects={(value) => onObjectChange(value, index)}
                          />
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                ))}
              </Col>
            </Row>
            <Row className="promo-code">
              <Col>
                <h6> {t("promoCode")} </h6>
                <Row
                  style={{
                    marginLeft: 3,
                    width: "380px",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Group controlId="promoCode">
                    <Form.Control
                      type="string"
                      value={promocode}
                      onChange={(e) => onPromoCodeChange(e)}
                      disabled={notAllowedStatus()}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    style={{ height: 40 }}
                    disabled={notAllowedStatus()}
                    onClick={applyCoupon}
                  >
                    {!callingAPI && <span>{t("applyCoupon")}</span>}
                    {callingAPI && (
                      <span>
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      </span>
                    )}
                  </Button>
                </Row>
                {promoCodeError !== "" && (
                  <Form.Text className="address-invalidmsg">
                    {t(promoCodeError)}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row className="request-pricing">
              <Col>
                <h6> {t("requestPricing")} </h6>
                <Form.Group controlId="requestPricing">
                  <Form.Label>{t("price")} €</Form.Label>
                  <Form.Control
                    type="number"
                    value={request.PricingInfoRequest.price.price_tax_included}
                    disabled
                  />
                  {priceError !== "" && (
                    <Form.Text className="address-invalidmsg">
                      {t(priceError)}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {isDeliveryEditable && (
              <Fragment>
                <Button
                  variant="primary"
                  disabled={
                    !validRequest() ||
                    callingAPI ||
                    !updatedRequest.current ||
                    !validPromoCode() ||
                    !validPricingInfo
                  }
                  onClick={updateRequestInfo}
                >
                  {!callingAPI && <span>{t("saveDeliveryInformation")}</span>}
                  {callingAPI && (
                    <span>
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    </span>
                  )}
                </Button>
                {!callingAPI && (
                  <Button variant="light" onClick={resetRequest}>
                    {" "}
                    {t("cancel")}{" "}
                  </Button>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
        <Row className="requestDetails">
          <Col xs={4} className="requestStatus">
            <h4> {t("requestStatus")} </h4>
          </Col>
          <Col xs={8}>
            {request.Status === "cancelled" && (
              <Badge variant="danger">{t("deliveryCancelled")}</Badge>
            )}
            {request.Status === "validation" && (
              <Button
                variant="primary"
                disabled={
                  !validRequest() ||
                  callingAPI ||
                  updatedRequest.current ||
                  !request.PaymentIntent ||
                  (
                    slotType === "Custom" &&
                    request.JobRequest.delivery_slot &&
                    dayjs(new Date()).isAfter(dayjs(request.JobRequest.delivery_slot.start_time))
                  )
                }
                onClick={handlePushEvent}
              >
                {!callingAPI && (
                  <span>
                    {t(
                      request.PaymentIntent ? "saveDelivery" : "waiting_payment"
                    )}
                  </span>
                )}
                {callingAPI && (
                  <span>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  </span>
                )}
              </Button>
            )}
            {request.Status === "pushed" && (
              <Badge variant="success">
                {t("deliveryValidated")} ({t(request.Job?.status)})
              </Badge>
            )}
            {request.Status !== "cancelled" && !callingAPI && (
              <RequestCanceller
                destinations={[request.JobRequest.dropoffs[0].address]}
                postCancel={postCancel}
                entity="deliveries"
                entityId={request.ID}
                entityClientAccountID={request.ClientAccountID}
                entityStatus={request.Status}
              >
                <Button variant="light">{t("cancelDelivery")}</Button>
              </RequestCanceller>
            )}
          </Col>
          {request.Status === "pushed" && (
            <Col xs={{ offset: 4 }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_STUART_DASHBOARD}/jobs/${request.Job.id}`}
              >
                {t("deliveryLink")}
              </a>
            </Col>
          )}
        </Row>
      </Col>
      <ToastContainer transition={Slide} />
    </Row>
  );
};

RequestPage.defaultProps = {};

export default RequestPage;
