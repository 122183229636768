import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './Slideout.css';

const Slideout = (props) =>{

  const [isVisible, setIsVisible] = useState(false);

  return(
    <div className="slideout" data-visible={isVisible}>
      <div className="slideout-tongue" onClick={() => setIsVisible(!isVisible)}>
        {isVisible && <FontAwesomeIcon icon={faChevronRight} size="2x"/> }
        {!isVisible && <FontAwesomeIcon icon={faChevronLeft} size="2x"/> }
      </div>
      <div className="slideout-content">
        {props.children}
      </div>
    </div>
  )
};

export default Slideout;
