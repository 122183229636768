import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Container,
  Col,
  Row,
  Card,
  ListGroup,
  Form,
  Button,
} from "react-bootstrap";

import { workTimes, daysOfWeek, toastOptions } from "../../state/constants";

import {
  getSlotsConfig,
  createSlotsConfig,
  deleteSlotsConfigByClientID,
} from "../../utilities/requests";
import { loadClient } from "../../utilities/clients";
import { openZones } from "../../state/constants";

import "./B2CWorkingHoursConfig.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast, Slide } from 'react-toastify';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const ClientType = {
  b2c: "Particulier",
  instore: "Magasin",
  b2b: "B2B",
};
const SlotType = {
  Standard: "Standard",
  Custom: "Custom",
};
const defaultConfig = {
  Day: "Monday",
  StartTime: "00:00",
  EndTime: "00:00",
  Zone: undefined,
  ClientAccountId: undefined,
  CuttOff: "00:00",
  ServiceTime: "02:00",
  Activated: false,
  TimeZone: timezone,
  Slots: [{ StartTime: "00:00", EndTime: "00:00" }],
  SlotType: SlotType.Standard,
  Channel: "b2c",
};

const B2CWorkingHoursConfig = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [config, setConfig] = useState(defaultConfig);
  const [stagedConfig, setStagedConfig] = useState(defaultConfig);
  const [zone, setZone] = useState("paris");
  const [isCustomTimeChanged, setIsCustomTimeChanged] = useState(false);

  useEffect(() => {
    selectDay("Monday");
    // getSlotsConfigs().then((res) => {
    //   console.log(res);
    // });
  }, []);

  const getAddressZone = (address) => {
    try {
      const addressSubstrings = address.split(", ");
      const dropOffZone = addressSubstrings[addressSubstrings.length - 2]
        .replace(/\s/g, "_")
        .toLowerCase();
      return openZones.includes(dropOffZone) ? dropOffZone : "paris";
    } catch {
      return "paris";
    }
  };

  const selectDay = (Day) => {
    setIsCustomTimeChanged(false);
    getSlotsConfig({ Day, ClientAccountId: id })
      .then((res) => {
        setConfig(res);
        setStagedConfig(res);
      })
      .catch((e) => {
        loadClient(id).then(({ Config }) => {
          const clientAddress = Config.InStore.PickupAddress.address;
          const clientSlotType = Config.SlotType;
          setZone(getAddressZone(clientAddress));
          setConfig({ ...defaultConfig, Day });
          setStagedConfig({ ...defaultConfig, Day });
        });
      });
  };

  const saveConfig = () => {
    setIsCustomTimeChanged(false);
    createSlotsConfig({ ...stagedConfig, ClientAccountId: id }).then((res) => {
      toast.success("Configuration enregistée",toastOptions)
      setConfig({ ...stagedConfig, ClientAccountId: id });
      setStagedConfig({ ...stagedConfig, ClientAccountId: id });
    }).catch((err) => {
      if (err?.error?.code === 400)
      toast.error(err.error.message,toastOptions )
    });
  };

  const deleteConfig = () => {
    setIsCustomTimeChanged(false);
    deleteSlotsConfigByClientID(id).then((res) => {
      setConfig({ ...defaultConfig, Day: stagedConfig.Day });
      setStagedConfig({ ...defaultConfig, Day: stagedConfig.Day });
    });
  };

  const resetZoneConfig = () => {
    selectDay(stagedConfig.Day);
    setStagedConfig(config);
  };

  useEffect(() => {
    if (stagedConfig.Channel !== "instore") {
      setStagedConfig({
        ...stagedConfig,
        SlotType: SlotType.Standard,
        Slots: null,
      });
    }
  }, [stagedConfig.Channel]);
  const handleCustomSlotAdd = () => {
    setStagedConfig({
      ...stagedConfig,
      Slots: [
        ...(stagedConfig?.Slots ?? defaultConfig.Slots),
        { StartTime: "00:00", EndTime: "00:00" },
      ],
    });
  };
  const handleCustomSlotRemove = (index) => {
    const list = [...stagedConfig.Slots];
    list.splice(index, 1);
    setStagedConfig({
      ...stagedConfig,
      Slots: list,
    });
  };

  const onSlotTypeChange = (e) => {
    let selectedSlotType = e.target.value;
    let ch = "b2c";
    setIsCustomTimeChanged(false);
    if (selectedSlotType === SlotType.Custom) {
      ch = "instore";
      setStagedConfig({
        ...stagedConfig,
        Channel: "instore",
        SlotType: SlotType[selectedSlotType],
        Slots: null,
      });
    } else {
      setStagedConfig({
        ...stagedConfig,
        Channel: "b2c",
        SlotType: SlotType[selectedSlotType],
        Slots: null,
      });
    }

    getSlotsConfig({
      Day: stagedConfig.Day,
      ClientAccountId: id,
      Zone: stagedConfig.Zone,
      Channel: ch,
      SlotType: ch === "instore" ? selectedSlotType : SlotType.Standard,
    })
      .then((res) => {
        res.Channel = ch;
        res.SlotType = selectedSlotType;

        setConfig(res);
        setStagedConfig(res);
      })
      .catch((e) => {
        setConfig({
          ...defaultConfig,
          Day: stagedConfig.Day,
          Zone: stagedConfig.Zone,
          Channel: ch,
          SlotType: selectedSlotType,
          Slots: null,
        });
        setStagedConfig({
          ...defaultConfig,
          Day: stagedConfig.Day,
          Zone: stagedConfig.Zone,
          Channel: ch,
          SlotType: selectedSlotType,
          Slots: null,
        });
      });
  };

  const onCustomSlotsChange = (key, value, index) => {
    setIsCustomTimeChanged(true);
    let newArr = stagedConfig.Slots
      ? [...stagedConfig.Slots]
      : [...defaultConfig.Slots];
    newArr[index][key] = value;
    setStagedConfig({ ...stagedConfig, Slots: newArr });
  };

  const handleVisibilityOfCuttOffChange = (event) => {
    const inputTime = event.target.value;
    setStagedConfig({
      ...stagedConfig,
      CuttOff: inputTime,
    });
  };
  const handleVisibilityOfCuttOffBlur = () => {
    const isCustomConfig = stagedConfig.SlotType === SlotType.Custom;
    
    // Check the input format after the user has finished typing
    const regex = isCustomConfig ? /^([01][1-9]|2[0-3]):[0-5][0-9]$/ : /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

    if (!regex.test(stagedConfig.CuttOff)) {
      if(isCustomConfig) toast.warn(t("CuffOffDurationGreaterThanOneHour"), toastOptions);
      // Reset the input if it doesn't match the '00:00' format
      setStagedConfig({
        ...stagedConfig,
        CuttOff: isCustomConfig ? "01:00" : "00:00",
      });
    }
  };

  useEffect(() => {
    if(stagedConfig.SlotType === SlotType.Custom) {
      if(stagedConfig.Activated) {
        return setStagedConfig(prev => ({...prev, CuttOff: "01:00"}))
      }

      setStagedConfig(prev => ({...prev, CuttOff: "00:00"}))
    }
  }, [stagedConfig.SlotType, stagedConfig.Activated]);
  
  return (
    <Container>
      <br />
      <Row>
        <Col>
          <Card>
            <Container>
              <br />
              <Row className="mb-3">
                <Col>
                  <div className="d-flex justify-content-between">
                    <h3>{t("workingDaysSelection")}</h3>
                    <Button variant="outline-secondary" onClick={deleteConfig}>
                      réinitialiser
                    </Button>
                  </div>

                  <br />
                  <ListGroup
                    horizontal
                    className="flex-wrap"
                    activeKey={`#${stagedConfig.Day}`}
                  >
                    {daysOfWeek.map((Day) => (
                      <ListGroup.Item
                        className="dayItem"
                        href={`#${Day}`}
                        onClick={() => selectDay(Day)}
                      >
                        {t(Day)}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <br />
                  <Form.Switch
                    id="active-switch"
                    label={
                      stagedConfig.Activated ? "Jour ouvré" : "Jour non ouvré"
                    }
                    checked={stagedConfig.Activated}
                    onChange={() =>
                      setStagedConfig({
                        ...stagedConfig,
                        Activated: !stagedConfig.Activated,
                      })
                    }
                  />
                  <br />
                  <h3>{t("workingHoursConfig")}</h3>
                  <br />
                  <Row>
                    {
                      <Col xs={6} md={3} className="mb-2">
                        <Form.Control
                          as="select"
                          value={stagedConfig.SlotType ?? SlotType.Standard}
                          onChange={onSlotTypeChange}
                        >
                          {Object.entries(SlotType).map(
                            ([key, value], index) => (
                              <option key={index} value={key}>
                                {value}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    }
                    {stagedConfig.SlotType === SlotType.Custom && (
                      <Col xs={6} md={3} className="mb-2">
                        <Form.Control
                          disabled
                          as="select"
                          value={stagedConfig.Channel ?? ClientType.b2c}
                          // onChange={onChannelChange}
                        >
                          {Object.entries(ClientType)?.map(
                            ([key, value], index) => (
                              <option key={index} value={key}>
                                {value}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                    )}
                  </Row>
                  {stagedConfig.SlotType === SlotType.Custom ? (
                    <Row className="align-items-center">
                      <Row style={{ marginLeft: "3%" }}>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Cut-off de visibilité :&nbsp;</span>
                          <Form.Control
                            type="text"
                            disabled={!stagedConfig.Activated}
                            value={stagedConfig.CuttOff}
                            onChange={(e) => handleVisibilityOfCuttOffChange(e)}
                            onBlur={(e) => handleVisibilityOfCuttOffBlur(e)}
                            pattern="[0-9]{2}:[0-9]{2}"
                            placeholder={"01:00"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Row style={{marginLeft:"5%"}}>
                          <Col>
                            <br />
                            <h6>{t("workingSlotsConfig")} : </h6>
                            <br />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            {" "}
                            {(stagedConfig.Slots ?? defaultConfig.Slots).map(
                              (slot, index) => {
                                return (
                                  <Row
                                    className="align-items-center"
                                    style={{ marginLeft: "5%" }}
                                  >
                                    <Col xs={9}>
                                      <Row>
                                        <Col xs={6} md={3} className="mb-2">
                                          <span>A partir de :&nbsp;</span>
                                          <Form.Control
                                            as="select"
                                            disabled={!stagedConfig.Activated}
                                            value={slot.StartTime}
                                            onChange={(e) =>
                                              onCustomSlotsChange(
                                                "StartTime",
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {workTimes.map((time, index) => (
                                              <option key={index} value={time}>
                                                {time}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </Col>
                                        <Col xs={6} md={3} className="mb-2">
                                          <span>Jusqu'à :&nbsp;</span>
                                          <Form.Control
                                            as="select"
                                            disabled={!stagedConfig.Activated}
                                            value={slot.EndTime}
                                            onChange={(e) =>
                                              onCustomSlotsChange(
                                                "EndTime",
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {workTimes.map((time, index) => (
                                              <option key={index} value={time}>
                                                {time}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </Col>
                                        <Col>
                                          <Row style={{ paddingTop: "9%" }}>
                                            {stagedConfig.Slots &&
                                              stagedConfig.Slots?.length !==
                                                1 && (
                                                <span
                                                  variant="primary"
                                                  onClick={() =>
                                                    handleCustomSlotRemove(
                                                      index
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{ color: "red" }}
                                                  />
                                                </span>
                                              )}
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ marginLeft: "6%" }}>
                            <Button
                              variant="outlined-white"
                              color="dashed"
                              onClick={handleCustomSlotAdd}
                              disabled={!stagedConfig.Activated}
                            >
                              <FontAwesomeIcon
                                className="prepend-svg object-controls-plus"
                                icon={faPlus}
                              />
                              <span>Ajouter un nouveau créneau</span>
                            </Button>
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  ) : (
                    <>
                      <Row className="align-items-center">
                        <Col xs={6} md={3} className="mb-2">
                          {" "}
                          <span>A partir de :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedConfig.Activated}
                            value={stagedConfig.StartTime}
                            onChange={(e) =>
                              setStagedConfig({
                                ...stagedConfig,
                                StartTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Jusqu'à :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedConfig.Activated}
                            value={stagedConfig.EndTime}
                            onChange={(e) =>
                              setStagedConfig({
                                ...stagedConfig,
                                EndTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Cut-off :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedConfig.Activated}
                            value={stagedConfig.CuttOff}
                            onChange={(e) =>
                              setStagedConfig({
                                ...stagedConfig,
                                CuttOff: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <span>Taille de créneau :&nbsp;</span>
                        </Col>
                        <Col xs={6} md={3} className="mb-2">
                          <Form.Control
                            as="select"
                            disabled={!stagedConfig.Activated}
                            value={stagedConfig.ServiceTime}
                            onChange={(e) =>
                              setStagedConfig({
                                ...stagedConfig,
                                ServiceTime: e.target.value,
                              })
                            }
                          >
                            {workTimes.map((time, index) => (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </>
                  )}
                  <br />
                  <br />
                  <div className="d-flex flex-wrap justify-content-end">
                    <Button
                      variant="outline-secondary"
                      disabled={
                        JSON.stringify(stagedConfig) ===
                          JSON.stringify(config) && !isCustomTimeChanged
                      }
                      onClick={resetZoneConfig}
                    >
                      Annuler
                    </Button>
                    <Button
                      className="ml-3"
                      variant="info"
                      onClick={saveConfig}
                      disabled={
                        JSON.stringify(stagedConfig) ===
                          JSON.stringify(config) && !isCustomTimeChanged
                      }
                    >
                      Enregistrer
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card>
          <br />
        </Col>
      </Row>
      <ToastContainer transition={Slide} />
    </Container>
  );
};

export default B2CWorkingHoursConfig;
