import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './PriceRule.css';

const formFromProps = props => ({
    price: props.rule.price,
    type: props.rule.type,
    dynamic_attr: props.rule.dynamic_attr,
    rule: props.rule.rule || {}
})

const PriceRule = (props) => {
  
  const { t } = useTranslation();
  const [form, setForm] = useState(formFromProps(props));
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const inputAttributes = props.attributes.filter(a => a.type === "input");

  const updateForm = (field, value) => {
    const newData = {
      [field]: value,
      ...(field === "type" ? {
        dynamic_attr: value === "dynamic" ? inputAttributes[0].name : undefined
      }: {})
    }
    setForm({...form, ...newData})
  }

  const updateCondition = (attrName, rawValue) => {
    const { type } = props.attributes.find(a => a.name === attrName);
    const fieldsByType = {
      input: "threshold",
      select: "option",
    };
    const field = fieldsByType[type];
    const value = field === "threshold" ? parseInt(rawValue) : rawValue;
    const condition = value === "*" ? null : {[field]: value, type};
    const rawConditions = {...form.rule, [attrName]: condition};
    const validKeys = Object.keys(rawConditions).filter(k => rawConditions[k]);
    const pureConditions = validKeys.reduce(
      (obj, k) => ({...obj, [k]: rawConditions[k]}),
      {}
    )
    updateForm("rule", pureConditions);
  }

  const getConditionValue = attrName => {
    const attribute = props.attributes.find(a => a.name === attrName);
    const fieldsByType = {
      input: "threshold",
      select: "option",
    };
    const field = fieldsByType[attribute.type];
    const fallBack = field === "threshold" ? attribute.thresholds[0] : "*";
    return (form.rule[attrName] || {})[field] || fallBack;
  }

  const changed = props.rule.staged || (
    form.price !== props.rule.price
    || form.type !== props.rule.type
    || form.dynamic_attr !== props.rule.dynamic_attr
    || props.attributes.some(({name: key}) => {
        const changedAttribute = props.attributes.find(attr => attr.name === key);
        const comparedProp = changedAttribute.type === "select" ? "option" : "threshold";
        const propRule = props.rule.rule[key];
        const condition = form.rule[key];
        if(!condition){ //this means that the selected option for the attribute is anyValue, so we need to check if the previous selected option is different
          return propRule && propRule[comparedProp]
        };
        return !propRule || condition[comparedProp] !== propRule[comparedProp];
      })
  )

  const reset = useCallback(() => {
    setForm(formFromProps(props));
  }, [props])

  const cancelChanges = () => {
    if(props.rule.staged) setShowConfirmRemove(true);
    else reset();
  }

  const submit = () => {
    props.onUpdate(form, props.rule.id);
  }

 return (
    <div className="pricerule-container" data-changed={changed}>
    {showConfirmRemove && <div className="pricerule-confirmremove">
      <div className="pricerule-confirmremovepopup">
        <div>{t("confirmRemovePrice")}</div>
        <div>
          <Button variant="light" onClick={() => setShowConfirmRemove(false)}>{t("no")}</Button>
          &nbsp;
          <Button variant="info" onClick={() => props.onRemove(props.rule.id)}>{t("yes")}</Button>
        </div>
      </div>
    </div>}
    <Container>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <FontAwesomeIcon
            icon={faTimes}
            className="pricerule-actionicon"
            onClick={() => setShowConfirmRemove(true)}/>
        </Col>
      </Row>
      <Form.Row>
        <Col sm={4}> 
          <Form.Group controlId={"price"}>
            <Form.Label>{t("price")}</Form.Label> 
            <Form.Control
              type="number"
              placeholder="0"
              value={form.price}
              onChange={e => updateForm("price", parseFloat(e.target.value))}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col sm={3}> 
          <div>
            <Form.Label>{t("type")}</Form.Label> 
            <div>
              <Form.Check
                id={`type-static-${props.rule.id}`}
                inline
                type="radio"
                placeholder="0"
                label={t("static")}
                checked={form.type === "static"}
                onChange={e => updateForm("type","static")}
              />
              <Form.Check
                id={`type-dynamic-${props.rule.id}`}
                inline
                type="radio"
                placeholder="0"
                label={t("dynamic")}
                checked={form.type === "dynamic"}
                onChange={e => updateForm("type","dynamic")}
                disabled={inputAttributes.length === 0}
              />
            </div>
          </div>
        </Col>
        {form.type === "dynamic" && <Col sm={4}>
          <Form.Group controlId={"dynamicProp"}>
            <Form.Label>{t("dynamicPropLabel")}</Form.Label>
            <Form.Control
              as="select"
              value={form.dynamic_attr}
              onChange={e => updateForm("dynamic_attr", e.target.value)}
            >
              {inputAttributes.map(attr => (
                <option key={attr.name}>{attr.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>}
      </Form.Row>
      <Row>
        <Col><h5>{t("conditions")}</h5></Col>
      </Row>
      <div className="pricerule-attributes">
        {props.attributes.map((attr) => (
          <Row key={attr.name}>
              <Col sm={2}> {attr.name} </Col>
              <Col>
                <Form.Group controlId={`rule${attr.name}`}>
                  <Form.Control
                    as="select"
                    placeholder="0"
                    value={getConditionValue(attr.name)}
                    onChange={e => updateCondition(attr.name, e.target.value)}
                  >
                  {attr.type === "select" && <option value="*">{t("anyValue")}</option>}
                  {(attr.thresholds || attr.options).map((option, optionIndex) => (
                    <option key={option} value={option}>
                      {option}
                      {attr.type === "input" && `
                        - ${(attr.thresholds[optionIndex + 1] || "+∞")} 
                      `}
                    </option>
                  ))}
                  </Form.Control>
                </Form.Group>
              </Col>
          </Row>
        ))}
        {changed && 
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Button variant="light" onClick={cancelChanges}>{t("cancel")}</Button>
              &nbsp;
              <Button variant="warning" onClick={submit}>{t("confirm")}</Button>
            </Col>
          </Row>
        }
      </div>
    </Container>
    </div>
  ) 
};

PriceRule.propTypes = {
  rule: PropTypes.object.isRequired
};

export default PriceRule;
