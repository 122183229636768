export const getDistance = (
  origin,
  destinations,
  travelMode = "DRIVING",
  withRoute = false
) =>
  new Promise((resolve) => {
    try {
      let fastResult = null;

      if (!window.google) {
        return resolve({ distance: "0", directionResult: fastResult });
      }

      if (!origin.length || !destinations.length || !destinations[0].length)
        return resolve({ distance: "0", directionResult: fastResult });

      const destination = destinations[0];

      let params = {
        origin,
        destination,
        travelMode,
        provideRouteAlternatives: true,
      };

      if (destinations.length > 1) {
        const waypoints = destinations
          .slice(0, -1)
          .map((dest) => ({ location: dest, stopover: true }));
        params = { ...params, waypoints };
      }

      const ds = new window.google.maps.DirectionsService();
      ds.route(params, (response, status) => {
        if (status !== "OK")
          return resolve({ distance: "0", directionResult: fastResult });
        const distances = response?.routes.map((r) => {
          let legsDistance = 0;
          for (let leg of r.legs) {
            if (leg.distance) legsDistance += leg.distance.value;
          }
          return legsDistance;
        });

        const distance = Math.min(...distances);

        if (withRoute) {
          fastResult = {
            ...response,
            routes: [
              response.routes.find(
                (r) => r.legs[0].distance.value === distance
              ),
            ],
          };
        }

        return resolve({
          distance: `${distance / 1000}`,
          directionResult: fastResult,
        });
      });
    } catch (error) {}
  });
