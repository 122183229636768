import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { loadClient, refreshTasks, removeDuplicatedTasks } from "../../utilities/clients.js";
import PageSpinner from "../PageSpinner";
import { toastOptions } from '../../state/constants';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const ClientTasks = () => {
  const { id } = useParams();
  const [client, setClient] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isRemovingDuplicates, setIsRemovingDuplicates] = useState(false);

  useEffect(() => {
    loadClient(id).then(setClient);
  }, [id])

  const refresh = async () => {
    try {
      setIsRefreshing(true);
      await refreshTasks(id);
      toast.success("Success", toastOptions);
    } catch(e) {
      toast.error("Something went wrong", toastOptions);
    } finally {
      setIsRefreshing(false);
    }
  }

  const removeDuplicates = async () => {
    try {
      setIsRemovingDuplicates(true);
      await removeDuplicatedTasks(id);
      toast.success("Success", toastOptions);
    } catch(e) {
      toast.error("Something went wrong", toastOptions);
    } finally {
      setIsRemovingDuplicates(false);
    }
  }

  if(!client || !client.ID) return <PageSpinner></PageSpinner>

  return (
    <Container>
      <br/>
      <Card>
        <br/>
        <Container>
          <Row>
            <Col>
              <h3> {client.Name} </h3>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <Button variant="warning" onClick={refresh}>
                {!isRefreshing && "Refresh"}
                {isRefreshing && <FontAwesomeIcon icon={faSpinner} pulse/>}
              </Button>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <Button variant="danger" onClick={removeDuplicates}>
                {!isRemovingDuplicates && "Remove Duplicates"}
                {isRemovingDuplicates && <FontAwesomeIcon icon={faSpinner} pulse/>}
              </Button>
            </Col>
          </Row>
        </Container>
        <br/>
      </Card>
      <br/>
      <ToastContainer transition={Slide} />
    </Container>
)}

ClientTasks.defaultProps = {};

export default ClientTasks;
